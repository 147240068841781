import Immutable from 'immutable';
import  {ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setCoupons = (state, { allCouponsData }) => {
    if (!allCouponsData) return state;
    return state.merge({ allCouponsData });
}

const setCouponsById = (state, { CouponsParams }) => {
    return state.merge({ CouponsParams });
}
const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_COUPONS,
        actionFunction: setCoupons,
    },
    {
        actionType: ActionTypes.SET_COUPONS_BY_ID,
        actionFunction: setCouponsById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];