export const ActionTypes = {
    ADD_UPDATE_LOCALIZATION: 'services/hrm/localization/add_update_localization',
    FETCH_LOCALIZATION: 'services/hrm/localization/fetch_localization',
    SET_LOCALIZATION: 'services/hrm/localization/set_localization',
    DELETE_LOCALIZATION: 'services/hrm/localization/delete_localization',
    SET_LOADER: 'Services/settings/localization/set_loader',
};

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllLocalization = ({ localizationParams }) => ({
    localizationParams,
    type: ActionTypes.FETCH_LOCALIZATION,
});

const setLocalization = ({ allLocalizationData }) => ({
    allLocalizationData,
    type: ActionTypes.SET_LOCALIZATION,
});

const deleteLocalization = ({ localizationParam }) => ({
    localizationParam,
    type: ActionTypes.DELETE_LOCALIZATION,
});

const addUpdateLocalization = ({ localizationData, actionName, localizationParams }) => ({
    localizationData,
    actionName,
    localizationParams,
    type: ActionTypes.ADD_UPDATE_LOCALIZATION,
});

export default {
    addUpdateLocalization,
    fetchAllLocalization,
    setLocalization,
    deleteLocalization,
    setLoader,
};
