import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import DatePicker from 'react-datepicker';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'
import moment from 'moment';

const OtherInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, optionsData } = props;
    const { nationalities } = optionsData;
    const dispatch = useDispatch();

    const [visaExpiry, setVisaExpiry] = useState(null)
    const [passportExpiry, setPassportExpiry] = useState(null)

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [isStateSelected, setIsStateSelected] = useState(false)

    const allStatesData = useSelector(state => commonSelectors.setAllStates(state));
    const statesData = allStatesData ? allStatesData.toJS() : [];

    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    const handleVisaExpiryChange = (date) => {
        setVisaExpiry(date);
        form.setFieldsValue({ visaExpiry: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handlePassportExpiryChange = (date) => {
        setPassportExpiry(date);
        form.setFieldsValue({ passportExpiry: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleCountryChange = (selectedCountryId) => {
        form.setFieldsValue({ stateId: null })
        form.setFieldsValue({ cityId: null })
        setIsStateSelected(false)
        dispatch(commonActions.getAllStatesByCountryId({ stateParams: selectedCountryId }))
        setIsCountrySelected(true);
    }

    const handleStateChange = (selectedStateId) => {
        form.setFieldsValue({ cityId: null })
        dispatch(commonActions.getAllCitiesByStateId({ cityParams: selectedStateId }));
        setIsStateSelected(true);
    };

    useEffect(() => {
        if (isUpdate && isUpdate == true && currentModalData) {
            setIsCountrySelected(true)
            setIsStateSelected(true)
        }
    }, [isUpdate, currentModalData])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    emergencyContact1: currentModalData.formData?.emergencyContact1,
                    emergencyContact2: currentModalData.formData?.emergencyContact2,
                    address: currentModalData.formData?.address,
                    countryId: currentModalData.formData?.countryId,
                    stateId: currentModalData.formData?.stateId,
                    cityId: currentModalData.formData?.cityId,
                    zipCode: currentModalData.formData?.zipCode,
                    visaNo: currentModalData.formData?.visaNo,
                    passportNo: currentModalData.formData?.passportNo,
                    visaExpiry: currentModalData.formData?.visaExpiry ? moment(currentModalData.formData.visaExpiry).format('YYYY-MM-DD') : null,
                    passportExpiry: currentModalData.formData?.passportExpiry ? moment(currentModalData.formData.passportExpiry).format('YYYY-MM-DD') : null,
                }}
                name="forForm" className='productActions_addModalWrapper'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="emergencyContact1"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emergency.no.one"
                                        popoverkey="hrm.employee.form.label.popover.emergency.no.one"
                                    />
                                }
                                rules={[
                                    {
                                        max: 20,
                                        message: "Emergency contact cannot exceed 20 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="emergencyContact2"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emergency.no.two"
                                        popoverkey="hrm.employee.form.label.popover.emergency.no.two"
                                    />
                                }
                                rules={[
                                    {
                                        max: 20,
                                        message: "Emergency contact cannot exceed 20 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="address"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.address"
                                        popoverkey="hrm.employee.form.label.popover.address"
                                    />
                                }
                                rules={[
                                    {
                                        max: 500,
                                        message: "Address cannot exceed 500 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="countryId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.country"
                                        popoverkey="hrm.employee.form.label.popover.country"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.country" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={nationalities.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    onChange={handleCountryChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="stateId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.state"
                                        popoverkey="hrm.employee.form.label.popover.state"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.state" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={statesData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    disabled={!isCountrySelected}
                                    onChange={handleStateChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="cityId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.city"
                                        popoverkey="hrm.employee.form.label.popover.city"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.city" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={citiesData.map(c => ({ label: c.name, value: c.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    disabled={!isStateSelected}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="zipCode"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.zipcode"
                                        popoverkey="hrm.employee.form.label.popover.zipcode"
                                    />
                                }
                                rules={[
                                    {
                                        pattern: /^[A-Za-z0-9\s-]+$/,
                                        message: "Zip code must be a valid alphanumeric string.",
                                    },
                                    {
                                        max: 20,
                                        message: "Zip code cannot exceed 20 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>


                    {/* Visa & Passport Info */}
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="visaNo"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Visa Number"
                                        popoverkey="Visa Number"
                                    />
                                }
                                rules={[
                                    {
                                        pattern: /^[A-Za-z0-9\s]+$/,
                                        message: "Visa number must be a valid alphanumeric string.",
                                    },
                                    {
                                        max: 50,
                                        message: "Visa number cannot exceed 50 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="visaExpiry"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Visa Expiry"
                                        popoverkey="Visa Expiry"
                                    />
                                }
                            >
                                <DatePicker
                                    selected={visaExpiry}
                                    onChange={handleVisaExpiryChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="passportNo"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Passport Number"
                                        popoverkey="Passport Number"
                                    />
                                }
                                rules={[
                                    {
                                        pattern: /^[A-Za-z0-9\s]+$/,
                                        message: "Passport number must be a valid alphanumeric string.",
                                    },
                                    {
                                        max: 50,
                                        message: "Passport number cannot exceed 50 characters.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="passportExpiry"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Passport Expiry"
                                        popoverkey="Passport Expiry"
                                    />
                                }
                            >
                                <DatePicker
                                    selected={passportExpiry}
                                    onChange={handlePassportExpiryChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default OtherInformationTab;
