export const ActionTypes = {
    ADD_OTPSETTING: 'services/Settings/otpsetting/add_update_otpsetting',
    FETCH_OTPSETTING: 'services/Settings/otpsetting/fetch_otpsetting',
    SET_OTPSETTING: 'services/Settings/otpsetting/set_otpsetting',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllOtpSettings = ({ otpsettingParams }) => ({
    otpsettingParams,
    type: ActionTypes.FETCH_OTPSETTING,
})

const setOtpSettings = ({ allOtpSettingsData }) => ({
    allOtpSettingsData,
    type: ActionTypes.SET_OTPSETTING,
})

const addOtpSetting = ({ otpSettingData, actionName, otpSettingParams }) => ({
    otpSettingData,
    actionName,
    otpSettingParams,
    type: ActionTypes.ADD_OTPSETTING,
})

export default {
    fetchAllOtpSettings,
    addOtpSetting,
    setOtpSettings,
    setLoader
};
