import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';


function* addUpdateSellingType({ sellingTypeData, actionName, sellingTypeParams }) {
    try {
      if (actionName === 'update') {
        yield call(api.put, {
          route: `/Inventory/edit-sellingtype`,
          data: sellingTypeData,
        });
      } else {
        yield call(api.post, {
          route: '/Inventory/create-sellingtype',
          data: sellingTypeData,
        });
      }
      
      yield put(Actions.fetchAllSellingType({ sellingTypeParams }));
      notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SELLINGTYPE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SELLINGTYPE_CREATED_SUCCESSFULLY);
    } catch (error) {
      const apiStatus = error.details.response.status;
      yield put(apiActions.setApiResponse({ apiStatus }));
      handleNotification(error);
      console.log(error);
    }
  }
  function* fetchAllSellingType({ sellingTypeParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch units
      const { data } = yield call(api.get, {
        route: '/Inventory/all-sellingtypes',
        params: sellingTypeParams,
      });
  
      // Store the fetched data in Redux store
      const allSellingTypeData = data;
      yield put(Actions.setSellingType({ allSellingTypeData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }

  function* deleteSellingTypeById({ sellingTypeParams }) {
    try {
      yield put(Actions.setLoader({ showLoader: true }));
      yield call(api.delete, {
        route: `/Inventory/delete-sellingtype/` + sellingTypeParams.id,
      });
      yield put(Actions.fetchAllSellingType({sellingTypeParams}));
      yield put(Actions.setLoader({ showLoader: false }));
      notification('success', CONSTANT_VARIABLES.SELLINGTYPE_DELETED_SUCCESSFULLY);
    } catch (error) {
      yield put(Actions.setLoader({ showLoader: false }));
      handleNotification(error);
      console.log(error);
    }
  }

  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0) {
      message.details.response.data.errors.map((value) => (
        notification('error', value)
      ));
    } else {
      notification('error', 'SERVE-POS API Issue.');
    }
  }

  export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_SELLINGTYPE, fetchAllSellingType),
      takeLatest(ActionTypes.ADD_UPDATE_SELLINGTYPE, addUpdateSellingType),
      takeLatest(ActionTypes.DELETE_SELLINGTYPE, deleteSellingTypeById)
    ]);
  }