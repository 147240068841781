import React, { useState, useEffect } from "react";
import { Calendar } from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from "../../shared/components/utility/intlMessages";
import { Modal, Form, Input, Switch,InputNumber,DatePicker,Select} from 'antd';
import { CONSTANT_VARIABLES } from '../../core/Common/Constant_Variables/constants';
import CustomFormLabel from '../common/CustomFormLabel';
import * as ApiResponseSelectors from '../selectors';
import * as commonSelector from '../commonSelectors';
import couponsAction from '../../core/Services/coupons/actions'
import commonAction from "../../core/Services/common/actions";

import moment from 'moment';
import { label } from "yet-another-react-lightbox";



const AddCoupons = (props) => {
  const price = [
    { value: "choose", label: "Choose Type" },
    { value: "fixed", label: "Fixed" },
    { value: "percentage", label: "Percentage" },
  ];

  const {currentModalData, isModalOpen, isUpdate, toggleModal, CouponsParams } = props;
  const [selectedstartDate, setSelectedstartDate] = useState(null);
  const [selectedendDate, setSelectedendDate] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

  const allProductsData = useSelector(state => commonSelector.setProducts(state));
  const allProducts = allProductsData ? allProductsData.toJS() : [];
  

  const handleChangeProducts = (e) => {
    dispatch(couponsAction.getCouponsById({ CouponsParams: e }));
  }


  const handlestartDateChange = (date) => {
    setSelectedstartDate(date);
    form.setFieldsValue({ startDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleendDateChange = (date) => {
    setSelectedendDate(date);
    form.setFieldsValue({ endDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  useEffect(() => {
    dispatch(commonAction.fetchAllProducts());
  }, [])

  const handleRecord = (actionName, CouponsData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      CouponsData.id = currentModalData.formData?.id;
      CouponsData.companyId = currentModalData.formData?.companyId;
      CouponsData.productId = CouponsData.productId == null ? currentModalData.formData.productId : CouponsData.productId;
      CouponsData.limit = CouponsData.limit == null ? currentModalData.formData.limit : CouponsData.limit;
      CouponsData.name = CouponsData.name == null ? currentModalData.formData.name : CouponsData.name;
      CouponsData.type = CouponsData.type == null ? currentModalData.formData.type : CouponsData.type;
      CouponsData.code = CouponsData.code == null ? currentModalData.formData.code : CouponsData.code;
      CouponsData.startDate = CouponsData.startDate == null ? currentModalData.formData.startDate : CouponsData.startDate;
      CouponsData.endDate = CouponsData.endDate == null ? currentModalData.formData.endDate : CouponsData.endDate;
      CouponsData.disscount = CouponsData.disscount == null ? currentModalData.formData.disscount : CouponsData.disscount;
      CouponsData.isOncePerCustomer = CouponsData.isOncePerCustomer == null ? currentModalData.formData.isOncePerCustomer : CouponsData.isOncePerCustomer;
      CouponsData.isActive = CouponsData.isActive == null ? currentModalData.formData.isActive : CouponsData.isActive;


    }
    else {
      CouponsData.isOncePerCustomer = CouponsData.isOncePerCustomer ? true : false;
      CouponsData.isActive = CouponsData.isActive ? true : false;
      CouponsData.companyId = 1;

    }
    dispatch(couponsAction.addUpdateCoupons({ CouponsData, actionName, CouponsParams: CouponsParams }));
    toggleModal();
};

  return (
    <Modal
            open={isModalOpen}
            onCancel={() => toggleModal(true)}
            onOk={() => {
              form.validateFields()
                  .then(values => {
                      handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                  })
                  .catch(info => {
                      console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                  });
          }}
            title={isUpdate ? <IntlMessages id="coupons.form.modal.title.update" /> : <IntlMessages id="coupons.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="coupons.form.modal.update" /> : <IntlMessages id="coupons.form.modal.add" />}
            cancelText={<IntlMessages id="unit.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
 
      <Form
              form={form}
              layout="vertical"
              name="categoryForm"
              className='categoryActions_addModalWrapper'
      initialValues={{
          id:currentModalData.formData?.id,
          productId: currentModalData.formData?.productId,
          name: currentModalData.formData?.name,
          code: currentModalData.formData?.code,
          type: currentModalData.formData?.type,
          startDate:currentModalData.formData?.startDate ? moment(currentModalData.formData.startDate).format('YYYY-MM-DD') : null,
          endDate:currentModalData.formData?.endDate ? moment(currentModalData.formData.endDate).format('YYYY-MM-DD') : null,
          disscount:currentModalData.formData?.disscount,
          limit:currentModalData.formData?.limit,
          isActive:currentModalData.formData?.isActive,
          isOncePerCustomer:currentModalData.formData?.isOncePerCustomer,
          companyId: currentModalData.formData?.companyId || 1,

          
      }}
      >

             <div className='row'>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="name"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.name" popoverkey="coupons.form.label.popover.name" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.name" /> }]}
                                
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="code"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.code" popoverkey="coupons.form.label.popover.code" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.code" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="type"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.type" popoverkey="coupons.form.label.popover.type" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.type" /> }]}

                            >
                                <Select
                            classNamePrefix="react-select"
                            options={price.map(f => ({ label: f.label, value: f.value }))}
                         />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="disscount"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.discount" popoverkey="coupons.form.label.popover.discount" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.discount" /> }]}

                            >
                                <InputNumber />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-blocks">
                      <Form.Item
                                name="limit"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.limit" popoverkey="coupons.form.label.popover.limit" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.limit" /> }]}

                            >
                                <InputNumber />
                            </Form.Item>
                      </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="input-blocks">
                      <Form.Item
                                name="productId"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="coupons.form.label.productId" popoverkey="coupons.form.label.popover.productId" />}
                                rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.productId" /> }]}

                            >
                                  <Select
                          classNamePrefix="react-select"
                          options={allProducts.map(f => ({ label: f.name, value: f.id }))}
                          onChange={handleChangeProducts}
                          placeholder="Choose"
                        />

                      </Form.Item>
                      </div>
                      </div>
                      <div className="col-lg-12">
                      <div className="input-blocks">
                      <div className="col-lg-12">
                        <div className="addUnit_form--status_area">
                            <span className="status-label"><IntlMessages id="coupons.modal.validation.used" /></span>
                            <Form.Item
                                name="isOncePerCustomer"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Switch size="small" />
                            </Form.Item>
                        </div>
                    </div>
                        {/* <Form.Item
                          name="isOncePerCustomer"
                          className='actions_addModalFormItem'
                          label={<CustomFormLabel labelkey="coupons.form.label.used" popoverkey="coupons.form.label.popover.used" />}
                          rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.used" /> }]}
                        >
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                             <span className="customer-toggle">
                              Once Per Customer
                            </span>
                             <Switch size="small" className="ml-3" />
                           
                          </div>
                        </div>
                        </Form.Item> */}
                      </div>
                      </div>
                      <div className="col-lg-12">
                      <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                        <Form.Item
                          name="startDate"
                          className='actions_addModalFormItem'
                          label={<CustomFormLabel labelkey="coupons.form.label.startdate" popoverkey="coupons.form.label.popover.startdate" />}
                          rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.startdate" /> }]}
                        >
                          <div className="input-groupicon calender-input">
                            <Calendar className="info-img" />
                            <DatePicker
                              selected={selectedstartDate}
                              onChange={handlestartDateChange}
                              dateFormat="YYYY-MM-DD"
                              placeholderText="Select Date"
                              className="actions_addModalFormItem"
                            />
                          </div>
                        </Form.Item>
                        </div>

                      </div>
                      <div className="col-lg-6">
                      <div className="input-blocks">
                      <Form.Item
                       name="endDate"
                       className='actions_addModalFormItem'
                       label={<CustomFormLabel labelkey="coupons.form.label.enddate" popoverkey="coupons.form.label.popover.enddate" />}
                       rules={[{ required: true, message: <IntlMessages id="coupons.modal.validation.enddate" /> }]}
                      >
                          <div className="input-groupicon calender-input">
                            <Calendar className="info-img" />
                            <DatePicker
                              selected={selectedendDate}
                              onChange={handleendDateChange}
                              dateFormat="YYYY-MM-DD"
                              placeholderText="Select Date"
                              className="actions_addModalFormItem"
                            />
                          </div>
                      </Form.Item>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="addUnit_form--status_area">
                            <span className="status-label"><IntlMessages id="coupons.modal.validation.status" /></span>
                            <Form.Item
                                name="isActive"
                                className='actions_addModalFormItem'
                            >
                                <Switch size="small" />
                            </Form.Item>
                        </div>
                    </div>
                </div> 
      </Form>
    </Modal>

  );
};

export default AddCoupons;
