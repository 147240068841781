import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSaleOrders = (state, { allSaleOrderData }) => { // Updated function and parameter
    if (!allSaleOrderData) return state;
    return state.merge({ allSaleOrderData });
}

const setSaleOrderById = (state, { saleOrderData }) => { // Updated function and parameter
    return state.merge({ saleOrderData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const SetSaleQuotationByQNById = (state, { allsaleQuotationIdData }) => {
    return state.merge({ allsaleQuotationIdData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_SALE_ORDERS, // Updated action type
        actionFunction: setSaleOrders, // Updated function
    },
    {
        actionType: ActionTypes.SET_SALE_ORDER_BY_ID, // Updated action type
        actionFunction: setSaleOrderById, // Updated function
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: SetSaleQuotationByQNById,
    },
];
