import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import SettingsSideBar from "../settingssidebar";
import { Spin, Switch } from "antd";
import notificationActions from '../../../core/Services/settings/notifications/actions'
import * as commonSelectors from '../../commonSelectors'
import * as notificationSelectors from '../../../feature-module/settings/settingsSelector'
import { useDebounce } from "use-debounce";

const Notification = () => {
  const route = all_routes;
  const dispatch = useDispatch();

  //Use States
  const [notificationsParams, setNotificationsParams] = useState({ pageNo: 1, searchText: '' });
  const [debouncedText] = useDebounce(notificationsParams, 1000);
  //Notification Settings
  const [mobilePushNotifications, setMobilePushNotifications] = useState(false);
  const [desktopNotifications, setDesktopNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [msmsNotifications, setMSMSNotifications] = useState(false);

  //General Notification

  // Legendary
  const [legendarypush, setlegendarypushPush] = useState(false)
  const [legendarysms, setlegendarySms] = useState(false)
  const [legendaryemail, setlegendaryEmail] = useState(false)

  // Transaction
  const [transactionpush, settransactionPush] = useState(false)
  const [transactionsms, settransactionSms] = useState(false)
  const [transactionemail, settransactionEmail] = useState(false)

  // Email Verification	
  const [emailVerificationpush, setemailVerificationPush] = useState(false)
  const [emailVerificationsms, setemailVerificationSms] = useState(false)
  const [emailVerificationemail, setemailVerificationEmail] = useState(false)

  // OTP
  const [otppush, setotpPush] = useState(false)
  const [otpsms, setotpSms] = useState(false)
  const [otpemail, setotpEmail] = useState(false)

  // Activity
  const [activitypush, setactivityPush] = useState(false)
  const [activitysms, setactivitySms] = useState(false)
  const [activityemail, setactivityEmail] = useState(false)

  // Account
  const [accountpush, setaccountPush] = useState(false)
  const [accountsms, setaccountSms] = useState(false)
  const [accountemail, setaccountEmail] = useState(false)

  // Selectors
  const isNotificationsDataLoading = useSelector(state => commonSelectors.setLoader(state));
  const allNotificationsData = useSelector(state => notificationSelectors.setNotifications(state));
  const dataSource = allNotificationsData ? allNotificationsData.toJS() : {};
  const notificationsData = dataSource?.data

  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  // Switch Changes

  // Notifications Settings
  const onMobileNotificationsChange = (checked) => {
    debugger
    setMobilePushNotifications(checked)
  }

  const onDesktopNotificationsChange = (checked) => {
    setDesktopNotifications(checked)
  }

  const onEmailNotificationsChange = (checked) => {
    setEmailNotifications(checked)
  }

  const onMSMSNotificationsChange = (checked) => {
    setMSMSNotifications(checked)
  }

  // General Notificaionts

  // Legendary
  const onLegendaryPushChange = (checked) => {
    setlegendarypushPush(checked)
  }
  const onLegendarySmsChange = (checked) => {
    setlegendarySms(checked)
  }
  const onLegendaryEmailChange = (checked) => {
    setlegendaryEmail(checked)
  }

  // Transaction
  const onTransactionPushChange = (checked) => {
    settransactionPush(checked)
  }
  const onTransactionSmsChange = (checked) => {
    settransactionSms(checked)
  }
  const onTransactionEmailChange = (checked) => {
    settransactionEmail(checked)
  }

  // Email Verification	
  const onEmailVerificationPushChange = (checked) => {
    setemailVerificationPush(checked)
  }
  const onEmailVerificationSmsChange = (checked) => {
    setemailVerificationSms(checked)
  }
  const onEmailVerificationEmailChange = (checked) => {
    setemailVerificationEmail(checked)
  }

  // OTP
  const onOTPPushChange = (checked) => {
    setotpPush(checked)
  }
  const onOTPSmsChange = (checked) => {
    setotpSms(checked)
  }
  const onOTPEmailChange = (checked) => {
    setotpEmail(checked)
  }

  // Activity
  const onActivityPushChange = (checked) => {
    setactivityPush(checked)
  }
  const onActivitySmsChange = (checked) => {
    setactivitySms(checked)
  }
  const onActivityEmailChange = (checked) => {
    setactivityEmail(checked)
  }

  // Account
  const onAccountPushChange = (checked) => {
    setaccountPush(checked)
  }
  const onAccountSmsChange = (checked) => {
    setaccountSms(checked)
  }
  const onAccountEmailChange = (checked) => {
    setaccountEmail(checked)
  }

  // Update Create Notification
  const getLegendaeryNotificationsObj = () => {
    let legendaryNotificationsobj = {};
    legendaryNotificationsobj.name = "Legendary";
    legendaryNotificationsobj.isPush = legendarypush;
    legendaryNotificationsobj.isSMS = legendarysms;
    legendaryNotificationsobj.isEmail = legendaryemail;

    return legendaryNotificationsobj;
  }

  const getTransactionNotificationsObj = () => {
    let TransactionNotificationsobj = {};
    TransactionNotificationsobj.name = "Transaction";
    TransactionNotificationsobj.isPush = transactionpush;
    TransactionNotificationsobj.isSMS = transactionsms;
    TransactionNotificationsobj.isEmail = transactionemail;
    return TransactionNotificationsobj
  }
  const getEmailVerificationNotificationsObj = () => {
    let EmailVerificationNotificationsobj = {};
    EmailVerificationNotificationsobj.name = "Email Verification";
    EmailVerificationNotificationsobj.isPush = emailVerificationpush;
    EmailVerificationNotificationsobj.isSMS = emailVerificationsms;
    EmailVerificationNotificationsobj.isEmail = emailVerificationemail;
    return EmailVerificationNotificationsobj
  }
  const getOTPNotificationsObj = () => {
    let OTPNotificationsobj = {};
    OTPNotificationsobj.name = "OTP";
    OTPNotificationsobj.isPush = otppush;
    OTPNotificationsobj.isSMS = otpsms;
    OTPNotificationsobj.isEmail = otpemail;
    return OTPNotificationsobj
  }
  const getActivityNotificationsObj = () => {
    let ActivityNotificationsobj = {};
    ActivityNotificationsobj.name = "Activity";
    ActivityNotificationsobj.isPush = activitypush;
    ActivityNotificationsobj.isSMS = activitysms;
    ActivityNotificationsobj.isEmail = activityemail;
    return ActivityNotificationsobj
  }
  const getAccountNotificationsObj = () => {
    let AccountNotificationsobj = {};
    AccountNotificationsobj.name = "Account";
    AccountNotificationsobj.isPush = accountpush;
    AccountNotificationsobj.isSMS = accountsms;
    AccountNotificationsobj.isEmail = accountemail;
    return AccountNotificationsobj
  }


  const getGeneralNotificationsObj = () => {
    let generalNotification = [getLegendaeryNotificationsObj(), getTransactionNotificationsObj(), getEmailVerificationNotificationsObj(), getOTPNotificationsObj(), getActivityNotificationsObj(), getAccountNotificationsObj()]
    return generalNotification;
  }

  const handleCreateUpdateNotification = () => {
    debugger
    let notificationObj = {};
    notificationObj.id = notificationsData?.id
    notificationObj.companyId = 1;
    notificationObj.isMobilePush = mobilePushNotifications;
    notificationObj.isDesktop = desktopNotifications;
    notificationObj.isEmail = emailNotifications;
    notificationObj.isSMS = msmsNotifications;
    notificationObj.generalNotification = getGeneralNotificationsObj();

    dispatch(notificationActions.addNotification({ NotificationData: notificationObj, actionName: "Add", NotificationParams: notificationsParams }))
  }

  const handleResetNotifications = () => {
    setMobilePushNotifications(false)
    setDesktopNotifications(false)
    setEmailNotifications(false)
    setMSMSNotifications(false)

    setlegendarypushPush(false)
    setlegendarySms(false)
    setlegendaryEmail(false)

    settransactionPush(false)
    settransactionSms(false)
    settransactionEmail(false)

    setemailVerificationPush(false)
    setemailVerificationSms(false)
    setemailVerificationEmail(false)

    setotpPush(false)
    setotpSms(false)
    setotpEmail(false)

    setactivityPush(false)
    setactivitySms(false)
    setactivityEmail(false)

    setaccountPush(false)
    setaccountSms(false)
    setaccountEmail(false)
  }

  const handlePageRefresh = () => {
    dispatch(notificationActions.fetchAllNotifications({ notificationsParams }))
  }

  // Use Effects
  useEffect(() => {
    dispatch(notificationActions.fetchAllNotifications({ notificationsParams: debouncedText }))
  }, [dispatch, debouncedText, notificationsParams])

  useEffect(() => {
    debugger
      // Notification Settings
      setMobilePushNotifications(notificationsData?.isMobilePush)
      setDesktopNotifications(notificationsData?.isDesktop)
      setEmailNotifications(notificationsData?.isEmail)
      setMSMSNotifications(notificationsData?.isSMS)

      //General Notifications
      notificationsData?.generalNotification?.forEach(n => {
        if (n.name == "Legendary") {
          // Legendary
          setlegendarypushPush(n.isPush)
          setlegendarySms(n.isSMS)
          setlegendaryEmail(n.isEmail)
        }
        if (n.name == "Transaction") {
          //Transaction
          settransactionPush(n.isPush)
          settransactionSms(n.isSMS)
          settransactionEmail(n.isEmail)
        }
        if (n.name == "Email Verification") {
          //Email Verification
          setemailVerificationPush(n.isPush)
          setemailVerificationSms(n.isSMS)
          setemailVerificationEmail(n.isEmail)
        }
        if (n.name == "OTP") {
          //OTP
          setotpPush(n.isPush)
          setotpSms(n.isSMS)
          setotpEmail(n.isEmail)
        }
        if (n.name == "Activity") {
          //Activity
          setactivityPush(n.isPush)
          setactivitySms(n.isSMS)
          setactivityEmail(n.isEmail)
        }
        if (n.name == "Account") {
          //Account
          setaccountPush(n.isPush)
          setaccountSms(n.isSMS)
          setaccountEmail(n.isEmail)
        }
      });
  }, [allNotificationsData])

  return (
    <div>
      {isNotificationsDataLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="page-wrapper">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Settings</h4>
                <h6>Manage your settings on portal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handlePageRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap">
                  <div className="setting-title">
                    <h4>Notification Settings</h4>
                  </div>
                  <div className="security-settings">
                    <ul>
                      <li>
                        <div className="security-type">
                          <div className="security-title">
                            <h5>Mobile Push Notifications</h5>
                          </div>
                        </div>
                        <Switch onChange={onMobileNotificationsChange} style={{ backgroundColor: mobilePushNotifications ? 'lightgreen' : 'lightgrey' }} size="small" checked={mobilePushNotifications} />
                      </li>
                      <li>
                        <div className="security-type">
                          <div className="security-title">
                            <h5>Desktop Notifications</h5>
                          </div>
                        </div>
                        <Switch onChange={onDesktopNotificationsChange} style={{ backgroundColor: desktopNotifications ? 'lightgreen' : 'lightgrey' }} size="small" checked={desktopNotifications} />
                      </li>
                      <li>
                        <div className="security-type">
                          <div className="security-title">
                            <h5>Email Notifications</h5>
                          </div>
                        </div>
                        <Switch onChange={onEmailNotificationsChange} style={{ backgroundColor: emailNotifications ? 'lightgreen' : 'lightgrey' }} size="small" checked={emailNotifications} />
                      </li>
                      <li>
                        <div className="security-type">
                          <div className="security-title">
                            <h5>MSMS Notifications</h5>
                          </div>
                        </div>
                        <Switch onChange={onMSMSNotificationsChange} style={{ backgroundColor: msmsNotifications ? 'lightgreen' : 'lightgrey' }} size="small" checked={msmsNotifications} />
                      </li>
                    </ul>
                    <div className="table-responsive no-pagination">
                      <table className="table  datanew">
                        <thead>
                          <tr>
                            <th>General Notification</th>
                            <th>Push</th>
                            <th>SMS</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody className="custom-table-data">
                          <tr>
                            <td>Legendary</td>
                            <td>
                              <Switch onChange={onLegendaryPushChange} style={{ backgroundColor: legendarypush ? 'lightgreen' : 'lightgrey' }} size="small" checked={legendarypush} />
                            </td>
                            <td>
                              <Switch onChange={onLegendarySmsChange} style={{ backgroundColor: legendarysms ? 'lightgreen' : 'lightgrey' }} size="small" checked={legendarysms} />
                            </td>
                            <td>
                              <Switch onChange={onLegendaryEmailChange} style={{ backgroundColor: legendaryemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={legendaryemail} />
                            </td>
                          </tr>
                          <tr>
                            <td>Transaction</td>
                            <td>
                              <Switch onChange={onTransactionPushChange} style={{ backgroundColor: transactionpush ? 'lightgreen' : 'lightgrey' }} size="small" checked={transactionpush} />
                            </td>
                            <td>
                              <Switch onChange={onTransactionSmsChange} style={{ backgroundColor: transactionsms ? 'lightgreen' : 'lightgrey' }} size="small" checked={transactionsms} />
                            </td>
                            <td>
                              <Switch onChange={onTransactionEmailChange} style={{ backgroundColor: transactionemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={transactionemail} />
                            </td>
                          </tr>
                          <tr>
                            <td>Email Verification</td>
                            <td>
                              <Switch onChange={onEmailVerificationPushChange} style={{ backgroundColor: emailVerificationpush ? 'lightgreen' : 'lightgrey' }} size="small" checked={emailVerificationpush} />
                            </td>
                            <td>
                              <Switch onChange={onEmailVerificationSmsChange} style={{ backgroundColor: emailVerificationsms ? 'lightgreen' : 'lightgrey' }} size="small" checked={emailVerificationsms} />
                            </td>
                            <td>
                              <Switch onChange={onEmailVerificationEmailChange} style={{ backgroundColor: emailVerificationemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={emailVerificationemail} />
                            </td>
                          </tr>
                          <tr>
                            <td>OTP</td>
                            <td>
                              <Switch onChange={onOTPPushChange} style={{ backgroundColor: otppush ? 'lightgreen' : 'lightgrey' }} size="small" checked={otppush} />
                            </td>
                            <td>
                              <Switch onChange={onOTPSmsChange} style={{ backgroundColor: otpsms ? 'lightgreen' : 'lightgrey' }} size="small" checked={otpsms} />
                            </td>
                            <td>
                              <Switch onChange={onOTPEmailChange} style={{ backgroundColor: otpemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={otpemail} />
                            </td>
                          </tr>
                          <tr>
                            <td>Activity</td>
                            <td>
                              <Switch onChange={onActivityPushChange} style={{ backgroundColor: activitypush ? 'lightgreen' : 'lightgrey' }} size="small" checked={activitypush} />
                            </td>
                            <td>
                              <Switch onChange={onActivitySmsChange} style={{ backgroundColor: activitysms ? 'lightgreen' : 'lightgrey' }} size="small" checked={activitysms} />
                            </td>
                            <td>
                              <Switch onChange={onActivityEmailChange} style={{ backgroundColor: activityemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={activityemail} />
                            </td>
                          </tr>
                          <tr>
                            <td>Account</td>
                            <td>
                              <Switch onChange={onAccountPushChange} style={{ backgroundColor: accountpush ? 'lightgreen' : 'lightgrey' }} size="small" checked={accountpush} />
                            </td>
                            <td>
                              <Switch onChange={onAccountSmsChange} style={{ backgroundColor: accountsms ? 'lightgreen' : 'lightgrey' }} size="small" checked={accountsms} />
                            </td>
                            <td>
                              <Switch onChange={onAccountEmailChange} style={{ backgroundColor: accountemail ? 'lightgreen' : 'lightgrey' }} size="small" checked={accountemail} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="text-end settings-bottom-btn">
                        <Link onClick={handleResetNotifications} to="#" className="btn btn-cancel me-2">
                          Reset
                        </Link>
                        <Link
                          // to={route.generalsettings}
                          onClick={handleCreateUpdateNotification}
                          className="btn btn-submit"
                        >
                          Save Changes
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
