export const ActionTypes = {
    ADD_UPDATE_CURRENCY: 'services/Settings/currency/add_update_currency',
    FETCH_CURRENCY: 'services/Settings/currency/fetch_currency',
    SET_CURRENCY: 'services/Settings/currency/set_currency',
    DELETE_CURRENCY: 'services/Settings/currency/delete_currency',
    SET_LOADER: 'services/Settings/currency/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllCurrency = ({ currencyParams }) => ({
    currencyParams,
    type: ActionTypes.FETCH_CURRENCY,
})

const setCurrency = ({ allCurrencyData }) => ({
    allCurrencyData,
    type: ActionTypes.SET_CURRENCY,
})

const deleteCurrency = ({ currencyParam }) => ({
    currencyParam,
    type: ActionTypes.DELETE_CURRENCY,
})

const addUpdateCurrency = ({ currencyData, actionName, currencyParams }) => ({
    currencyData,
    actionName,
    currencyParams,
    type: ActionTypes.ADD_UPDATE_CURRENCY,
})

export default {
    addUpdateCurrency,
    fetchAllCurrency,
    setCurrency,
    deleteCurrency,
    setLoader
};
