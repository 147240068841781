import Immutable from 'immutable';
import  {ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setLogs = (state, { allLogsData }) => {
    if (!allLogsData) return state;
    return state.merge({ allLogsData });
}

const setLogsById = (state, { logsParams }) => {
    return state.merge({ logsParams });
}
const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_LOGS,
        actionFunction: setLogs,
    },
    {
        actionType: ActionTypes.GET_LOGS_BY_ID,
        actionFunction: setLogsById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];