import React, { useState, useEffect } from 'react';
import saleReceiveAction from '../../../core/Services/sales/saleReceive/actions.js';
import commonActions from '../../../core/Services/common/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, DatePicker, Select, InputNumber } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import moment from 'moment';
import TextEditor from "../../../feature-module/inventory/texteditor";
import * as commonSelectors from '../../commonSelectors';

const AddSaleReceiveModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, saleReceiveParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [paymentDueDate, setpaymentDueDate] = useState(new Date());
    const [editorData, setEditorData] = useState();
    const SaleInvoicesData = useSelector(state => commonSelectors.setSaleInvoices(state));
    const SaleInvoicesDataSource = SaleInvoicesData ? SaleInvoicesData.toJS() : [];



    useEffect(() => {
        if (isUpdate) {
            setSelectedDate(moment(currentModalData.formData.paymentDate).format('YYYY-MM-DD'));
            setpaymentDueDate(moment(currentModalData.formData.paymentDueDate).format('YYYY-MM-DD'));
            let editorFetchedData = currentModalData.formData?.notesHtml.replace(/""/g, '');
            setEditorData(editorFetchedData);
        }
    }, [currentModalData])

    useEffect(() => {
        dispatch(commonActions.fetchAllSaleInvoices());
    }, [])

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleBillDueDateChange = (e) => {
        setpaymentDueDate(e.target.value);
    };
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        let stringifyData = `${data}`
        setEditorData(stringifyData);
    };

    const handleRecord = (actionName, saleReceiveData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            saleReceiveData.id = currentModalData.formData.id;
            saleReceiveData.companyId = currentModalData.formData.companyId;
            saleReceiveData.notesHtml = editorData;
            saleReceiveData.paymentDate = selectedDate;
            saleReceiveData.paymentDueDate = paymentDueDate;
        } else {
            saleReceiveData.companyId = 0;
            saleReceiveData.paymentDate = selectedDate;
            saleReceiveData.paymentDueDate = paymentDueDate;
            saleReceiveData.notesHtml = editorData;
        }

        dispatch(saleReceiveAction.addUpdateSaleReceive({ saleReceiveData, actionName, saleInvoiceParams: saleReceiveParams }));
        toggleModal();
    };

    return (

        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={800}
            zIndex={99999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="sale.receive.form.modal.title.update" />
                ) : (
                    <IntlMessages id="sale.receive.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="sale.receive.form.modal.update" />
                ) : (
                    <IntlMessages id="sale.receive.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="sale.receive.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="categoryForm"
                className='categoryActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    saleInvoiceId: currentModalData.formData.saleInvoiceId,
                    grandTotal: currentModalData.formData.grandTotal,
                    totalRceieved: currentModalData.formData.totalRceieved,
                    totalDue: currentModalData.formData.totalDue,
                }}
            >

                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="saleInvoiceId"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="sale.receive.form.sale.order.label.sale.invoice"
                                        popoverkey="sale.receive.form.label.popover.sale.invoice"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="sale.receive.modal.validation.sale.invoice" />,
                                    },
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    classNamePrefix="react-select"
                                    options={SaleInvoicesDataSource.map(f => ({ label: f.name, value: f.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <span>Payment Due Date</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={moment(selectedDate).format("YYYY-MM-DD")}
                                    onChange={handleDateChange}
                                />
                                {/* <Form.Item
                                    name="paymentDate"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.receive.form.label.payment.date"
                                            popoverkey="sale.receive.form.label.popover.payment.date"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.receive.modal.validation.payment.date" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        selected={moment(selectedDate)}
                                        onChange={handleDateChange}
                                        dateFormat="YYYY-MM-DD"
                                        placeholderText="Select Date"
                                        className="actions_addModalFormItem"
                                    />
                                </Form.Item> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <span>Payment Date</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={moment(paymentDueDate).format("YYYY-MM-DD")}
                                    onChange={handleBillDueDateChange}
                                />
                                {/* <Form.Item
                                    name="paymentDueDate"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.receive.form.label.payment.due.date"
                                            popoverkey="sale.receive.form.label.popover.payment.due.date"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.receive.modal.validation.payment.due.date" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        selected={moment(paymentDueDate)}
                                        onChange={handleBillDueDateChange}
                                        dateFormat="YYYY-MM-DD"
                                        placeholderText="Select Date"
                                        className="actions_addModalFormItem"
                                    />
                                </Form.Item> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="input-blocks">
                                <Form.Item
                                    name="grandTotal"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.receive.form.sale.order.label.grandTotal"
                                            popoverkey="sale.receive.form.label.popover.grandTotal"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.receive.modal.validation.grandTotal" />,
                                        },
                                    ]}
                                >
                                    <InputNumber defaultValue={0}></InputNumber>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="input-blocks">
                                <Form.Item
                                    name="totalRceieved"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.receive.form.sale.order.label.totalRceieved"
                                            popoverkey="sale.receive.form.label.popover.totalRceieved"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.receive.modal.validation.totalRceieved" />,
                                        },
                                    ]}
                                >
                                    <InputNumber defaultValue={0}></InputNumber>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="input-blocks">
                                <Form.Item
                                    name="totalDue"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.receive.form.sale.order.label.totalDue"
                                            popoverkey="sale.receive.form.label.popover.totalDue"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.receive.modal.validation.totalDue" />,
                                        },
                                    ]}
                                >
                                    <InputNumber defaultValue={0}></InputNumber>
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="input-blocks summer-description-box">
                            <Form.Item
                                name="notesHtml"
                                label={
                                    <CustomFormLabel
                                        labelkey="sale.receive.modal.description"
                                        popoverkey="sale.receive.form.shipment.label.description"
                                    />
                                }
                            >
                                <TextEditor data={editorData} onChange={handleEditorChange} />
                            </Form.Item>

                        </div>
                    </div>
                </div>
            </Form>

        </Modal >

    )
}

export default AddSaleReceiveModal
