export const ActionTypes = {
    ADD_UPDATE_SHIPMENT: 'services/inventory/shipments/add_update_shipment',
    FETCH_SHIPMENT: 'services/inventory/shipments/fetch_shipments',
    SET_SHIPMENTS: 'services/inventory/shipments/set_shipments',
    DELETE_SHIPMENTS: 'services/inventory/shipments/delete_shipments',
    SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllShipments = ({ shipmentParams }) => ({
    shipmentParams,
    type: ActionTypes.FETCH_SHIPMENT,
})

const setShipments = ({ allShipmentData }) => ({
    allShipmentData,
    type: ActionTypes.SET_SHIPMENTS,
})

const deleteShipmentById = ({ shipmentParam }) => ({
    shipmentParam,
    type: ActionTypes.DELETE_SHIPMENTS,
})

const addUpdateShipments = ({ shipmentData, actionName, UserParams }) => ({
    shipmentData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_SHIPMENT,
})

export default {
    addUpdateShipments,
    fetchAllShipments,
    setShipments,
    deleteShipmentById,
    setLoader
};
