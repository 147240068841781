export const inventoryUnitLoader = state => {
   return state.services.units.getIn(['showLoader']);
};
export const inventoryProductLoader = state => {
   return state.services.products.getIn(['showLoader']);
};

export const inventorybrandsLoader = state => {
   return state.services.brands.getIn(['showLoader']);
};

export const inventoryCategoryLoader = state => {
   ;
   return state.services.categories.getIn(['showLoader']);
};

export const inventorysubcategoryLoader = state => {
   return state.services.subcategory.getIn(['showLoader']);
};
export const inventoryVariantLoader = state => {
   return state.services.variant.getIn(['showLoader']);
};
export const inventoryWarrantyLoader = state => {
   return state.services.warranty.getIn(['showLoader']);
};

export const setBrands = state => {
   return state.services.brands.getIn(['allBrandData']);
};

export const setBrandById = state => {
   return state.services.brands.getIn(['brandParam']);
};

export const setCategories = state => {
   return state.services.categories.getIn(['allCategoryData']);
};

export const setCategoryById = state => {
   return state.services.categories.getIn(['categoryParam']);
};

export const setSubcategories = state => {
   return state.services.subcategory.getIn(['allSubcategoryData']);
};

export const setSubcategoryById = state => {
   return state.services.subcategory.getIn(['subcategoryParam']);
};

export const setUnits = state => {
   return state.services.units.getIn(['allUnitData']);
};

export const setUnitById = state => {
   return state.services.units.getIn(['unitParam']);
};
export const setProducts = state => {
   return state.services.products.getIn(['allProductData']);
};

export const setProductById = state => {
   return state.services.products.getIn(['productParam']);
};

export const setUploadProfileImage = state => {
   return state.services.brands.getIn(['brandImage']);
};

export const setWarrenties = state => {
   return state.services.warranty.getIn(['allWarrantyData']);
};

export const setWarrantyById = state => {
   return state.services.warranty.getIn(['warrantyParam']);
};

export const setVariants = state => {
   return state.services.variant.getIn(['allVariantData']);
};

export const setVariantById = state => {
   return state.services.variant.getIn(['variantParam']);
};
export const setExpiredProducts = state => {
   return state.services.variant.getIn(['allExpiredProductData']);
};


export const setSellingType = state => {
   return state.services.sellingType.getIn(['allSellingTypeData']);
};
export const sellingTypeLoader = state => {
   return state.services.sellingType.getIn(['showLoader']);
};
export const setSellingTypeById = state => {
   return state.services.sellingType.getIn(['sellingTypeParams']);
};