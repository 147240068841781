export const ActionTypes = {
    FETCH_LOGS: 'services/Logs/fetch_Logs',
    SET_LOGS: 'services/Logs/set_Logs', 
    GET_LOGS_BY_ID: 'services/Logs/get_Logs_by_id',
    SET_LOGS_BY_ID: 'services/Logs/set_Logs_by_id',
    SET_LOADER: 'services/Logs/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});
const fetchAllLogs = ({ logsParams }) => ({
    logsParams,
    type: ActionTypes.FETCH_LOGS,
});
const setLogs = ({ allLogsData }) => ({
    allLogsData,
    type: ActionTypes.SET_LOGS,
})

const getLogsById = ({ logsParams }) => ({
    logsParams,
    type: ActionTypes.GET_LOGS_BY_ID,
})

const setLogsById = ({ logsParams }) => ({
    logsParams,
    type: ActionTypes.SET_LOGS_BY_ID,
})

export default {
    fetchAllLogs,
    setLogs,
    getLogsById,
    setLogsById,
    setLoader
};