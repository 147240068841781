import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../stocktransfer/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateStockTransfers({ stocktransferData, actionName, stocktransferParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Stock/edit-stocktransfer`,
        data: stocktransferData,
      });
    } else {
      yield call(api.post, {
        route: '/Stock/create-stocktransfer',
        data: stocktransferData,
      });
    }
    
    yield put(Actions.fetchAllStockTransfers({ stocktransferParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.STOCK_TRANSFER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.STOCK_TRANSFER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllStockTransfers({ stocktransferParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch stock transfers
    const { data } = yield call(api.get, {
      route: '/Stock/all-stocktransfers',
      params: stocktransferParams,
    });

    // Store the fetched data in Redux store
    const allStockTransferData = data;
    yield put(Actions.setStockTransfers({ allStockTransferData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteStockTransferById({ stocktransferParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Stock/delete-stocktransfer/` + stocktransferParam.id,
    });
    yield put(Actions.fetchAllStockTransfers({ stocktransferParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.STOCK_TRANSFER_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_STOCKTRANSFER, fetchAllStockTransfers),
    takeLatest(ActionTypes.ADD_UPDATE_STOCKTRANSFER, addUpdateStockTransfers),
    takeLatest(ActionTypes.DELETE_STOCKTRANSFERS, deleteStockTransferById)
  ]);
}
