import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../emailsetting/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateEmailsetting({ emailsettingData, actionName, emailsettingParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-emailsetting`,
        data: emailsettingData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-emailsetting',
        data: emailsettingData,
      });
    }

    yield put(Actions.fetchAllEmailsetting({ emailsettingParams }));
    notification('success', actionName === 'update' ? "Emailsetting Updated Successfully" : "Emailsetting Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllEmailsetting({ emailsettingParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-emailsettings',
      params: emailsettingParams,
    });

    const allEmailsettingData = data;
    yield put(Actions.setEmailsetting({ allEmailsettingData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteEmailsettingById({ emailsettingParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-emailsetting/` + emailsettingParam.id,
    });
    yield put(Actions.fetchAllEmailsetting({ emailsettingParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Emailsetting Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_EMAILSETTING, fetchAllEmailsetting),
    takeLatest(ActionTypes.ADD_UPDATE_EMAILSETTING, addUpdateEmailsetting),
    takeLatest(ActionTypes.DELETE_EMAILSETTING, deleteEmailsettingById),
  ]);
}
