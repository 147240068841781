export const ActionTypes = {
    ADD_UPDATE_BANK: 'services/Settings/bank/add_update_bank',
    FETCH_BANK: 'services/Settings/bank/fetch_bank',
    SET_BANK: 'services/Settings/bank/set_bank',
    DELETE_BANK: 'services/Settings/bank/delete_bank',
    SET_LOADER: 'services/Settings/bank/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllBank = ({ bankParams }) => ({
    bankParams,
    type: ActionTypes.FETCH_BANK,
})

const setBank = ({ allBankData }) => ({
    allBankData,
    type: ActionTypes.SET_BANK,
})

const deleteBank = ({ bankParam }) => ({
    bankParam,
    type: ActionTypes.DELETE_BANK,
})

const addUpdateBank = ({ bankData, actionName, bankParams }) => ({
    bankData,
    actionName,
    bankParams,
    type: ActionTypes.ADD_UPDATE_BANK,
})

export default {
    addUpdateBank,
    fetchAllBank,
    setBank,
    deleteBank,
    setLoader
};

