import React, { useState, useRef, useEffect,  } from 'react';
import { Modal, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import headActions from '../../../core/Services/purchases/heads/actions';
import debitNoteActions from '../../../core/Services/sales/debitnotes/actions';
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import * as purchaseSelector from '../../../feature-module/purchases/purchasesSelector';
import moment from 'moment';

const AddDebitNoteModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, debitnotesParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const allSaleOrderData = useSelector(state => commonSelector.setAllSaleOrder(state));
  const allHeadTypeData = useSelector(state => purchaseSelector.setHeads(state));
  const headTypeDataSource = allHeadTypeData ? allHeadTypeData.toJS() : [];
  const SaleOrderDataSource = allSaleOrderData ? allSaleOrderData.toJS() : [];
   console.log(headTypeDataSource);
  const [rows, setRows] = useState([{ id: 1 }]); // Initial state with one row

  const addRow = () => {
    setRows([...rows, { id: rows.length + 1, crDrNoteId: 0, headId: 0, creditNo: '', amount: 0, checkNO: 0, checkDate: new Date(), description: '' }]);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id)); // Remove the row by its id
  };

  useEffect(() => {
    if (isUpdate) {
      currentModalData.formData?.crDrNoteDetails.forEach((row) => {
        setRows((prevRows) => {
          // Check if the row with this `id` already exists
          const rowExists = prevRows.some((prevRow) => prevRow.id === row.id);
          if (!rowExists) {
            return [row, ...prevRows]; // Append row if it doesn't exist
          }
          return prevRows; // Return the previous state if row already exists
        });
      });
    }
  }, [currentModalData]);

  useEffect(() => {
    dispatch(commonAction.getAllSaleOrder());
    dispatch(headActions.fetchAllHeads({ headParams: '' }));
  }, [dispatch])

  const handleRecord = (actionName, debitNoteData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      debitNoteData.id = currentModalData.formData?.id;
      debitNoteData.companyId = currentModalData.formData?.companyId;
      debitNoteData.isCredit = false;
      debitNoteData.crDrNoteDetails=  rows.filter(c =>c.headId > 0).map(detail => ({
        ...detail,
        crDrNoteId: 0,
        id: 0
      }));
    } else {
      debitNoteData.id = 0;
      debitNoteData.companyId = 0;
      debitNoteData.crDrNoteDetails = rows.slice(0, rows.length - 1).map(detail => ({
        ...detail,
        crDrNoteId: 0,
        id: 0
      }));
      debitNoteData.isCredit = false;
    }

    dispatch(debitNoteActions.addUpdateDebitNote({ debitNoteData, actionName, debitnotesParams: debitnotesParams }));
    toggleModal();
  };

  const handleFieldChange = (id, field, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
  };

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="sale.debit.notes.form.modal.title.update" />
        ) : (
          <IntlMessages id="sale.debit.notes.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          saleOrderId: currentModalData.formData?.saleOrderId,
        }}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="saleOrderId"
                    label={
                      <CustomFormLabel
                        labelkey="sale.debitnote.form.sale.order.label.name"
                        popoverkey="sale.debitnote.form.label.popover.sale.order.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="sale.debitnote.modal.validation.sale.order.name" />,
                      },
                    ]}
                  >
                    <Select
                      options={SaleOrderDataSource.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                    />
                  </Form.Item>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="modal-body-table">
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Account Ledger</th>
                      <th>Credit No</th>
                      <th>Amount</th>
                      <th>Check No</th>
                      <th>Check Date</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows?.map((row, index) => (
                      <tr key={row.id}>
                        <td>
                          <Select
                            options={headTypeDataSource.data?.map(f => ({ label: f.name, value: f.id }))}
                            classNamePrefix="react-select"
                            placeholder="Choose"
                            value={row.headId}
                            onChange={(e) => handleFieldChange(row.id, 'headId', e)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Credit No"
                            value={row.creditNo}
                            onChange={(e) => handleFieldChange(row.id, 'creditNo', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Amount"
                            value={row.amount}
                            onChange={(e) => handleFieldChange(row.id, 'amount', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Check No"
                            value={row.checkNO}
                            onChange={(e) => handleFieldChange(row.id, 'checkNO', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            className="form-control"
                            value={moment(row.checkDate).format('YYYY-MM-DD')}
                            onChange={(e) => handleFieldChange(row.id, 'checkDate', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            value={row.description}
                            onChange={(e) => handleFieldChange(row.id, 'description', e.target.value)}
                          />
                        </td>
                        <td>
                          {index === rows.length - 1 ? (
                            <button onClick={addRow} className="me-2 p-2 btn btn-success">
                              <PlusCircle className="me-2" />
                            </button>
                          ) : (
                            <button
                              onClick={() => deleteRow(row.id)}
                              className="me-2 p-2 btn btn-danger"
                            >
                              <Trash className="me-2" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddDebitNoteModal;
