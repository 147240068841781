import {
  ChevronUp,
  Edit2,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  Zap,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import Select from "react-select";
import SettingsSideBar from "../settingssidebar";
import Table from "../../../core/pagination/datatable";
import { printerSettingsData } from "../../../core/json/printerSettings";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import commonActions from '../../../core/Services/common/actions'
import printerActions from '../../../core/Services/settings/printersetting/actions'
import * as settingSelectors from '../settingsSelector'
import * as commonSelectors from '../../commonSelectors'
import { useDebounce } from "use-debounce";
import * as XLSX from 'xlsx';
import AddUpdatePrinterModal from "./components/addUpdatePrinterModal";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import { Spin } from "antd";
import jsPDF from "jspdf";

const PrinterSettings = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const [searchText, setSearchText] = useState("");
  const [isPrinterModalOpen, setPrinterModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [printerSettingParams, setPrinterSettingParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [debouncedText] = useDebounce(printerSettingParams, 1000);

  const isLoading = useSelector(state => commonSelectors.setLoader(state));
  const isPrinterDataLoading = useSelector(state => settingSelectors.setPrinterLoader(state));

  const allPrintersData = useSelector(state => settingSelectors.setPrinterSettings(state))
  const dataSource = allPrintersData ? allPrintersData.toJS() : [];
  const printersData = dataSource.data;

  const allConnectionTypesData = useSelector(state => commonSelectors.setAllconnectiontype(state))
  const connectionTypesData = allConnectionTypesData ? allConnectionTypesData.toJS() : [];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    dispatch(printerActions.fetchAllPrinterSettings({ printerSettingParams: debouncedText }))
  }, [])

  useEffect(() => {
    dispatch(commonActions.fetchAllconnectiontype())
  }, [])

  const handleRefresh = () => {
    dispatch(printerActions.fetchAllPrinterSettings({ printerSettingParams }))
  }

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Printer Name', 'Connection Type', 'IP Address', 'Port']];

    const tableData = printersData?.map((item, index) => [item.printerName, connectionTypesData?.filter(ct => ct.id == item.connectionTypeId)[0]?.name, item.ipAddress, item.port]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('PrinterSettings.pdf');
  };

  const handleDataIntoExcel = (filename = 'PrinterSettings.xlsx') => {
    if (printersData && printersData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(printersData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const togglePrinterModal = (value) => {
    setPrinterModalOpen();
  }


  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(printerActions.deletePrinterSetting({ printerSettingParam: row }));
      } else {
        MySwal.close();
      }
    });
  };
  const columns = [
    {
      title: "Printer Name",
      dataIndex: "printerName",
      sorter: (a, b) => a.printerName.length - b.printerName.length,
    },
    {
      title: "Connection Type",
      dataIndex: "connectionTypeId",
      sorter: (a, b) => a.connectionType.length - b.connectionType.length,
      render: (text) => {
        return (
          <span>{connectionTypesData?.filter(ct => ct.id == text)[0]?.name}</span>
        )
      }
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      sorter: (a, b) => a.ipAddress.length - b.ipAddress.length,
    },
    {
      title: "Port",
      dataIndex: "port",
      sorter: (a, b) => a.port.length - b.port.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <div className="edit-delete-action action-table-data">
          <Link
            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
            className="me-2 p-2"
            href="#"
          >
            <i data-feather="edit" className="feather-edit" />
          </Link>
          <Link className="confirm-text p-2" to="#" onClick={() => showConfirmationAlert(row)}>
            <i data-feather="trash-2" className="feather-trash-2" />
          </Link>
        </div>
      ),
    },
  ];

  const handleModal = (printerData, actionName) => {
    const printerModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? printerData : {},
    };
    setCurrentModalData(printerModalObj);
    setPrinterModalOpen(true);
  };
  return (
    <div>
      {(isLoading || isPrinterDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="page-wrapper">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Settings</h4>
                <h6>Manage your settings on portal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap w-50">
                  <div className="setting-title">
                    <h4>Printer</h4>
                  </div>
                  <div className="page-header justify-content-end">
                    <ul className="table-top-head">
                      <li>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <Link onClick={() => handleGeneratePDF()}>
                            <ImageWithBasePath
                              src="assets/img/icons/pdf.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderExcelTooltip}
                        >
                          <Link onClick={() => handleDataIntoExcel()}>
                            <ImageWithBasePath
                              src="assets/img/icons/excel.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                    </ul>
                    <div className="page-btn">
                      <Link
                        onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                        to="#"
                        className="btn btn-added"
                      >
                        <PlusCircle className="me-2" />
                        Add New Printer
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card table-list-card">
                        <div className="card-body">
                          <div className="table-top">
                            <div className="search-set">
                              <div className="search-input">
                                <Link to="#" className="btn btn-searchset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search"
                                  >
                                    <circle cx={11} cy={11} r={8} />
                                    <line
                                      x1={21}
                                      y1={21}
                                      x2="16.65"
                                      y2="16.65"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  id="DataTables_Table_0_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    {" "}
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search"
                                      aria-controls="DataTables_Table_0"
                                      value={searchText}
                                      onChange={handleSearch}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isPrinterModalOpen ? (
                            <AddUpdatePrinterModal
                              currentModalData={currentModalData}
                              isModalOpen={isPrinterModalOpen}
                              isUpdate={currentModalData.formData.id ? true : false}
                              toggleModal={togglePrinterModal}
                              printerSettingParams={printerSettingParams}
                              optionsData = {connectionTypesData}
                            />
                          ) : null}
                          <div className="table-responsive">
                            <Table
                            isLoading={isLoading}
                              columns={columns}
                              dataSource={dataSource}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrinterSettings;
