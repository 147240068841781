import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPaymentGateways = (state, { allPaymentGatewayData }) => {
    if (!allPaymentGatewayData) return state;
    return state.merge({ allPaymentGatewayData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_PAYMENTGATEWAYS,
        actionFunction: setPaymentGateways,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
