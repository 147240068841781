import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import { useDebounce } from 'use-debounce';
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter, Zap } from "react-feather";
import Select from "react-select";
import Table from "../../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddSalesReturns from "./addsalesreturns";
import salereturnAction from '../../../core/Services/sales/saleReturn/actions.js';
import apiResponseAction from '../../../core/Services/apiResponse/actions.js';
import * as salesSelectors from '../saleSelectors.js';
import * as ApiResponseSelectors from '../../selectors.js';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const SalesReturn = () => {
  const dispatch = useDispatch();
  const [saleReturnParams, setsaleReturnParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const MySwal = withReactContent(Swal);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(saleReturnParams, 1000);
  const isLoading = useSelector(state => salesSelectors.setLoader(state));
  const saleReturnsData = useSelector(state => salesSelectors.setSaleReturns(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = saleReturnsData ? saleReturnsData.toJS() : [];

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleWarrantyModal();
    }
  }, [apiResponseStatus])

  useEffect(() =>{
    dispatch(salereturnAction.fetchAllSaleReturns({saleReturnParams: debouncedText}))
  },[dispatch]);

  const toggleModal = (value) => {
    setModalOpen();
  }

  
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productname",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img" />
          <ImageWithBasePath alt="img" src={record.img} />
          <Link to="#" className="ms-2">
            {text}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productname.length - b.productname.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === "Received" && (
            <span className="badges bg-lightgreen">{text}</span>
          )}
          {text === "Pending" && (
            <span className="badges bg-lightred">{text}</span>
          )}
          {text === "Ordered" && (
            <span className="badges bg-lightyellow">{text}</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Grand Total ($)",
      dataIndex: "grandtotal",
      sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Paid",
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: "Due ($)",
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },
    {
      title: "paymentstatus",
      dataIndex: "paymentstatus",
      render: (text) => (
        <div>
          {text === "Paid" && (
            <span className="badges bg-lightgreen">{text}</span>
          )}
          {text === "Unpaid" && (
            <span className="badges bg-lightred">{text}</span>
          )}
          {text === "Partial" && (
            <span className="badges bg-lightyellow">{text}</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.paymentstatus.length - b.paymentstatus.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-categories"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
 

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(salereturnAction.deleteSaleReturnById({saleReturnParam:row}))
      } else {
        MySwal.close();
      }
    });
  };

  const handleDataIntoExcel = (filename = 'Categories.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Id', 'Warranty Name', 'Period', 'Duration', 'Description', 'Status']];


    const tableData = dataSource.map((item, index) => [item.id, item.warrantyName, item.warrantyPeriod, item.warrantyDuration, item.warrantyDescription, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Warrenty.pdf');
  };

  const handleRefresh = () => {
    dispatch(warrantyAction.fetchAllWarranties({ shipmentParams }));
  }

  const handleModal = (saleReceiveData, actionName) => {

    const saleReceiveModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? saleReceiveData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(saleReceiveModalObj);
    setModalOpen(true);
  };
  
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Return List</h4>
                <h6>Manage your Returns</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link 
                  onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip} >
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
            <a
              onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
              className="btn btn-added"
            >
              <PlusCircle className="me-2" />
              Add Sale Return
            </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isModalOpen ? (
              <AddSalesReturns
                currentModalData={currentModalData}
                isModalOpen={isModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleModal}
                saleReturnParams ={saleReturnParams}
              />
            ) : null}
              {/* /Filter */}
              <div className="table-responsive">
                <Table isLoading={isLoading} columns={columns} dataSource={dataSource} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default SalesReturn;
