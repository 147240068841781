import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from "react-redux";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Spin } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import * as XLSX from 'xlsx';
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import purchaseInvoiceActions from '../../../core/Services/purchases/purchaseInvoice/actions';
import * as purchasesSelector from '../purchasesSelector';
import { setToogleHeader } from "../../../core/redux/action";
import Table from "../../../core/pagination/datatable";
import AddPurchaseInvoice from "../../../feature-module/purchases/invoice/addpurchaseinvoice";
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";


const PurchasesInvoiceList = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [isPurchaseInvoiceModalOpen, setPurchaseInvoiceModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [purchaseInvoiceParams, setPurchaseInvoiceParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [debouncedText] = useDebounce(purchaseInvoiceParams, 1000);

  const isLoading = useSelector(state => purchasesSelector.purchaseInvoiceLoader(state));
  const allPurchasesData = useSelector(state => purchasesSelector.setPurchaseInvoices(state))
  const dataSource = allPurchasesData ? allPurchasesData.toJS() : [];
  const purchasedata = dataSource.data;
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    dispatch(purchaseInvoiceActions.fetchAllPurchaseInvoices({ purchaseInvoiceParams: debouncedText }));
  }, [dispatch]);
 
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Bill Date', 'Bill Due Date', 'Notes', 'Supplier DO No.', 'Supplier Invoice No.', 'Status', 'Other Tax', 'Discount', 'Shipping Cost', 'Total Count']];

    const tableData = purchasedata?.map((item, index) => [moment(item.billDate).format('YYYY-MM-DD'), moment(item.billDueDate).format('YYYY-MM-DD'), item.notesHtml, item.supplierDONo, item.supplierInvoiceNo, item.status, item.otherTax,item.disscount,item.shippingCost,item.totalCount]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Invoices.pdf');
  };

  const handleDataIntoExcel = (filename = 'invoices.xlsx') => {
    if (purchasedata && purchasedata.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(purchasedata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleRefresh = () => {
    dispatch(purchaseInvoiceActions.fetchAllPurchaseInvoices({ purchaseInvoiceParams }));
  }

  const togglePurchaseInvoiceModal = (value) => {
    setPurchaseInvoiceModalOpen();
  }
  
  const columns = [
    // {
    //   title:<IntlMessages id="purchaseinvoice.table.column.BillNo" />,
    //   dataIndex: "BillNo",
    //   sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    // },
    {
      title:<IntlMessages id="purchaseinvoice.table.column.GRN" />,
      dataIndex: "GRN",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },

    {
      title:<IntlMessages id="purchaseinvoice.table.column.SupplierDO" />,
      dataIndex: "supplierDONo",
      sorter: (a, b) => a.supplierDONo.localeCompare(b.supplierDONo),
    },
    {
      title:<IntlMessages id="purchaseinvoice.table.column.SupplierInvoiceNo" />,
      dataIndex: "supplierInvoiceNo",
      sorter: (a, b) => a.supplierInvoiceNo.localeCompare(b.supplierInvoiceNo),
    },
    {
      title:<IntlMessages id="purchaseinvoice.table.column.billdate" />,
      dataIndex: "billDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => new Date(a.billDate) - new Date(b.billDate),
    },
    {
      title:<IntlMessages id="purchaseinvoice.table.column.billduedate" />,
      dataIndex: "billDueDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => new Date(a.billDueDate) - new Date(b.billDueDate),
    },
    {
      title:<IntlMessages id="purchaseinvoice.table.column.status" />,
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badges ${
            text === "Received" ? "status-badge" : "badge-bgdanger"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    // {
    //   title:<IntlMessages id="purchaseinvoice.table.column.grandtotal" />,
    //   dataIndex: "grandTotal",
    //   sorter: (a, b) => a.grandTotal.length - b.grandTotal.length,
    // },
    // {
    //   title:<IntlMessages id="purchaseinvoice.table.column.paid" />,
    //   dataIndex: "paid",
    //   sorter: (a, b) => a.paid.length - b.paid.length,
    // },
    // {
    //   title:<IntlMessages id="purchaseinvoice.table.column.due" />,
    //   dataIndex: "due",
    //   sorter: (a, b) => a.due.length - b.due.length,
    // },
    {
      title:<IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">

            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
      MySwal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          showCancelButton: true,
          confirmButtonColor: '#00ff00',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#ff0000',
          cancelButtonText: 'Cancel',
      }).then((result) => {
          if (result.isConfirmed) {
            dispatch(purchaseInvoiceActions.deletePurchaseInvoices({ purchaseInvoiceParam: row }));
          } else {
              MySwal.close();
          }

      });
  };

  const handleModal = (purchaseInvoiceData, actionName) => {
    const purchaseInvoiceModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? purchaseInvoiceData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(purchaseInvoiceModalObj);
    setPurchaseInvoiceModalOpen(true);
  };

  return (
    <div>
      <div className="page-wrapper">
        {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchaseinvoice.table.heading.purchaseinvoice" /></h4>
                <h6><IntlMessages id="purchaseinvoice.table.label.managepurchaseinvoice" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  className="btn btn-added"
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                >
                  <PlusCircle className="me-2" />
                  <IntlMessages id="purchaseinvoice.table.button.addnewinvoice" />
                </Link>
              </div>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
              <div className="search-set">
                              <div className="search-input">
                                <Link to="#" className="btn btn-searchset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search"
                                  >
                                    <circle cx={11} cy={11} r={8} />
                                    <line
                                      x1={21}
                                      y1={21}
                                      x2="16.65"
                                      y2="16.65"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  id="DataTables_Table_0_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    {" "}
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search"
                                      aria-controls="DataTables_Table_0"
                                      value={searchText}
                                      onChange={handleSearch}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
              </div>
              {isPurchaseInvoiceModalOpen ? (
              <AddPurchaseInvoice
                currentModalData={currentModalData}
                isModalOpen={isPurchaseInvoiceModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={togglePurchaseInvoiceModal}
                purchaseInvoiceParams={purchaseInvoiceParams}
              />
            ) : null}
              <div className="table-responsive product-list">
                <Table columns={columns} isLoading={isLoading} dataSource={dataSource} />
              </div>
            </div>
          </div>
        </div>
       </div>
    </div>
  );
};

export default PurchasesInvoiceList;
