import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../sales/saleInvoice/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateSaleInvoice({ saleInvoiceData, actionName, saleInvoiceParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Sales/edit-saleinvoice`,
        data: saleInvoiceData,
      });
    } else {
      yield call(api.post, {
        route: '/Sales/create-saleinvoice',
        data: saleInvoiceData,
      });
    }
    
    yield put(Actions.fetchAllSaleInvoices({ saleInvoiceParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SALE_INVOICE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SALE_INVOICE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllSaleInvoices({ saleInvoiceParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch sale invoices
    const { data } = yield call(api.get, {
      route: '/Sales/all-saleinvoices',
      params: saleInvoiceParams,
    });

    // Store the fetched data in Redux store
    const allSaleInvoiceData = data;
    yield put(Actions.setSaleInvoices({ allSaleInvoiceData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteSaleInvoiceById({ saleInvoiceParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Sales/delete-saleinvoice/` + saleInvoiceParam.id,
    });
    yield put(Actions.fetchAllSaleInvoices({ saleInvoiceParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.SALE_INVOICE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* getQuotationNoById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/Sales/all-quotationByQN?Id=` + QnParam,
    });
    const QuotationData = data;
    yield put(Actions.setQuotationNoById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SALE_INVOICE, fetchAllSaleInvoices),
    takeLatest(ActionTypes.ADD_UPDATE_SALE_INVOICE, addUpdateSaleInvoice),
    takeLatest(ActionTypes.DELETE_SALE_INVOICE, deleteSaleInvoiceById),
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, getQuotationNoById)
  ]);
}
