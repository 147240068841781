import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Edit, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import withReactContent from "sweetalert2-react-content";
import Table from "../../../core/pagination/datatable";
import StockadjustmentModal from "./stockadjustmentModal";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronUp, PlusCircle, RotateCcw } from 'feather-icons-react/build/IconComponents';
import StockActions from '../../../core/Services/stock/stockadjustment/actions';
import * as StockSelector from '../stockSelector';

const StockAdjustment = () => {
  const stockAdjustmentData = useSelector(state => StockSelector.setStockAdjustments(state));
  const isLoading = useSelector(state => StockSelector.setLoader(state));
  const dataSource = stockAdjustmentData ? stockAdjustmentData.toJS() : [];
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const params = {
    pageNumber: currentPage,
    pageSize: pageSize,
  }

  useEffect(() => {
    dispatch(StockActions.fetchAllStockAdjustments({ stockadjustmentParams: params }));
  }, [dispatch, currentPage, pageSize])

  const handleRefresh = () => {
    dispatch(StockActions.fetchAllStockAdjustments({ stockadjustmentParams: params }));
  }

  const handleEditModal = (row) => {
    setCurrentModalData(row);
    setIsUpdate(true);
    setModalOpen(true);
  }

  const toggleModal = () => {
    const action = isModalOpen ? false : true;
    if (action === false) {
      setIsUpdate(false)
      setCurrentModalData(null)
    }
    setModalOpen(action);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  dataSource.data = dataSource?.data?.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData?.current);
    setPageSize(paginationData?.pageSize);
  };

  const handleGeneratePDF = () => {
    debugger;
    const doc = new jsPDF();
    const headers = [['warehouse', 'department', 'date', 'person', 'quanity']];

    const tableData = dataSource?.data?.map((item, index) => [item.wareHouse, item.shop, item.dateCreated, item.person, item.quantity]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('StockAdjustment.pdf');
  };

  const handleDataIntoExcel = (filename = 'StockAdjustment.xlsx') => {
    if (dataSource?.data && dataSource?.data?.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(dataSource?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Warehouse",
      dataIndex: "wareHouse",
      sorter: (a, b) => a.Warehouse.length - b.Warehouse.length,
    },
    {
      title: "Shop",
      dataIndex: "shop",
      sorter: (a, b) => a.Shop.length - b.Shop.length,
    },
    {
      title: "Product",
      dataIndex: "productName",
      sorter: (a, b) => a.Product.Name.length - b.Product.Name.length,
    },

    {
      title: "Date",
      dataIndex: "dateCreated",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Person",
      dataIndex: "person",
      sorter: (a, b) => a.Person.Name.length - b.Person.Name.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditModal(row)}
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(row)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(StockActions.deleteStockAdjustmentById({ stockadjustmentParam: row }));
      } else {
        MySwal.close();
      }
    });
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Stock Adjustment</h4>
              <h6>Manage your stock adjustment</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link onClick={() => handleGeneratePDF()}>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                  <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <Link
              to="#"
              className="btn btn-added"
              onClick={toggleModal}
            >
              <PlusCircle className="me-2" />
              Add New
            </Link>
          </div>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <Link to="#" className="btn btn-searchset">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx={11} cy={11} r={8} />
                    <line
                      x1={21}
                      y1={21}
                      x2="16.65"
                      y2="16.65"
                    />
                  </svg>
                </Link>
                <div
                  id="DataTables_Table_0_filter"
                  className="dataTables_filter"
                >
                  <label>
                    {" "}
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            isLoading={isLoading}
            className="table datanew"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: dataSource?.totalRecords,
              showSizeChanger: false,
            }}
            onChange={handlePageChange}
          />
        </div>
      </div>
      {isModalOpen ?
        <StockadjustmentModal
          currentModalData={currentModalData}
          isUpdate={isUpdate}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen} />
        : null}
    </div>
  );
};

export default StockAdjustment;
