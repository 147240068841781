import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setstatus2fa = (state, { allfaData }) => {
    if (!allfaData) return state;
    return state.merge({ allfaData });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_STATUS_2FA,
        actionFunction: setstatus2fa,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
