import React, { useEffect, useState } from 'react';
import * as posSelectors from '../../posSelectors.js';
import advanceBookingsActions from '../../../../core/Services/pos/advanceBookings/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Input } from 'antd';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants.js";
import IntlMessages from "../../../../shared/components/utility/intlMessages.js";
import '../advanceBookings.css';
import '../../pos.css';
import * as ApiResponseSelectors from '../../../selectors.js';
import TextArea from 'antd/es/input/TextArea.js';
import advanceBillActions from '../../../../core/Services/pos/advanceBookings/actions.js'
import { PlusCircle, Trash } from 'feather-icons-react/build/IconComponents/index.js';

const AddMoreAdvance = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, advanceBookingsParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const todayExpensesData = useSelector(state => posSelectors.setAdvanceBookings(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));


    const [rows, setRows] = useState([{ id: 1, amount: '', description: '' }]);


    // const addNewRow = () => {
    //     setRows([...rows, { id: rows.length + 1, amount: '', description: '' }]);
    // };

    const addNewRow = () => {
        const newRow = { id: Date.now(), amount: '', description: '' }; // Using Date.now() for unique IDs
        setRows([...rows, newRow]);
    };

    // const deleteRow = (id) => {
    //     debugger
    //     setRows(rows.filter((row) => row.id !== id)); // Remove the row by its id
    // };

    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id);
        setRows(updatedRows);
        // Reset the form values based on the remaining rows
        form.setFieldsValue({
            rows: updatedRows,
        });
    };

    const handleFieldChange = (id, field, value) => {
        const updatedRows = rows.map(row =>
            row.id === id ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
    };

    const handleRecord = (actionName, advanceBillsData) => {
        debugger
        if (isUpdate == true) {
            advanceBillsData.id = currentModalData?.formData?.id;
            advanceBillsData.advanceAmount = advanceBillsData.rows[0].amount;
            advanceBillsData.description = advanceBillsData.rows[0].description;
            advanceBillsData.advanceBookingId = currentModalData?.formData?.advanceBookingId;

            delete advanceBillsData.amount_0;
            delete advanceBillsData.description_0;

            dispatch(advanceBillActions.addUpdateAdvanceBills({ advanceBillsData: advanceBillsData, actionName: 'update', advanceBookingsParams: advanceBookingsParams }));
        }
        else {
            const formattedData = rows.map((row, index) => ({
                advanceBookingId: currentModalData?.formData?.id,
                advanceAmount: advanceBillsData.rows[index].amount,
                description: advanceBillsData.rows[index].description,
                // description: advanceBillsData[`description_${index}`]
            }));

            dispatch(advanceBillActions.addUpdateAdvanceBills({ advanceBillsData: formattedData, actionName: 'add', advanceBookingsParams: advanceBookingsParams }));
        }
        toggleModal();
    };

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            // Create an array of rows (as needed)
            const existingRows = [
                {
                    amount: currentModalData.formData.advanceAmount,
                    description: currentModalData.formData.description,
                }
            ];

            // Set the rows state for dynamic rendering
            setRows(existingRows);

            // Prepare form values object for nested structure
            const formValues = {
                rows: existingRows.map((row, index) => ({
                    amount: row.amount,
                    description: row.description
                }))
            };

            // Set form fields
            form.setFieldsValue(formValues);
        }
    }, [currentModalData, isUpdate]);




    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={800}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={<IntlMessages id="advanceBookings.pos.more.advance.form.modal.title.add" />}
            okText={isUpdate ? 'Update' : 'Add'}
            cancelText={<IntlMessages id="advanceBookings.pos.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="advanceMoreForm"
                className='advanceBookingsActions_addModalWrapper'
                initialValues={{ rows }}
            >

                <div className="row">
                    <div className="col-lg-12">
                        <div className="modal-body-table">
                            <div className="table-responsive">
                                <table className="table  datanew">
                                    <thead>
                                        <tr>
                                            <th>Amount</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={row}>
                                                <td>
                                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                                        <div className="input-blocks">
                                                            <Form.Item
                                                                name={['rows', index, 'amount']}
                                                                rules={[{ required: true, message: 'Amount Required' }, 
                                                                    {
                                                                        pattern: /^\d+$/,
                                                                        message: "Amount must be a number",
                                                                    }]}
                                                            >
                                                                <Input
                                                                    placeholder="Enter Advance Amount"
                                                                    value={row.amount}
                                                                    onChange={(e) =>
                                                                        handleFieldChange(row.id, 'amount', e.target.value)
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                                        <div className="input-blocks">
                                                            <Form.Item name={['rows', index, 'description']}>
                                                                <Input
                                                                    placeholder="Description"
                                                                    value={row.description}
                                                                    onChange={(e) =>
                                                                        handleFieldChange(row.id, 'description', e.target.value)
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {!isUpdate ? (
                                                        <Form.Item
                                                            name={`description_${index}`}
                                                        // label={<IntlMessages id="pos.advanceBookings.more.advance.form.label.description" />}
                                                        >
                                                            {index === rows.length - 1 ? (
                                                                <button onClick={addNewRow} className="btn btn-success">
                                                                    <PlusCircle className="" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    onClick={() => deleteRow(row.id)}
                                                                    className="btn btn-danger"
                                                                >
                                                                    <Trash className="" />
                                                                </button>
                                                            )}
                                                        </Form.Item>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal >
    );
}

export default AddMoreAdvance;
