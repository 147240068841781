import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setDebitNotes = (state, { allDebitNoteData }) => {
    if (!allDebitNoteData) return state;
    return state.merge({ allDebitNoteData });
}

const setDebitNoteById = (state, { debitNoteParam }) => {
    return state.merge({ debitNoteParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_DEBIT_NOTES,
        actionFunction: setDebitNotes,
    },
    {
        actionType: ActionTypes.SET_DEBIT_NOTE_BY_ID,
        actionFunction: setDebitNoteById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
