export const ActionTypes = {
    ADD_UPDATE_DEMANDREQUEST: 'services/stock/demandrequests/add_update_demandrequest',
    FETCH_DEMANDREQUEST: 'services/stock/demandrequests/fetch_demandrequests',
    SET_DEMANDREQUESTS: 'services/stock/demandrequests/set_demandrequests',
    DELETE_DEMANDREQUESTS: 'services/stock/demandrequests/delete_demandrequests',
    SET_LOADER: 'services/stock/demandrequests/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllDemandRequests = ({ demandrequestParams }) => ({
    demandrequestParams,
    type: ActionTypes.FETCH_DEMANDREQUEST,
})

const setDemandRequests = ({ allDemandRequestData }) => ({
    allDemandRequestData,
    type: ActionTypes.SET_DEMANDREQUESTS,
})

const deleteDemandRequestById = ({ demandrequestParam }) => ({
    demandrequestParam,
    type: ActionTypes.DELETE_DEMANDREQUESTS,
})

const addUpdateDemandRequests = ({ demandrequestData, actionName, UserParams }) => ({
    demandrequestData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_DEMANDREQUEST,
})

export default {
    addUpdateDemandRequests,
    fetchAllDemandRequests,
    setDemandRequests,
    deleteDemandRequestById,
    setLoader
};
