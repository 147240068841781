export const ActionTypes = {
    ADD_NOTIFICATION: 'services/Settings/notification/add_update_notification',
    FETCH_NOTIFICATION: 'services/Settings/notification/fetch_notification',
    SET_NOTIFICATION: 'services/Settings/notification/set_notification',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllNotifications = ({ notificationsParams }) => ({
    notificationsParams,
    type: ActionTypes.FETCH_NOTIFICATION,
})

const setNotifications = ({ allnotificationsData }) => ({
    allnotificationsData,
    type: ActionTypes.SET_NOTIFICATION,
})

const addNotification = ({ NotificationData, actionName, NotificationParams }) => ({
    NotificationData,
    actionName,
    NotificationParams,
    type: ActionTypes.ADD_NOTIFICATION,
})

export default {
    fetchAllNotifications,
    addNotification,
    setNotifications,
    setLoader
};
