import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setUserProfiles = (state, { allUserProfileData }) => {
    if (!allUserProfileData) return state;
    return state.merge({ allUserProfileData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_USERPROFILES,
        actionFunction: setUserProfiles,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
