import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import SettingsSideBar from '../settingssidebar'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { Button, Form, Image, Spin, Switch, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import reportActions from '../../../core/Services/settings/reportsetting/actions'
import commonActions from '../../../core/Services/common/actions'
import * as commonSelectors from '../../commonSelectors'
import * as reportSelectors from '../settingsSelector'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import TextArea from 'antd/es/input/TextArea'
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import axios from 'axios'

const InvoiceSettings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [reportsettingParams, setReportSettingParams] = useState({ pageNo: 1, searchText: '' });
    const [debouncedText] = useDebounce(reportsettingParams, 1000);

    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isError, setisError] = useState(false);


    const isImageLoading = useSelector(state => commonSelectors.setLoader(state));
    const isLoading = useSelector(state => reportSelectors.setReportLoader(state));

    const reportAllData = useSelector(state => reportSelectors.setReportSettings(state));
    const dataSource = reportAllData ? reportAllData.toJS() : [];
    const reportData = dataSource?.data

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ file, fileList: newFileList }) => setFileList([file]);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    useEffect(() => {
        uploadFromURL(reportData?.logoUrl)
    }, [reportData?.logoUrl]);

    const uploadFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...fileList,
                {
                    uid: `-${fileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    useEffect(() => {
        dispatch(reportActions.fetchAllReportSettings({ reportsettingParams: debouncedText }))
    }, [dispatch, debouncedText, reportsettingParams])

    const handleRefresh = () => {
        dispatch(reportActions.fetchAllReportSettings({ reportsettingParams }))
    }

    const handleValidateFormData = () => {
        form.validateFields()
            .then(values => {
                handleRecord(values);
            })
            .catch(info => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    }

    const handleRecord = (reportSettingData) => {
        reportSettingData.companyId = 1;
        reportSettingData.showCompanyDetails = reportSettingData.showCompanyDetails == true ? true : false;
        reportSettingData.reportImage = fileList[0];
        dispatch(reportActions.addReportSetting({ reportSettingData, actionName: "Add", reportSettingParams: reportsettingParams }));
    };

    useEffect(() => {
        if (reportData) {
            form.setFieldsValue({ showCompanyDetails: reportData.showCompanyDetails })
            form.setFieldsValue({ header: reportData.header })
            form.setFieldsValue({ footer: reportData.footer })
        }
    }, [reportData])

    return (
        <div>
            {(isLoading || isImageLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh">
                                    <i data-feather="rotate-ccw" onClick={handleRefresh} className="feather-rotate-ccw" />
                                </a>
                            </li>
                            <li>
                                <a
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Collapse"
                                    id="collapse-header"
                                >
                                    <i data-feather="chevron-up" className="feather-chevron-up" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <form>
                                        <div className="setting-title">
                                            <h4>Invoice Settings</h4>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            initialValues={{}}
                                            name="forForm" className='productActions_addModalWrapper'>
                                            <div className="company-info border-0">
                                                <ul className="logo-company">
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="logo-info me-0 mb-3 mb-md-0">
                                                                    <h6>Report Logo</h6>
                                                                    <p>
                                                                        Upload Logo of your Company to display in Report
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-pic-upload mb-0">
                                                                    <div className="new-employee-field">
                                                                        <div className="mb-0">
                                                                            <Form.Item
                                                                                name="logoUrl"
                                                                                className="actions_addModalFormItem"
                                                                                rules={[
                                                                                    ({ getFieldValue }) => ({
                                                                                        validator(rule, value) {
                                                                                            return isError
                                                                                                ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                                                : Promise.resolve();
                                                                                        },
                                                                                    }),
                                                                                ]}
                                                                            >
                                                                                <Upload showUploadList={false} onPreview={handlePreview} onChange={handleChange}>
                                                                                    <Button className='p-3' icon={<UploadOutlined />}>
                                                                                        Upload
                                                                                    </Button>
                                                                                </Upload>
                                                                                <span>
                                                                                    For better preview recommended size is 450px x
                                                                                    450px. Max size 1MB.
                                                                                </span>
                                                                                {previewImage && (
                                                                                    <Image
                                                                                        wrapperStyle={{
                                                                                            display: 'none',
                                                                                        }}
                                                                                        preview={{
                                                                                            visible: previewOpen,
                                                                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                                        }}
                                                                                        src={previewImage}
                                                                                    />
                                                                                )}
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="ms-auto">
                                                                    <Upload
                                                                        listType="picture-circle"
                                                                        fileList={fileList}
                                                                        onPreview={handlePreview}
                                                                    >
                                                                    </Upload>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Show Report Details</h6>
                                                                <p>Show / Hide Company Details in Report</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Form.Item
                                                                name="showCompanyDetails"
                                                                className="actions_addModalFormItem"
                                                            >
                                                                <Switch className='pe-2' size='small' />
                                                            </Form.Item>
                                                            {/* <div className="localization-select d-flex align-items-center">
                                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center me-3">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="user4"
                                                                        className="check"
                                                                        defaultChecked="true"
                                                                    />
                                                                    <label htmlFor="user4" className="checktoggle" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Report Header Terms</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="mb-3">
                                                                <Form.Item
                                                                    name="header"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Report Header Terms Required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea rows={4} placeholder='Type your message' />
                                                                </Form.Item>
                                                                {/* <textarea
                                                                    rows={4}
                                                                    className="form-control"
                                                                    placeholder="Type your message"
                                                                    defaultValue="true"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Report Footer Terms</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="mb-3">
                                                                <Form.Item
                                                                    name="footer"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Report Footer Terms Required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea rows={4} placeholder='Type your message' />
                                                                </Form.Item>
                                                                {/* <textarea
                                                                    rows={4}
                                                                    className="form-control"
                                                                    placeholder="Type your message"
                                                                    defaultValue="true"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className="modal-footer-btn">
                                            <Link onClick={handleValidateFormData} to="#" className="btn btn-submit">
                                                Save Changes
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InvoiceSettings
