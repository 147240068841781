import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api.js';
import Actions, { ActionTypes } from './actions.js';
import notification from "../../../shared/components/Notification.js";
import { CONSTANT_VARIABLES } from "../../Common/Constant_Variables/constants.js";
import apiActions from '../apiResponse/actions.js';

function* addUpdateRecipes({ recipesData, actionName, recipesParams }) {
  console.log("recipe data in saga", recipesData);
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Recipe/edit-recipe`,
        data: recipesData,
      });
    } else {
      yield call(api.post, {
        route: '/Recipe/create-recipe',
        data: recipesData,
      });
    }
    
    yield put(Actions.fetchAllRecipes({ recipesParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.RECIPES_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.RECIPES_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllRecipes({ recipesParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));
    
    // Make the API call to fetch recipes
    const { data } = yield call(api.get, {
      route: '/Recipe/all-recipes',
      params: recipesParams,
    });
    
    console.log("saga recipes called", recipesParams);
    // Store the fetched data in Redux store
    const allRecipesData = data;
    yield put(Actions.setRecipes({ allRecipesData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteRecipesById({ recipesParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Recipe/delete-recipe/${recipesParam.id}`,
    });
    yield put(Actions.fetchAllRecipes({ recipesParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.RECIPES_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_RECIPES, fetchAllRecipes),
    takeLatest(ActionTypes.ADD_UPDATE_RECIPES, addUpdateRecipes),
    takeLatest(ActionTypes.DELETE_RECIPES, deleteRecipesById)
  ]);
}
