export const ActionTypes = {
  ADD_UPDATE_RECIPES: 'services/recipes/add_update_recipes',
  FETCH_RECIPES: 'services/recipes/fetch_recipes',
  SET_RECIPES: 'services/recipes/set_recipes',
  DELETE_RECIPES: 'services/recipes/delete_recipes',
  GET_RECIPES_BY_ID: 'services/recipes/get_recipes_by_id',
  SET_RECIPES_BY_ID: 'services/recipes/set_recipes_by_id',
  SET_LOADER: 'services/recipes/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllRecipes = ({ recipesParams }) => ({
  recipesParams,
  type: ActionTypes.FETCH_RECIPES,
})

const setRecipes = ({ allRecipesData }) => ({
  allRecipesData,
  type: ActionTypes.SET_RECIPES,
})

const deleteRecipesById = ({ recipesParam }) => ({
  recipesParam,
  type: ActionTypes.DELETE_RECIPES,
})

const getRecipesById = ({ recipesParam }) => ({
  recipesParam,
  type: ActionTypes.GET_RECIPES_BY_ID,
})

const setRecipesById = ({ recipesParam }) => ({
  recipesParam,
  type: ActionTypes.SET_RECIPES_BY_ID,
})

const addUpdateRecipes = ({ recipesData, actionName, UserParams }) => ({
  recipesData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_RECIPES,
})

export default {
  addUpdateRecipes,
  fetchAllRecipes,
  setRecipes,
  setRecipesById,
  deleteRecipesById,
  getRecipesById,
  setRecipesById,
  setLoader
}
