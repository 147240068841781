import React from 'react';
import moment from 'moment';
import { CONSTANT_VARIABLES } from '../Constant_Variables/constants';
import countryList from 'react-select-country-list'

export const filterDate = datetime => {
  if (!datetime) return '';
  return moment(datetime).format(CONSTANT_VARIABLES.DATE_TIME_FORMAT_YEAR);
};

export function countriesList () {
    return countryList().getData().map((item, index) => ({
      ...item,
      id: index // or any unique identifier
    }));
}