import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../../shared/components/Notification";


  function* fetchAllLogs({ logsParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch coupons
      const { data } = yield call(api.get, {
        route: '/Exception/all-exceptionslogs',
        params: logsParams,
      });
  
      // Store the fetched data in Redux store
      const allLogsData = data;
      yield put(Actions.setLogs({ allLogsData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }

  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0) {
      message.details.response.data.errors.map((value) => (
        notification('error', value)
      ));
    } else {
      notification('error', 'SERVE-POS API Issue.');
    }
  }

  export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_LOGS, fetchAllLogs),
    ]);
  }