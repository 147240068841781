export const ActionTypes = {
    ADD_UPDATE_EMAILSETTING: 'services/hrm/emailsetting/add_update_emailsetting',
    FETCH_EMAILSETTING: 'services/hrm/emailsetting/fetch_emailsetting',
    SET_EMAILSETTING: 'services/hrm/emailsetting/set_emailsetting',
    DELETE_EMAILSETTING: 'services/hrm/emailsetting/delete_emailsetting',
    SET_LOADER: 'services/hrm/emailsetting/set_loader',
};

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllEmailsetting = ({ emailsettingParams }) => ({
    emailsettingParams,
    type: ActionTypes.FETCH_EMAILSETTING,
});

const setEmailsetting = ({ allEmailsettingData }) => ({
    allEmailsettingData,
    type: ActionTypes.SET_EMAILSETTING,
});

const deleteEmailsetting = ({ emailsettingParam }) => ({
    emailsettingParam,
    type: ActionTypes.DELETE_EMAILSETTING,
});

const addUpdateEmailsetting = ({ emailsettingData, actionName, emailsettingParams }) => ({
    emailsettingData,
    actionName,
    emailsettingParams,
    type: ActionTypes.ADD_UPDATE_EMAILSETTING,
});

export default {
    addUpdateEmailsetting,
    fetchAllEmailsetting,
    setEmailsetting,
    deleteEmailsetting,
    setLoader,
};