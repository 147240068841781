import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../printersetting/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePrinterSetting({ printerSettingData, actionName, printerSettingParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-printersetting`,
        data: printerSettingData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-printersetting',
        data: printerSettingData,
      });
    }

    yield put(Actions.fetchAllPrinterSettings({ printerSettingParams }));
    notification('success', actionName === 'update' ? "Printer Setting Updated Successfully" : "Printer Setting Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPrinterSettings({ printerSettingParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-printersettings',
      params: printerSettingParams,
    });

    const allPrinterSettingData = data;
    yield put(Actions.setPrinterSettings({ allPrinterSettingData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deletePrinterSettingById({ printerSettingParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-printersetting/` + printerSettingParam.id,
    });
    yield put(Actions.fetchAllPrinterSettings({ printerSettingParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Printer Setting Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PRINTERSETTING, fetchAllPrinterSettings),
    takeLatest(ActionTypes.ADD_UPDATE_PRINTERSETTING, addUpdatePrinterSetting),
    takeLatest(ActionTypes.DELETE_PRINTERSETTING, deletePrinterSettingById)
  ]);
}
