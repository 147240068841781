import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setCurrency = (state, { allCurrencyData }) => {
    if (!allCurrencyData) return state;
    return state.merge({ allCurrencyData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_CURRENCY,
        actionFunction: setCurrency,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
