import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setCustomField = (state, { allCustomFieldData }) => {
    if (!allCustomFieldData) return state;
    return state.merge({ allCustomFieldData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_CUSTOMFIELD,
        actionFunction: setCustomField,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
