export const ActionTypes = {
    ADD_UPDATE_COUPONS: 'services/Coupons/add_update_Coupons',
    FETCH_COUPONS: 'services/Coupons/fetch_Coupons',
    SET_COUPONS: 'services/Coupons/set_Coupons', 
    DELETE_COUPONS: 'services/Coupons/delete_Coupons',
    GET_COUPONS_BY_ID: 'services/Coupons/get_Coupons_by_id',
    SET_COUPONS_BY_ID: 'services/Coupons/set_Coupons_by_id',
    SET_LOADER: 'services/Coupons/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});
const fetchAllCoupons = ({ CouponsParams }) => ({
    CouponsParams,
    type: ActionTypes.FETCH_COUPONS,
});
const setCoupons = ({ allCouponsData }) => ({
    allCouponsData,
    type: ActionTypes.SET_COUPONS,
})
const deleteCouponsById = ({ CouponsParams }) => ({
    CouponsParams,
    type: ActionTypes.DELETE_COUPONS,
})

const getCouponsById = ({ CouponsParams }) => ({
    CouponsParams,
    type: ActionTypes.GET_COUPONS_BY_ID,
})

const setCouponsById = ({ CouponsParams }) => ({
    CouponsParams,
    type: ActionTypes.SET_COUPONS_BY_ID,
})
const addUpdateCoupons = ({ CouponsData, actionName, UserParams }) => ({
    CouponsData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_COUPONS,
})
export default {
    addUpdateCoupons,
    fetchAllCoupons,
    setCoupons,
    deleteCouponsById,
    getCouponsById,
    setCouponsById,
    setLoader
};