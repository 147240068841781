import React, { useState, useEffect} from "react";
import { useDebounce } from 'use-debounce';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Calendar,
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { Box, Filter, Sliders, Zap } from "react-feather";
import Select from "react-select";
import { setToogleHeader } from "../../core/redux/action";
import AddCoupons from "./addcoupons";
import EditCoupons from "../../core/modals/coupons/editcoupons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { DatePicker } from "antd";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import * as ApiResponseSelectors from '../selectors';
import * as couponsSelectors from './couponsselector';
import couponsAction from "../../core/Services/coupons/actions"
import apiResponseAction from '../../core/Services/apiResponse/actions';
import IntlMessages from "../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from '../../core/Common/Constant_Variables/constants'


const Coupons = () => {
  const dispatch = useDispatch();
  const [CouponsParams, setCouponsParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const data = useSelector((state) => state.toggle_header);
  const CouponsData = useSelector(state => couponsSelectors.setAllCoupons(state));


  const dataSource = CouponsData ? CouponsData.toJS() : [];
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const [debouncedText] = useDebounce(CouponsParams, 1000);
  const isLoading = useSelector(state => couponsSelectors.couponsLoader(state));

  const [isCouponsModalOpen, setCouponsModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});

  useEffect(() => {
      if (apiResponseStatus === 200) {
        toggleCouponsModal();
      }
  }, [apiResponseStatus]);


  useEffect(() => {
    dispatch(couponsAction.fetchAllCoupons({ CouponsParams: debouncedText }));
}, [dispatch, debouncedText, CouponsParams]);
const toggleCouponsModal = (value) => {
  setCouponsModalOpen(!setCouponsModalOpen);
};
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const price = [
    { value: "choose", label: "Choose Type" },
    { value: "fixed", label: "Fixed" },
    { value: "percentage", label: "Percentage" },
  ];
  const pricelist = [
    { value: "Choose Name", label: "Choose Name" },
    { value: "Coupons 21", label: "Coupons 21" },
    { value: "First Offer", label: "First Offer" },
    { value: "Offer 40", label: "Offer 40" },
    { value: "Subscription", label: "Subscription" },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = ['id', 'Name',	'Code',	'Type',	'Discount',	'Limit',	'Used',	'Valid',	'Status',	'Action',];

    const tableData = dataSource.map((item) => [
        item.id,
        item.Name,
        item.Code,
        item.Type, item.Discount,
        item.Limit, item.Used,
        item.Valid, item.Status,
        item.Action,

    ]);

    doc.autoTable({
        startY: 30,
        head: [headers],
        body: tableData,
    });

    doc.save('coupons.pdf');
};
const handleDataIntoExcel = (filename = 'coupons.xlsx') => {
  if (dataSource && dataSource.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
  } else {
      console.error("No data to export");
  }
};
const handleRefresh = () => {
  dispatch(couponsAction.fetchAllCoupons({ CouponsParams}));
};
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
          dispatch(couponsAction.deleteCouponsById({ CouponsParams: row }));
      }
      else {
          MySwal.close();
      }
  });
  };

  const handleModal = (CouponsData, actionName) => {
    const couponsModalObj = {
        formData: actionName === CONSTANT_VARIABLES.ADD ? CouponsData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }));
    setCurrentModalData(couponsModalObj);
    setCouponsModalOpen(true);
};
  const columns = [
    {
        title:<IntlMessages id="coupons.table.column.name" />,
        dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,

    },
    {
      title:<IntlMessages id="coupons.table.column.code" />,
      dataIndex: "code",
      sorter: (a, b) => a.code.length - b.code.length,

      },
   {
    title:<IntlMessages id="coupons.table.column.type" />,
    dataIndex: "type",
    sorter: (a, b) => a.type.length - b.type.length,

     },
  {
  title:<IntlMessages id="coupons.table.column.discount" />,
  dataIndex: "disscount",
  sorter: (a, b) => a.disscount.length - b.disscount.length,

     },
  {
  title:<IntlMessages id="coupons.table.column.limit" />,
  dataIndex: "limit",
  sorter: (a, b) => a.limit.length - b.limit.length,

      },
      {
        title:<IntlMessages id="coupons.table.column.productId" />,
        dataIndex: "productId",
        sorter: (a, b) => a.productId.length - b.productId.length,

            },
      {
        title:<IntlMessages id="coupons.table.column.startdate" />,
        dataIndex: "startDate",
        render: (text) => {
          const date = new Date(text);
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }).replace(/ /g, ' ');
          return formattedDate;
        },
        sorter: (a, b) => a.startDate.length - b.startDate.length,

           }, 
    {
  title:<IntlMessages id="coupons.table.column.enddate" />,
  dataIndex: "endDate",
  render: (text) => {
    const date = new Date(text);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace(/ /g, ' ');
    return formattedDate;
  },
  sorter: (a, b) => a.endDate.length - b.endDate.length,

     },
     {
      title:<IntlMessages id="coupons.table.column.used" />,
      dataIndex: "isOncePerCustomer",
      sorter: (a, b) => a.isOncePerCustomer.length - b.isOncePerCustomer.length,
      render: (text) => (
        <div>
            <span className={`${text ? "badge badge-linedanger" : "badge badge-linesuccess"}`}>
                {text ? 'Used' : 'Not Used'}
            </span>
        </div>
    ),
         },
     {
      title:<IntlMessages id="coupons.table.column.status" />,
      dataIndex: "isActive",
      render: (text) => (
          <div>
              <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>
                  {text ? 'Active' : 'Inactive'}
              </span>
          </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
  },
  
    {
        title:<IntlMessages id="table.column.actions" />,
        dataIndex: "actions",
        key: "actions",
        render: (text, row) => (
            <div className="action-table-data">
                <div className="edit-delete-action">
                    <Link
                        className="me-2 p-2"
                        onClick={() => handleModal(row, CONSTANT_VARIABLES.ADD)}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                    <Link
                        className="confirm-text p-2"
                        onClick={() => showConfirmationAlert(row)}
                    >
                        <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                </div>
            </div>
        ),
    },
];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Coupons</h4>
                <h6>Manage Your Coupons</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                  onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top"
                  onClick={() => handleDataIntoExcel()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top"
                  onClick={handleRefresh}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                Add New Coupons
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <div className="d-flex align-items-center">
                    <Link
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                    >
                      <Filter
                        className="filter-icon"
                        onClick={toggleFilterVisibility}
                      />
                      <span onClick={toggleFilterVisibility}>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </Link>
                    {/* <div className="layout-hide-box">
                      <Link to="#" className="me-3 layout-box">
                        <i data-feather="layout" className="feather-search" />
                      </Link>
                      <div className="layout-drop-item card">
                        <div className="drop-item-head">
                          <h5>Want to manage datatable?</h5>
                          <p>
                            Please drag and drop your column to reorder your
                            table and enable see option as you want.
                          </p>
                        </div>
                        <ul>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Shop
                              </span>
                              <input
                                type="checkbox"
                                id="option1"
                                className="check"
                                defaultChecked=""
                              />
                              <label
                                htmlFor="option1"
                                className="checktoggle"
                              />
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Product
                              </span>
                              <input
                                type="checkbox"
                                id="option2"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option2" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Reference No
                              </span>
                              <input
                                type="checkbox"
                                id="option3"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option3" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Date
                              </span>
                              <input
                                type="checkbox"
                                id="option4"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option4" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Responsible Person
                              </span>
                              <input
                                type="checkbox"
                                id="option5"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option5" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Notes
                              </span>
                              <input
                                type="checkbox"
                                id="option6"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option6" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Quantity
                              </span>
                              <input
                                type="checkbox"
                                id="option7"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option7" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                              <span className="status-label">
                                <i
                                  data-feather="menu"
                                  className="feather-menu"
                                />
                                Actions
                              </span>
                              <input
                                type="checkbox"
                                id="option8"
                                className="check"
                                defaultChecked=""
                              />
                              <label htmlFor="option8" className="checktoggle">
                                {" "}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />

                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={pricelist}
                          placeholder="Choose Type"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Box className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={price}
                          placeholder="Choose Type"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Calendar className="info-img" />
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          type="date"
                          className="filterdatepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholder="20-2-2024"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
              <Table isLoading={isLoading} columns={columns} dataSource={dataSource}/>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {isCouponsModalOpen && (
                        <AddCoupons
                            currentModalData={currentModalData}
                            isModalOpen={isCouponsModalOpen}
                            isUpdate={currentModalData.formData.id ? true : false}
                            toggleModal={toggleCouponsModal}
                        />
                    )}

      <EditCoupons />
    </div>
  );
};

export default Coupons;
