import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import paymentActions from '../../../../core/Services/settings/paymentgateway/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdatePaymentGatewayModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, paymentGatewayParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, paymentGatewayData) => {
        debugger
        paymentGatewayData.companyId = 1
        paymentGatewayData.isActive = paymentGatewayData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            paymentGatewayData.id = currentModalData.formData.id
        }

        dispatch(paymentActions.addUpdatePaymentGateways({ paymentGatewayData, actionName, paymentGatewayParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ name: currentModalData.formData.name })
            form.setFieldsValue({ apiKey: currentModalData.formData.apiKey })
            form.setFieldsValue({ secretKey: currentModalData.formData.secretKey })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Currency"
                ) : (
                    "Add New Currency"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '30px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="name"
                                        label="Payment Gateway Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Payment Gateway Required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Only trigger required validation when empty
                                                    }

                                                    if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                                                        return Promise.reject(new Error("Payment Gateway must be a valid alphanumeric string."));
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Payment Gateway cannot exceed 50 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Payment Gateway Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // All validations passed
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="apiKey"
                                        label="API Key"
                                        rules={[
                                            {
                                                required: true,
                                                message: "API Key is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length < 10) {
                                                        return Promise.reject(new Error("API Key must be at least 10 characters."));
                                                    }

                                                    if (value.length > 64) {
                                                        return Promise.reject(new Error("API Key cannot be longer than 64 characters."));
                                                    }

                                                    if (!/^[A-Za-z0-9\-]+$/.test(value)) {
                                                        return Promise.reject(new Error("API Key can only contain alphanumeric characters and hyphens."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter API Key'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="secretKey"
                                        label="Secret Key"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Secret Key is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length < 10) {
                                                        return Promise.reject(new Error("Secret Key must be at least 10 characters."));
                                                    }

                                                    if (value.length > 128) {
                                                        return Promise.reject(new Error("Secret Key cannot be longer than 128 characters."));
                                                    }

                                                    if (!/^[A-Za-z0-9\-_.]+$/.test(value)) {
                                                        return Promise.reject(new Error("Secret Key can only contain alphanumeric characters, hyphens, underscores, and periods."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Secret Key'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10 d-flex">
                                    <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <Switch size='small' className='pe-2 mb-2' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdatePaymentGatewayModal;