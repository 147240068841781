import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setCompanySettings = (state, { allCompanySettingData }) => {
    if (!allCompanySettingData) return state;
    return state.merge({ allCompanySettingData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setLogoImage = (state, { uploadLogoImageUrl }) => {
    if (!uploadLogoImageUrl) return state;
    return state.merge({ uploadLogoImageUrl });
};

const setIconImage = (state, { uploadIconImageUrl }) => {
    if (!uploadIconImageUrl) return state;
    return state.merge({ uploadIconImageUrl });
};

const setFavIconImage = (state, { uploadFavImageUrl }) => {
    if (!uploadFavImageUrl) return state;
    return state.merge({ uploadFavImageUrl });
};

const setDarkLogoImage = (state, { uploadDarkImageUrl }) => {
    if (!uploadDarkImageUrl) return state;
    return state.merge({ uploadDarkImageUrl });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_COMPANYSETTING,
        actionFunction: setCompanySettings,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_LOGO_IMAGE,
        actionFunction: setLogoImage,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_ICON_IMAGE,
        actionFunction: setIconImage,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_FAV_IMAGE,
        actionFunction: setFavIconImage,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_DARK_IMAGE,
        actionFunction: setDarkLogoImage,
    },
];
