import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Spin } from "antd";
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import StockActions from '../../../core/Services/stock/demandrequest/actions';
import moment from 'moment';
import { debounce } from 'lodash';

const AddDemandRequestModal = (props) => {
    const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
    const allProducts = productData ? productData.toJS() : [];
    const [rows, setRows] = useState([{ id: 1 }]);
    const [selectedDate, setSelectedDate] = useState(new Date())

    const addRow = () => {
        setRows([...rows, { id: rows.length + 1, productId: 0, requiredDate: new Date(), description: '', quantity: 0 }]);
    };

    const deleteRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleFieldChange = (id, field, value) => {
        if (field === "requiredDate") {
            setSelectedDate(value);
        }
        setRows(rows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
    };

    useEffect(() => {
        setLoading(false);
      }, [productData])

    const searchProducts = (text) => {
        setLoading(true);
        dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
    }
    const debouncedSearchProducts = debounce(searchProducts, 1000);

    useEffect(() => {
        if (isUpdate) {
            currentModalData.forEach((row) => {
                setRows((prevRows) => {
                    const rowExists = prevRows.some((prevRow) => prevRow.id === row.id);
                    if (!rowExists) {
                        return [row, ...prevRows];
                    }
                    return prevRows;
                });
            });
        }
    }, [currentModalData])

    const handleRecord = (actionName, demandrequestData) => {
        demandrequestData = rows.slice(0, rows.length - 1).map(detail => ({
            ...detail,
        }));
        debugger;

        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            const data = demandrequestData[0];
            dispatch(StockActions.addUpdateDemandRequests({ demandrequestData: data, actionName, UserParams: {} }));
        } else {
            demandrequestData.forEach(e => {
                e.id = 0;
                e.companyId = 1;
            });

            dispatch(StockActions.addUpdateDemandRequests({ demandrequestData, actionName, UserParams: {} }));
        }

        toggleModal();
    }

    return (
        <Modal
            open={isModalOpen}
            width={1100}
            zIndex={99999}
            onClose={() => toggleModal()}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal()}
            title={
                isUpdate ? (
                    <IntlMessages id="demandrequest.modal.title.edit" />
                ) : (
                    <IntlMessages id="demandrequest.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="modal-body-table">
                            <div className="table-responsive">
                                <table className="table  datanew">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Required Date</th>
                                            <th>Description</th>
                                            <th>quantity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows?.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Product"
                                                        onSearch={debouncedSearchProducts}
                                                        filterOption={false}
                                                        options={allProducts.map((f) => ({ label: f.productName, value: f.id }))}
                                                        notFoundContent={loading ? <Spin size="small" /> : null}
                                                        onChange={(e) => handleFieldChange(row.id, 'productId', e)}
                                                        value={row.productName}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={moment(selectedDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => handleFieldChange(row.id, 'requiredDate', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        value={row.description}
                                                        onChange={(e) => handleFieldChange(row.id, 'description', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        value={row.quantity}
                                                        onChange={(e) => handleFieldChange(row.id, 'quantity', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    {index === rows.length - 1 ? (
                                                        <button onClick={addRow} className="me-2 p-2 btn btn-success">
                                                            <PlusCircle className="me-2" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => deleteRow(row.id)}
                                                            className="me-2 p-2 btn btn-danger"
                                                        >
                                                            <Trash className="me-2" />
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default AddDemandRequestModal
