import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal, Form, Input, Upload, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import brandActions from '../../../core/Services/inventory/brands/actions';
import * as ApiResponseSelectors from '../../selectors';
import './brand.css';
import { PlusOutlined } from '@ant-design/icons';

const AddBrandModal = (props) => {

    const { currentModalData, isModalOpen, isUpdate, toggleModal, brandParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    const [modal] = Modal.useModal();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([])
    const [isError, setisError] = useState(false);
    const [state, setState] = useState({});

    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    useEffect(() => {
        if (fileList.length > 0) {
            const formFile = new FormData();
            var file = fileList[0];
            // Use a unique key for each file if the backend expects separate files
            formFile.append('image', file.originFileObj);
            dispatch(brandActions.uploadProfileImage({ brandImage: formFile }));
        }

    }, [fileList]);

    const getAllBrand =(brandParams) =>{
        dispatch(brandActions.fetchAllBrands({ brandParams: brandParams }));
     }

    const handleRecord = (actionName, brandData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            brandData.id = currentModalData.formData.id;
            brandData.companyId = currentModalData.formData.companyId;
        }
        else {
            brandData.companyId = 0
            brandData.isActive = brandData.isActive ? true : false;
        }
        dispatch(brandActions.addUpdateBrands({ brandData, actionName, brandParams}));
        toggleModal();
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="brand.inventory.form.modal.title.update" />
                ) : (
                    <IntlMessages id="brand.inventory.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="brand.inventory.form.modal.update" />
                ) : (
                    <IntlMessages id="brand.inventory.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="brand.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="brandForm"
                className='actions_addModalFormItem'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    brandName: currentModalData.formData.brandName,
                    isActive: currentModalData.formData.isActive,
                }}
            >
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="brandName"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="brand.inventory.form.brand.label.name"
                                        popoverkey="brand.inventory.form.label.popover.brand.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="brand.inventory.modal.validation.name" />,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="base64Image"
                                label={<IntlMessages id="user.management.form.label.uploadImage" />}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            return isError
                                                ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                : Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Upload
                                    listType="picture-circle"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{
                                            display: 'none',
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="addBrand_form--status_area">
                            <span className="status-label"> <IntlMessages id="category.inventory.modal.status" /></span>
                            <Form.Item
                                name="isActive"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Switch size="small" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>

    )
}

export default AddBrandModal
