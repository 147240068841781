import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as inventorySelectors from '../InventorySelectors';
import categoryActions from '../../../core/Services/inventory/categories/actions.js';
import subCategoryAction from "../../../core/Services/inventory/subCategory/actions.js";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Modal, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
//import './categories.css';
import '../inventory.css';
import TextArea from 'antd/es/input/TextArea';
import { useDebounce } from 'use-debounce';

import * as commonSelectors from '../../commonSelectors';
import commonAction from '../../../core/Services/common/actions';

const AddSubcategoryModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, subCategoryParams } = props;
    console.log('Parent category',currentModalData);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    const [modal] = Modal.useModal();

    const [debouncedText] = useDebounce(subCategoryParams, 1000);

    useEffect(() => {
        dispatch(commonAction.getAllCategory());
      }, []);
    
      const categoryDataSource = useSelector(state => commonSelectors.setAllCategory(state));
      const categories = categoryDataSource ? categoryDataSource.toJS() : [];

    const handleRecord = (actionName, subCategoryData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            subCategoryData.id = currentModalData.formData.id;
            subCategoryData.companyId = currentModalData.formData.companyId;
        }
        
        subCategoryData.isActive = subCategoryData.isActive ? true : false;

        dispatch(subCategoryAction.addUpdateSubcategories({ subCategoryData, actionName, subCategoryParams: subCategoryParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="subCategory.inventory.form.modal.title.update" />
                ) : (
                    <IntlMessages id="subCategory.inventory.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="subCategory.inventory.form.modal.update" />
                ) : (
                    <IntlMessages id="subCategory.inventory.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="subCategory.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="categoryForm"
                className='categoryActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    categoryId: currentModalData.formData ? currentModalData.formData.categoryId : undefined,
                    subCategoryName: currentModalData.formData.subCategoryName, 
                    subCategoryCode: currentModalData.formData.subCategoryCode,
                    subCategoryDescription: currentModalData.formData.subCategoryDescription,
                    isActive: currentModalData.formData.isActive,
                }}
            >

                <div className="col-lg-12">
                    <div className="input-blocks">
                        <Form.Item
                            name="categoryId"
                            className='actions_addModalFormItem'
                            label={
                                <CustomFormLabel
                                    labelkey="Sub.Category.inventory.form.modal.category"
                                    popoverkey="Sub.Category.inventory.form.label.popover.category"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: <IntlMessages id="Sub.Category.inventory.form.validation.category" />,
                                },
                            ]}
                        >
                            <Select
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                classNamePrefix="react-select"
                                options={categories.map(f => ({ label: f.name, value: f.id }))}
                                placeholder={CONSTANT_VARIABLES.CATEGORY_SELCT}
                                showSearch
                                filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                notFoundContent="No results found"
                            />
                        </Form.Item>
                    </div>
                    <div className="input-blocks">
                        <Form.Item
                            name="subCategoryName"
                            className='actions_addModalFormItem'
                            label={
                                <CustomFormLabel
                                    labelkey="sub.category.inventory.form.category.label.sub.category.name"
                                    popoverkey="sub.category.inventory.form.label.popover.name"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: <IntlMessages id="sub.category.inventory.modal.validation.name" />,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="input-blocks">
                        <Form.Item
                            name="subCategoryCode"
                            className='actions_addModalFormItem'
                            label={
                                <CustomFormLabel
                                    labelkey="sub.category.inventory.form.category.label.sub.category.code"
                                    popoverkey="sub.category.inventory.form.label.popover.code"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: <IntlMessages id="sub.category.inventory.modal.validation.code" />,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="input-blocks">
                        <Form.Item
                            name="subCategoryDescription"
                            className='actions_addModalFormItem'
                            label={
                                <CustomFormLabel
                                    labelkey="sub.category.inventory.form.category.label.sub.category.description"
                                    popoverkey="sub.category.inventory.form.label.popover.description"
                                />
                            }
                        >
                            <TextArea />
                        </Form.Item>
                    </div>
                </div>
                <div className="col-lg-12">
                    {/* <div className="input-blocks"> */}
                    <div className="addCategory_form--status_area">
                        <span className="status-label"> <IntlMessages id="sub.category.inventory.modal.status" /></span>
                        <Form.Item
                            name="isActive"
                            className='actions_addModalFormItem'
                            valuePropName="checked"
                        >
                            <Switch size="small" />
                        </Form.Item>
                        {/* </div> */}
                    </div>
                </div>
            </Form>

        </Modal >
    )
}

export default AddSubcategoryModal
