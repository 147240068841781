export const ActionTypes = {
    ADD_UPDATE_PRINTERSETTING: 'services/Settings/printersetting/add_update_printersetting',
    FETCH_PRINTERSETTING: 'services/Settings/printersetting/fetch_printersetting',
    SET_PRINTERSETTING: 'services/Settings/printersetting/set_printersetting',
    DELETE_PRINTERSETTING: 'services/Settings/printersetting/delete_printersetting',
    SET_LOADER: 'services/Settings/printersetting/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllPrinterSettings = ({ printerSettingParams }) => ({
    printerSettingParams,
    type: ActionTypes.FETCH_PRINTERSETTING,
})

const setPrinterSettings = ({ allPrinterSettingData }) => ({
    allPrinterSettingData,
    type: ActionTypes.SET_PRINTERSETTING,
})

const deletePrinterSetting = ({ printerSettingParam }) => ({
    printerSettingParam,
    type: ActionTypes.DELETE_PRINTERSETTING,
})

const addUpdatePrinterSetting = ({ printerSettingData, actionName, printerSettingParams }) => ({
    printerSettingData,
    actionName,
    printerSettingParams,
    type: ActionTypes.ADD_UPDATE_PRINTERSETTING,
})

export default {
    addUpdatePrinterSetting,
    fetchAllPrinterSettings,
    setPrinterSettings,
    deletePrinterSetting,
    setLoader
};
