import Immutable from 'immutable';
import  {ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setAdminDashboard = (state, { allAdminDashboardData }) => {
    if (!allAdminDashboardData) return state;
    return state.merge({ allAdminDashboardData });
}

const setAdminDashboardById = (state, { adminDashboardParams }) => {
    return state.merge({ adminDashboardParams });
}
const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_ADMINDASHBOARD,
        actionFunction: setAdminDashboard,
    },
    {
        actionType: ActionTypes.SET_ADMINDASHBOARD_BY_ID,
        actionFunction: setAdminDashboardById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];