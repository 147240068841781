import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../currency/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateCurrency({ currencyData, actionName, currencyParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-currency`,
        data: currencyData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-currency',
        data: currencyData,
      });
    }
    
    yield put(Actions.fetchAllCurrency({ currencyParams }));
    notification('success', actionName === 'update' ? "Currency Updated Successfully" : "Currency Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllCurrency({ currencyParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-currencies',
      params: currencyParams,
    });

    const allCurrencyData = data;
    yield put(Actions.setCurrency({ allCurrencyData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteCurrencyById({ currencyParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-currency/` + currencyParam.id,
    });
    yield put(Actions.fetchAllCurrency({ currencyParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Currency Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_CURRENCY, fetchAllCurrency),
    takeLatest(ActionTypes.ADD_UPDATE_CURRENCY, addUpdateCurrency),
    takeLatest(ActionTypes.DELETE_CURRENCY, deleteCurrencyById)
  ]);
}
