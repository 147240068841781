export const ActionTypes = {
  ADD_UPDATE_SALE_RECEIVE: 'services/purchases/saleReceive/add_update_saleReceive',
  FETCH_SALE_RECEIVE: 'services/purchases/saleReceive/fetch_saleReceives',
  SET_SALES_RECEIVE: 'services/purchases/saleReceive/set_saleReceives',
  DELETE_SALES_RECEIVE: 'services/purchases/saleReceive/delete_saleReceives',
  GET_SALE_RECEIVE_BY_ID: 'services/purchases/saleReceive/get_saleReceive_by_id',
  SET_SALE_RECEIVE_BY_ID: 'services/purchases/saleReceive/set_saleReceive_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/saleReceive/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/saleReceive/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllSaleReceives = ({ saleReceiveParams }) => ({
  saleReceiveParams,
  type: ActionTypes.FETCH_SALE_RECEIVE,
})

const setSaleReceives = ({ allSaleReceiveData }) => ({
  allSaleReceiveData,
  type: ActionTypes.SET_SALES_RECEIVE,
})

const deleteSaleReceiveById = ({ SaleReceiveParam }) => ({
  SaleReceiveParam,
  type: ActionTypes.DELETE_SALES_RECEIVE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateSaleReceive = ({ saleReceiveData, actionName, UserParams }) => ({
  saleReceiveData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_SALE_RECEIVE,
})

const getSaleReceiveById = ({ saleReceiveParam }) => ({
  saleReceiveParam,
  type: ActionTypes.GET_SALE_RECEIVE_BY_ID,
})

const setSaleReceiveById = ({ saleReceiveParam }) => ({
  saleReceiveParam,
  type: ActionTypes.SET_SALE_RECEIVE_BY_ID,
})

export default {
  addUpdateSaleReceive,
  fetchAllSaleReceives,
  setSaleReceives,
  deleteSaleReceiveById,
  getSaleReceiveById,
  setSaleReceiveById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
