export const ActionTypes = {
    ADD_UPDATE_PAYMENTGATEWAY: 'services/Settings/paymentgateways/add_update_paymentgateway',
    FETCH_PAYMENTGATEWAY: 'services/Settings/paymentgateways/fetch_paymentgateways',
    SET_PAYMENTGATEWAYS: 'services/Settings/paymentgateways/set_paymentgateways',
    SET_LOADER: 'services/Settings/paymentgateways/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllPaymentGateways = ({ paymentGatewayParams }) => ({
    paymentGatewayParams,
    type: ActionTypes.FETCH_PAYMENTGATEWAY,
})

const setPaymentGateways = ({ allPaymentGatewayData }) => ({
    allPaymentGatewayData,
    type: ActionTypes.SET_PAYMENTGATEWAYS,
})

const addUpdatePaymentGateways = ({ paymentGatewayData, actionName, paymentGatewayParams }) => ({
    paymentGatewayData,
    actionName,
    paymentGatewayParams,
    type: ActionTypes.ADD_UPDATE_PAYMENTGATEWAY,
})

export default {
    addUpdatePaymentGateways,
    fetchAllPaymentGateways,
    setPaymentGateways,
    setLoader
};
