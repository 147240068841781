import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../reportsetting/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addReportSetting({ reportSettingData, actionName, reportSettingParams }) {
  try {
    if (reportSettingData.reportImage) {
      const file = reportSettingData.reportImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete reportSettingData.reportImage;
      if (imageUrl) {
        reportSettingData.logoUrl = imageUrl.url;
      }
    }

    if (actionName === 'Add') {
      yield call(api.post, {
        route: `/Settings/create-reportsetting`,
        data: reportSettingData,
      });
    }
   
    yield put(Actions.fetchAllReportSettings({ reportSettingParams }));
    notification('success', CONSTANT_VARIABLES.REPORTSETTING_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllReportSettings({ reportsettingParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch Report settings
    const { data } = yield call(api.get, {
      route: '/Settings/all-reportsettings',
      params: reportsettingParams,
    });

    // Store the fetched data in Redux store
    const allReportSettingsData = data;
    yield put(Actions.setReportSettings({ allReportSettingsData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_REPORTSETTING, fetchAllReportSettings),
    takeLatest(ActionTypes.ADD_REPORTSETTING, addReportSetting)
  ]);
}
