export const setUserProfiles = state => {
    return state.services.userprofile.getIn(['allUserProfileData']);
};

export const setLoader = state => {
    return state.services.userprofile.getIn(['showLoader']);
};

export const setConnectedAppLoader = state => {
    return state.services.connectedapp.getIn(['showLoader']);
};

export const setLocalizationLoader = state => {
    return state.services.localization.getIn(['showLoader']);
};

export const setOtpLoader = state => {
    return state.services.otpsetting.getIn(['showLoader']);
};

export const setBanIpLoader = state => {
    return state.services.banip.getIn(['showLoader']);
};

export const setCompanySettingsLoader = state => {
    return state.services.companysetting.getIn(['showLoader']);
};

export const setPrinterLoader = state => {
    return state.services.printersetting.getIn(['showLoader']);
};

export const setSMSGatewayLoader = state => {
    return state.services.smsgateway.getIn(['showLoader']);
};

export const setCurrencyLoader = state => {
    return state.services.currency.getIn(['showLoader']);
};

export const setBankDataLoader = state => {
    return state.services.bank.getIn(['showLoader']);
};

export const setPaymentGatewayLoader = state => {
    return state.services.paymentgateway.getIn(['showLoader']);
};

export const setTaxLoader = state => {
    return state.services.taxrates.getIn(['showLoader']);
};

export const setReportLoader = state => {
    return state.services.reportsetting.getIn(['showLoader']);
};

export const setEmailSettingLoader = state => {
    return state.services.emailsetting.getIn(['showLoader']);
};

export const setCustomFieldsLoader = state => {
    return state.services.customfield.getIn(['showLoader']);
};

export const setNotifications = state => {
    return state.services.notifications.getIn(['allnotificationsData']);
};

export const setBank = state => {
    return state.services.bank.getIn(['allBankData']);
};

export const setConnectedApps = state => {
    return state.services.connectedapp.getIn(['allConnectedAppData']);
};

export const setUploadAppLogoImage = state => {
    return state.services.connectedapp.getIn(['uploadImageUrl']);
};

export const setLogoImage = state => {
    return state.services.companysetting.getIn(['uploadLogoImageUrl']);
};

export const setIconImage = state => {
    return state.services.companysetting.getIn(['uploadIconImageUrl']);
};

export const setFavIconImage = state => {
    return state.services.companysetting.getIn(['uploadFavImageUrl']);
};

export const setDarkLogoImage = state => {
    return state.services.companysetting.getIn(['uploadDarkImageUrl']);
};

export const setstatus2fa = state => {
    return state.services.passowrd.getIn(['allfaData']);
};
export const setCompanySettings = state => {
    return state.services.companysetting.getIn(['allCompanySettingData']);
};

export const setPaymentGateways = state => {
    return state.services.paymentgateway.getIn(['allPaymentGatewayData']);
};

export const setCurrency = state => {
    return state.services.currency.getIn(['allCurrencyData']);
};

export const setTaxrate = state => {
    return state.services.taxrates.getIn(['allTaxrateData']);
};

export const setBanip = state => {
    return state.services.banip.getIn(['allBanipData']);
};

export const setEmailsetting = state => {
    return state.services.emailsetting.getIn(['allEmailsettingData']);
};

export const setLocalization = state => {
    return state.services.localization.getIn(['allLocalizationData']);
};

export const setOtpSettings = state => {
    return state.services.otpsetting.getIn(['allOtpSettingsData']);
};
export const setSmsGateway = state => {
    return state.services.smsgateway.getIn(['allSmsGatewayData']);
};

export const setReportSettings = state => {
    return state.services.reportsetting.getIn(['allReportSettingsData']);
};

export const setPrinterSettings = state => {
    return state.services.printersetting.getIn(['allPrinterSettingData']);
};

export const setCustomField = state => {
    return state.services.customfield.getIn(['allCustomFieldData']);
};