import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setUploadProfileImage = (state, { uploadImageUrl }) => {
    if (!uploadImageUrl) return state;
    return state.merge({ uploadImageUrl });
};

const setAllStores = (state, { allStoresData }) => {
    if (!allStoresData) return state;
    return state.merge({ allStoresData });
};

const setAllWarehouses = (state, { allWarehousesData }) => {
    if (!allWarehousesData) return state;
    return state.merge({ allWarehousesData });
};

const setAllCategory = (state, { allCategoryData }) => {
    if (!allCategoryData) return state;
    return state.merge({ allCategoryData });
};

const setAllSubCategory = (state, { allSubCategoryData }) => {
    if (!allSubCategoryData) return state;
    return state.merge({ allSubCategoryData });
};

const setAllBrands = (state, { allBrandsData }) => {
    if (!allBrandsData) return state;
    return state.merge({ allBrandsData });
};

const setAllUnits = (state, { allUnitsData }) => {
    if (!allUnitsData) return state;
    return state.merge({ allUnitsData });
};

const setAllUsers = (state, { allUsersData }) => {
    if (!allUsersData) return state;
    return state.merge({ allUsersData });
};

const setAllVariants = (state, { allVariantsData }) => {
    if (!allVariantsData) return state;
    return state.merge({ allVariantsData });
};

const setAllSellingTypes = (state, { allSellingTypesData }) => {
    if (!allSellingTypesData) return state;
    return state.merge({ allSellingTypesData });
};

const setAllbarcodesymbologies = (state, { allBarCodeSymbData }) => {
    if (!allBarCodeSymbData) return state;
    return state.merge({ allBarCodeSymbData });
};

const setAllDisscountTypes = (state, { allDisscountTypesData }) => {
    if (!allDisscountTypesData) return state;
    return state.merge({ allDisscountTypesData });
};

const setAllTaxTypes = (state, { allTaxTypesData }) => {
    if (!allTaxTypesData) return state;
    return state.merge({ allTaxTypesData });
};
const setAllPOSProductTypes = (state, { allPOSProductTypesData }) => {
    if (!allPOSProductTypesData) return state;
    return state.merge({ allPOSProductTypesData });
};

const setAllExpenseCategory = (state, { allExpenseCategoryData }) => {
    if (!allExpenseCategoryData) return state;
    return state.merge({ allExpenseCategoryData });
};

const setAllCities = (state, { allCitiesData }) => {
    if (!allCitiesData) return state;
    return state.merge({ allCitiesData });
};

const setAllStates = (state, { allStatesData }) => {
    if (!allStatesData) return state;
    return state.merge({ allStatesData });
};

const setAllCountries = (state, { allCountriesData }) => {
    if (!allCountriesData) return state;
    return state.merge({ allCountriesData });
};

const setAllCustomer = (state, { allCustomerData }) => {
    if (!allCustomerData) return state;
    return state.merge({ allCustomerData });
};

const setAllSuppliers = (state, { allSupplierData }) => {
    if (!allSupplierData) return state;
    return state.merge({ allSupplierData });
};

const setAllProductsBySearch = (state, { allProductsDataBySearch }) => {
    if (!allProductsDataBySearch) return state;
    return state.merge({ allProductsDataBySearch });
};

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};
const setAllQuotation = (state, { allQuotationData }) => {
    if (!allQuotationData) return state;
    return state.merge({ allQuotationData });
};
const setAllEmployee = (state, { allEmployeeData }) => {
    if (!allEmployeeData) return state;
    return state.merge({ allEmployeeData });
};

const setAllLeaveType = (state, { allLeaveTypeData }) => {
    if (!allLeaveTypeData) return state;
    return state.merge({ allLeaveTypeData });
};


const setAllDesignation = (state, { allDesignationData }) => {
    if (!allDesignationData) return state;
    return state.merge({ allDesignationData });
};

const setAllDepartment = (state, { allDepartmentData }) => {
    if (!allDepartmentData) return state;
    return state.merge({ allDepartmentData });
};

const setAllShift = (state, { allShiftData }) => {
    if (!allShiftData) return state;
    return state.merge({ allShiftData });
};

const setAllGender = (state, { allGenderData }) => {
    if (!allGenderData) return state;
    return state.merge({ allGenderData });
};

const setAllWeekoffs = (state, { allWeekoffData }) => {
    if (!allWeekoffData) return state;
    return state.merge({ allWeekoffData });
};

const setAllNationalities = (state, { allNationalityData }) => {
    if (!allNationalityData) return state;
    return state.merge({ allNationalityData });
};

const setAllBloodGroup = (state, { allBloodGroupData }) => {
    if (!allBloodGroupData) return state;
    return state.merge({ allBloodGroupData });
};

const setAllGrnById = (state, { allGrnData }) => {
    if (!allGrnData) return state;
    return state.merge({ allGrnData });
};

const setAllLeaveTypes = (state, { AllLeaveTypesData }) => {
    if (!AllLeaveTypesData) return state;
    return state.merge({ AllLeaveTypesData });
};

const setAllSaleQuotation = (state, { allSaleQuotationData }) => {
    if (!allSaleQuotationData) return state;
    return state.merge({ allSaleQuotationData });
};

const setAllSaleOrder = (state, { allSaleOrderData }) => {
    if (!allSaleOrderData) return state;
    return state.merge({ allSaleOrderData });
};

const setAllInvoicesData = (state, { AllInvoicesData }) => {
    if (!AllInvoicesData) return state;
    return state.merge({ AllInvoicesData });
};

const setProducts = (state, { allProductData }) => {
    if (!allProductData) return state;
    return state.merge({ allProductData });
}
const setShipments = (state, { allShipmentData }) => {
    if (!allShipmentData) return state;
    return state.merge({ allShipmentData });
}

const setSaleInvoices = (state, { allSaleInvoiceData }) => {
    if (!allSaleInvoiceData) return state;
    return state.merge({ allSaleInvoiceData });
}

const setAllBanks = (state, { allBanksData }) => {
    if (!allBanksData) return state;
    return state.merge({ allBanksData });
}
const setAllCurrency = (state, { allCurrencyData }) => {
    if (!allCurrencyData) return state;
    return state.merge({ allCurrencyData });
}
const setTaxrate = (state, { allTaxrateData }) => {
    if (!allTaxrateData) return state;
    return state.merge({ allTaxrateData });
}

const setLanguage = (state, { allLanguageData }) => {
    if (!allLanguageData) return state;
    return state.merge({ allLanguageData });
}

const setOpttype = (state, { allOpttypeData }) => {
    if (!allOpttypeData) return state;
    return state.merge({ allOpttypeData });
}

const setDateformate = (state, { allDateformateData }) => {
    if (!allDateformateData) return state;
    return state.merge({ allDateformateData });
}

const setTimezone = (state, { allTimezoneData }) => {
    if (!allTimezoneData) return state;
    return state.merge({ allTimezoneData });
}

const setTimeformate = (state, { allTimeformateData }) => {
    if (!allTimeformateData) return state;
    return state.merge({ allTimeformateData });
}

const setAllconnectiontype = (state, { allconnectiontypeData }) => {
    if (!allconnectiontypeData) return state;
    return state.merge({ allconnectiontypeData });
}

const setAllcustominputtype = (state, { allcustominputtypeData }) => {
    if (!allcustominputtypeData) return state;
    return state.merge({ allcustominputtypeData });
}

const setAllExpensetype = (state, { allExpensetypeData }) => {
    if (!allExpensetypeData) return state;
    return state.merge({ allExpensetypeData });
}

const setAllcustomfieldfor = (state, { allcustomfieldforData }) => {
    if (!allcustomfieldforData) return state;
    return state.merge({ allcustomfieldforData });
}

const setReportLogoImage = (state, { uploadReportLogoImageUrl }) => {
    if (!uploadReportLogoImageUrl) return state;
    return state.merge({ uploadReportLogoImageUrl });
};

const setAllFunctionTypes = (state, { allFuncTypeData }) => {
    if (!allFuncTypeData) return state;
    return state.merge({ allFuncTypeData });
};

const setAllPurchaseReceiveDrp = (state, { allPurchaseReceiveData }) => {
    if (!allPurchaseReceiveData) return state;
    return state.merge({ allPurchaseReceiveData });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_ALL_PURCHASE_RECEIVE_DRP,
        actionFunction: setAllPurchaseReceiveDrp,
    },
    {
        actionType: ActionTypes.SET_ALL_POS_PRODUCT_TYPES,
        actionFunction: setAllPOSProductTypes,
    },
    {
        actionType: ActionTypes.SET_CUSTOMFIELD_FOR_DRP,
        actionFunction: setAllcustomfieldfor,
    },
    {
        actionType: ActionTypes.SET_EXPENSETYPES_DRP,
        actionFunction: setAllExpensetype,
    },
    {
        actionType: ActionTypes.SET_CONNECTION_TYPE_DRP,
        actionFunction: setAllconnectiontype,
    },

    // For opttype
    {
        actionType: ActionTypes.SET_CUSTOMINPUTTYPE_DRP,
        actionFunction: setAllcustominputtype,
    },
    // For language
    {
        actionType: ActionTypes.SET_LANGUAGE_DRP,
        actionFunction: setLanguage,
    },

    // For opttype
    {
        actionType: ActionTypes.SET_OPTTYPE_DRP,
        actionFunction: setOpttype,
    },

    // For dateformate
    {
        actionType: ActionTypes.SET_DATEFORMATE_DRP,
        actionFunction: setDateformate,
    },

    // For timezone
    {
        actionType: ActionTypes.SET_TIMEZONE_DRP,
        actionFunction: setTimezone,
    },

    // For timeformate
    {
        actionType: ActionTypes.SET_TIMEFORMATE_DRP,
        actionFunction: setTimeformate,
    },

    {
        actionType: ActionTypes.SET_BANK_DRP,
        actionFunction: setAllBanks,
    },
    {
        actionType: ActionTypes.SET_CURRENCY_DRP,
        actionFunction: setAllCurrency,
    },
    {
        actionType: ActionTypes.SET_TAXRATE_DRP,
        actionFunction: setTaxrate,
    },
    {
        actionType: ActionTypes.SET_SHIPMENTS_DRP,
        actionFunction: setShipments,
    },
    {
        actionType: ActionTypes.SET_SALE_INVOICE_DRP,
        actionFunction: setSaleInvoices,
    },
    {
        actionType: ActionTypes.SET_ALL_DESIGNATION_DRP,
        actionFunction: setAllDesignation,
    },
    {
        actionType: ActionTypes.SET_ALL_DEPARTMENT_DRP,
        actionFunction: setAllDepartment,
    },
    {
        actionType: ActionTypes.SET_ALL_SHIFTS_DRP,
        actionFunction: setAllShift,
    },
    {
        actionType: ActionTypes.SET_ALL_GENDER_DRP,
        actionFunction: setAllGender,
    },
    {
        actionType: ActionTypes.SET_ALL_WEEKOFFS_DRP,
        actionFunction: setAllWeekoffs,
    },
    {
        actionType: ActionTypes.SET_ALL_NATIONALITIES_DRP,
        actionFunction: setAllNationalities,
    },
    {
        actionType: ActionTypes.SET_ALL_BLOOD_GROUP_DRP,
        actionFunction: setAllBloodGroup,
    },

    {
        actionType: ActionTypes.SET_ALL_EMPLOYEE_DRP,
        actionFunction: setAllEmployee,
    },
    {
        actionType: ActionTypes.SET_ALL_QUOTATION,
        actionFunction: setAllQuotation,
    },
    {
        actionType: ActionTypes.SET_ALL_VARIANTS,
        actionFunction: setAllVariants,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_ALL_STORES,
        actionFunction: setAllStores,
    },
    {
        actionType: ActionTypes.SET_ALL_WAREHOUSES,
        actionFunction: setAllWarehouses,
    },
    {
        actionType: ActionTypes.SET_ALL_CATEGORY,
        actionFunction: setAllCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_SUBCATEGORY,
        actionFunction: setAllSubCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_BRANDS,
        actionFunction: setAllBrands,
    },
    {
        actionType: ActionTypes.SET_ALL_UNITS,
        actionFunction: setAllUnits,
    },
    {
        actionType: ActionTypes.SET_ALL_USERS,
        actionFunction: setAllUsers,
    },
    {
        actionType: ActionTypes.SET_ALL_EXPENSE_CATEGORY,
        actionFunction: setAllExpenseCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_SELLING_TYPES,
        actionFunction: setAllSellingTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_BAR_CODE_SYMBOLOGIES,
        actionFunction: setAllbarcodesymbologies,
    },
    {
        actionType: ActionTypes.SET_ALL_DISSCOUNT_TYPES,
        actionFunction: setAllDisscountTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_TAX_TYPES,
        actionFunction: setAllTaxTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_CITY,
        actionFunction: setAllCities,
    },
    {
        actionType: ActionTypes.SET_ALL_STATE,
        actionFunction: setAllStates,
    },
    {
        actionType: ActionTypes.SET_ALL_COUNTRY,
        actionFunction: setAllCountries,
    },
    {
        actionType: ActionTypes.SET_ALL_CUSTOMER,
        actionFunction: setAllCustomer,
    },
    {
        actionType: ActionTypes.SET_ALL_SUPPLIER,
        actionFunction: setAllSuppliers,
    },
    {
        actionType: ActionTypes.SET_ALL_PRODUCTS_BY_SEARCH,
        actionFunction: setAllProductsBySearch,
    },
    {
        actionType: ActionTypes.SET_ALL_GRN_BY_ID,
        actionFunction: setAllGrnById,
    },
    {
        actionType: ActionTypes.SET_ALL_LEAVE_TYPE_DRP,
        actionFunction: setAllLeaveTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_SALE_QUOTATION,
        actionFunction: setAllSaleQuotation,
    },
    {
        actionType: ActionTypes.SET_ALL_SALE_ORDER_DRP,
        actionFunction: setAllSaleOrder,
    },
    {
        actionType: ActionTypes.SET_ALL_PURCHASE_INVOICES,
        actionFunction: setAllInvoicesData,
    },
    {
        actionType: ActionTypes.SET_PRODUCTS_DRP,
        actionFunction: setProducts,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_LOGO_IMAGE,
        actionFunction: setReportLogoImage,
    },
    {
        actionType: ActionTypes.SET_ALL_FUNCTYPES_DRP,
        actionFunction: setAllFunctionTypes,
    }
];
