export const ActionTypes = {
    ADD_COMPANYSETTING: 'services/Settings/companysetting/add_update_companysetting',
    FETCH_COMPANYSETTING: 'services/Settings/companysetting/fetch_companysetting',
    SET_COMPANYSETTING: 'services/Settings/companysetting/set_companysetting',
    SET_LOADER: 'services/Settings/companysetting/set_loader',

    UPLOAD_LOGO_IMAGE: 'services/Settings/companysetting/upload_logo_image',
    SET_UPLOAD_LOGO_IMAGE: 'services/Settings/companysetting/set_upload_logo_image',

    
    UPLOAD_ICON_IMAGE: 'services/Settings/companysetting/upload_icon_image',
    SET_UPLOAD_ICON_IMAGE: 'services/Settings/companysetting/set_upload_icon_image',

    
    UPLOAD_FAV_IMAGE: 'services/Settings/companysetting/upload_fav_image',
    SET_UPLOAD_FAV_IMAGE: 'services/Settings/companysetting/set_upload_fav_image',

    
    UPLOAD_DARK_IMAGE: 'services/Settings/companysetting/upload_dark_image',
    SET_UPLOAD_DARK_IMAGE: 'services/Settings/companysetting/set_upload_dark_image',
}


const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllCompanySettings = ({ companySettingParams }) => ({
    companySettingParams,
    type: ActionTypes.FETCH_COMPANYSETTING,
})

const setCompanySettings = ({ allCompanySettingData }) => ({
    allCompanySettingData,
    type: ActionTypes.SET_COMPANYSETTING,
})

const addCompanySetting = ({ companySettingData, actionName, companySettingParams }) => ({
    companySettingData,
    actionName,
    companySettingParams,
    type: ActionTypes.ADD_COMPANYSETTING,
})

const uploadLogoImage = ({ logoImage }) => ({
    logoImage,
    type: ActionTypes.UPLOAD_LOGO_IMAGE
});

const setLogoImage = ({ uploadLogoImageUrl }) => ({
    uploadLogoImageUrl,
    type: ActionTypes.SET_UPLOAD_LOGO_IMAGE
});

const uploadIconImage = ({ iconImage }) => ({
    iconImage,
    type: ActionTypes.UPLOAD_ICON_IMAGE
});

const setIconImage = ({ uploadIconImageUrl }) => ({
    uploadIconImageUrl,
    type: ActionTypes.SET_UPLOAD_ICON_IMAGE
});

const uploadFavIconImage = ({ favImage }) => ({
    favImage,
    type: ActionTypes.UPLOAD_FAV_IMAGE
});

const setFavIconImage = ({ uploadFavImageUrl }) => ({
    uploadFavImageUrl,
    type: ActionTypes.SET_UPLOAD_FAV_IMAGE
});

const uploadDarkLogoImage = ({ darkImage }) => ({
    darkImage,
    type: ActionTypes.UPLOAD_DARK_IMAGE
});

const setDarkLogoImage = ({ uploadDarkImageUrl }) => ({
    uploadDarkImageUrl,
    type: ActionTypes.SET_UPLOAD_DARK_IMAGE
});

export default {
    fetchAllCompanySettings,
    addCompanySetting,
    setCompanySettings,
    setLoader,
    uploadLogoImage,
    setLogoImage,
    uploadIconImage,
    setIconImage,
    uploadFavIconImage,
    setFavIconImage,
    uploadDarkLogoImage,
    setDarkLogoImage,
};
