import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Spin } from "antd";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import StockTransferActions from '../../../core/Services/stock/stocktransfer/actions';
import { debounce } from 'lodash';

const StockTransferModal = (props) => {
  const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const allProducts = productData ? productData.toJS() : [];
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
    dispatch(commonAction.getAllWarehouses());
    dispatch(commonAction.getAllEmployee());
  }, [])

  useEffect(() => {
    setLoading(false);
  }, [productData])

  const searchProducts = (text) => {
    setLoading(true);
    dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
  }
  const debouncedSearchProducts = debounce(searchProducts, 1000);

  const handleRecord = (actionName, stocktransferData) => {
    debugger;
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      stocktransferData.id = currentModalData.id;
      stocktransferData.companyId = currentModalData.companyId;
      stocktransferData.quantity = currentModalData.quantity;
      if (typeof stocktransferData.productId === 'string') {
        if (allProducts.length > 0) {
          const productId = allProducts.filter(p => p.productName === stocktransferData.productId);
          if (productId.length === 0) {
            stocktransferData.productId = currentModalData.productId;
          } else {
            stocktransferData.productId = productId[0].id;
          }
        } else {
          stocktransferData.productId = currentModalData.productId;
        }
      }
    } else {
      stocktransferData.id = 0;
      stocktransferData.companyId = 1;
      stocktransferData.quantity = 0;
    }

    dispatch(StockTransferActions.addUpdateStockTransfers({ stocktransferData, actionName, UserParams: {} }));

    toggleModal();
  }

  return (
    <Modal
      open={isModalOpen}
      width={800}
      zIndex={99999}
      onClose={() => toggleModal()}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal()}
      title={
        isUpdate ? (
          <IntlMessages id="stocktransfer.modal.title.edit" />
        ) : (
          <IntlMessages id="stocktransfer.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          departmentFromId: currentModalData ? currentModalData.departmentFromId : null,
          departmentToId: currentModalData ? currentModalData.departmentToId : null,
          employeeId: currentModalData ? currentModalData.employeeId : null,
          productId: currentModalData ? currentModalData.product : null,
        }}
      >
        <div className="modal-body custom-modal-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="departmentFromId"
                  className="actions_addModalFormItem"
                  label={
                    <IntlMessages id="stocktransfer.table.column.fromshop" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="departmentToId"
                  className="actions_addModalFormItem"
                  label={
                    <IntlMessages id="stocktransfer.table.column.toshop" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="employeeId"
                  label={
                    <IntlMessages id="stocktransfer.table.column.employee" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="productId"
                  className="actions_addModalFormItem"
                  label={
                    <IntlMessages id="stock.table.column.product" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Product"
                    onSearch={debouncedSearchProducts}
                    filterOption={false}
                    options={allProducts.map((f) => ({ label: f.productName, value: f.id }))}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default StockTransferModal;

