import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../shared/components/Notification";

  function* fetchAllAdminDashboard({ adminDashboardParams }) {
    try {
      yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
        route: '/Dashboard/get-dashboard',
        params: adminDashboardParams,
      });
  
      const allAdminDashboardData = data;
      yield put(Actions.setAdminDashboard({ allAdminDashboardData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      handleNotification(error);  
      console.log(error);
    } finally {
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }


  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0) {
      message.details.response.data.errors.map((value) => (
        notification('error', value)
      ));
    } else {
      notification('error', 'SERVE-POS API Issue.');
    }
  }


  export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_ADMINDASHBOARD, fetchAllAdminDashboard)

    ]);
  }