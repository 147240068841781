export const ActionTypes = {
    ADD_UPDATE_MANAGESTOCK: 'services/stock/managestocks/add_update_managestock',
    FETCH_MANAGESTOCK: 'services/stock/managestocks/fetch_managestocks',
    SET_MANAGESTOCKS: 'services/stock/managestocks/set_managestocks',
    DELETE_MANAGESTOCKS: 'services/stock/managestocks/delete_managestocks',
    SET_LOADER: 'services/stock/managestock/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllManageStocks = ({ managestockParams }) => ({
    managestockParams,
    type: ActionTypes.FETCH_MANAGESTOCK,
})

const setManageStocks = ({ allManageStockData }) => ({
    allManageStockData,
    type: ActionTypes.SET_MANAGESTOCKS,
})

const deleteManageStockById = ({ managestockParam }) => ({
    managestockParam,
    type: ActionTypes.DELETE_MANAGESTOCKS,
})

const addUpdateManageStocks = ({ managestockData, actionName, UserParams }) => ({
    managestockData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_MANAGESTOCK,
})

export default {
    addUpdateManageStocks,
    fetchAllManageStocks,
    setManageStocks,
    deleteManageStockById,
    setLoader
};
