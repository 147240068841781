import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, TimePicker } from 'antd';
import CustomFormLabel from '../../../common/CustomFormLabel';
import TextEditor from "../../../inventory/texteditor";
import dayjs from 'dayjs';

const BreakTimingsTab = (props) => {
    const { form, currentModalData, isUpdate, morningBreakFromTime, setMorningBreakFromTime, morningBreakToTime, setMorningBreakToTime, lunchBreakFromTime, setLunchBreakFromTime, lunchBreakToTime, setLunchBreakToTime, eveningBreakFromTime, setEveningBreakFromTime, eveningBreakToTime, setEveningBreakToTime, editorData, setEditorData } = props;
    const dispatch = useDispatch();

    const morningBreakFromChange = (time, timeString) => {
        setMorningBreakFromTime(timeString)
        form.setFieldValue({ morningBreakFrom: dayjs(time) })
        form.validateFields(["morningBreakTo"]);
    }

    const morningBreakToChange = (time, timeString) => {
        setMorningBreakToTime(timeString)
        form.setFieldValue({ morningBreakTo: dayjs(time) })
    }

    const lunchBreakFromChange = (time, timeString) => {
        setLunchBreakFromTime(timeString)
        form.setFieldValue({ lunchBreakFrom: dayjs(time) })
        form.validateFields(["lunchBreakTo"]);
    }

    const lunchBreakToChange = (time, timeString) => {
        setLunchBreakToTime(timeString)
        form.setFieldValue({ lunchBreakTo: dayjs(time) })
    }

    const eveningBreakFromChange = (time, timeString) => {
        setEveningBreakFromTime(timeString)
        form.setFieldValue({ eveningBreakFrom: dayjs(time) })
        form.validateFields(["eveningBreakTo"]);
    }

    const eveningBreakToChange = (time, timeString) => {
        setEveningBreakToTime(timeString)
        form.setFieldValue({ eveningBreakTo: dayjs(time) })
    }

    const validateMorningBreakToTime = (_, value) => {
        if (!value || !morningBreakFromTime) {
            return Promise.resolve();
        }

        const formattedToTime = dayjs(value).format("HH:mm:ss");
        if (formattedToTime <= morningBreakFromTime) {
            return Promise.reject(new Error("To Time should be greater than From Time"));
        }
        return Promise.resolve();
    };

    const validateLunchBreakToTime = (_, value) => {
        if (!value || !lunchBreakFromTime) {
            return Promise.resolve();
        }

        const formattedToTime = dayjs(value).format("HH:mm:ss");
        if (formattedToTime <= lunchBreakFromTime) {
            return Promise.reject(new Error("To Time should be greater than From Time"));
        }
        return Promise.resolve();
    };

    const validateEveningBreakToTime = (_, value) => {
        if (!value || !eveningBreakFromTime) {
            return Promise.resolve();
        }

        const formattedToTime = dayjs(value).format("HH:mm:ss");
        if (formattedToTime <= eveningBreakFromTime) {
            return Promise.reject(new Error("To Time should be greater than From Time"));
        }
        return Promise.resolve();
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        let stringifyData = `${data}`
        setEditorData(stringifyData);
    };


    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            setMorningBreakFromTime(currentModalData.formData.morningBreakFrom)
            setMorningBreakToTime(currentModalData.formData.morningBreakTo)
            form.setFieldsValue({ morningBreakFrom: dayjs(currentModalData.formData.morningBreakFrom, 'HH:mm:ss') })
            form.setFieldsValue({ morningBreakTo: dayjs(currentModalData.formData.morningBreakTo, 'HH:mm:ss') })

            setLunchBreakFromTime(currentModalData.formData.lunchBreakFrom)
            setLunchBreakToTime(currentModalData.formData.lunchBreakTo)
            form.setFieldsValue({ lunchBreakFrom: dayjs(currentModalData.formData.lunchBreakFrom, 'HH:mm:ss') })
            form.setFieldsValue({ lunchBreakTo: dayjs(currentModalData.formData.lunchBreakTo, 'HH:mm:ss') })

            setEveningBreakFromTime(currentModalData.formData.eveningBreakFrom)
            setEveningBreakToTime(currentModalData.formData.eveningBreakTo)
            form.setFieldsValue({ eveningBreakFrom: dayjs(currentModalData.formData.eveningBreakFrom, 'HH:mm:ss') })
            form.setFieldsValue({ eveningBreakTo: dayjs(currentModalData.formData.eveningBreakTo, 'HH:mm:ss') })

            setEditorData(currentModalData.formData?.description?.replace(/""/g, ''))
        }
    }, [isUpdate])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    description: editorData
                }}
                style={{ marginTop: '20px' }}
            >

                <span style={{ fontWeight: '', fontSize: '20px' }}>Morning Break</span>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="morningBreakFrom"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="From"
                                        popoverkey="From"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Morning Break From Time Required",
                                    },
                                ]}
                            >
                                <TimePicker
                                    onChange={morningBreakFromChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="morningBreakTo"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="To"
                                        popoverkey="To"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Morning Break To Time Required",
                                    },
                                    { validator: validateMorningBreakToTime },
                                ]}
                            >
                                <TimePicker
                                    onChange={morningBreakToChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <span style={{ fontWeight: '', fontSize: '20px' }}>Lunch Break</span>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="lunchBreakFrom"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="From"
                                        popoverkey="From"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Lunch Break From Time Required",
                                    },
                                ]}
                            >
                                <TimePicker
                                    onChange={lunchBreakFromChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="lunchBreakTo"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="To"
                                        popoverkey="To"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Lunch Break To Time Required",
                                    },
                                    { validator: validateLunchBreakToTime },
                                ]}
                            >
                                <TimePicker
                                    onChange={lunchBreakToChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <span style={{ fontWeight: '', fontSize: '20px' }}>Evening Break</span>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="eveningBreakFrom"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="From"
                                        popoverkey="From"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Evening Break From Time Required",
                                    },
                                ]}
                            >
                                <TimePicker
                                    onChange={eveningBreakFromChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="eveningBreakTo"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="To"
                                        popoverkey="To"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Evening Break To Time Required",
                                    },
                                    { validator: validateEveningBreakToTime },
                                ]}
                            >
                                <TimePicker
                                    onChange={eveningBreakToChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="input-blocks summer-description-box">
                        <Form.Item
                            name="description"
                            label={
                                <CustomFormLabel
                                    labelkey="Description"
                                    popoverkey="Description"
                                />
                            }
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (editorData && editorData?.length > 500) {
                                            return Promise.reject(new Error("Description cannot exceed 500 characters."));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <TextEditor data={editorData} onChange={handleEditorChange} />
                        </Form.Item>

                    </div>
                </div>
            </Form>
        </>
    );
};

export default BreakTimingsTab;
