import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../passowrd/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

// New function for changing the password
function* changePassword({ passwordData, callback }) {
  try {
    yield call(api.put, {
      route: `/Settings/change-password`,
      data: passwordData,
    });
    notification('success', CONSTANT_VARIABLES.PASSWORD_CHANGED_SUCCESSFULLY);
    callback("success")
  } catch (error) {
    callback(error)
    // handleNotification(error);
    console.log(error);
  }
}


function* forgotPassword({ email }) {
  try {
    yield call(api.post, {
      route: `/Settings/forgot-password`,
      data: { email },
    });
    notification('success', CONSTANT_VARIABLES.FORGOT_PASSWORD_EMAIL_SENT);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

// New function for resetting the password
function* resetPassword({ resetToken, newPassword }) {
  try {
    yield call(api.post, {
      route: `/Settings/reset-password`,
      data: { resetToken, newPassword },
    });
    notification('success', CONSTANT_VARIABLES.PASSWORD_RESET_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}


function* enable2fa({ faData }) {
  try {
    yield call(api.post, {
      route: `/Settings/enable-2fa`,
      data: { faData },
    });
    notification('success', CONSTANT_VARIABLES.ENABLE_2FA_SUCCESSFULLY);
    yield put(Actions.getstatus2fa());
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

// New function for resetting the password
function* disable2fa({ faData }) {
  try {
    yield call(api.post, {
      route: `/Settings/disable-2fa`,
      data: { faData },
    });
    yield put(Actions.getstatus2fa());
    notification('success', CONSTANT_VARIABLES.DISABLE_2FA_EMAIL_SENT);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

// New function for resetting the password
function* getstatus2fa() {
  try {
    const { data } = yield call(api.get, {
      route: `/Settings/status-2fa`,
    });

    const allfaData = data;
    yield put(Actions.setstatus2fa({ allfaData }));

    // notification('success', CONSTANT_VARIABLES.STATUS_2FA_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}



function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(ActionTypes.FORGOT_PASSWORD, forgotPassword),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword),
    takeLatest(ActionTypes.ENABLE_2FA, enable2fa),
    takeLatest(ActionTypes.DISABLE_2FA, disable2fa),
    takeLatest(ActionTypes.GET_STATUS_2FA, getstatus2fa),
  ]);
}
