export const ActionTypes = {
    ADD_UPDATE_STOCKADJUSTMENT: 'services/stock/stockadjustments/add_update_stockadjustment',
    FETCH_STOCKADJUSTMENT: 'services/stock/stockadjustments/fetch_stockadjustments',
    SET_STOCKADJUSTMENTS: 'services/stock/stockadjustments/set_stockadjustments',
    DELETE_STOCKADJUSTMENTS: 'services/stock/stockadjustments/delete_stockadjustments',
    SET_LOADER: 'services/stock/stockadjustments/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllStockAdjustments = ({ stockadjustmentParams }) => ({
    stockadjustmentParams,
    type: ActionTypes.FETCH_STOCKADJUSTMENT,
})

const setStockAdjustments = ({ allStockAdjustmentData }) => ({
    allStockAdjustmentData,
    type: ActionTypes.SET_STOCKADJUSTMENTS,
})

const deleteStockAdjustmentById = ({ stockadjustmentParam }) => ({
    stockadjustmentParam,
    type: ActionTypes.DELETE_STOCKADJUSTMENTS,
})

const addUpdateStockAdjustments = ({ stockadjustmentData, actionName, UserParams }) => ({
    stockadjustmentData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_STOCKADJUSTMENT,
})

export default {
    addUpdateStockAdjustments,
    fetchAllStockAdjustments,
    setStockAdjustments,
    deleteStockAdjustmentById,
    setLoader
};
