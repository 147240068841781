import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setLocalization = (state, { allLocalizationData }) => {
    if (!allLocalizationData) return state;
    return state.merge({ allLocalizationData });
};

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_LOCALIZATION,
        actionFunction: setLocalization,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
