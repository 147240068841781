export const ActionTypes = {
    ADD_UPDATE_TAXRATE: 'services/Settings/taxrate/add_update_taxrate',
    FETCH_TAXRATE: 'services/Settings/taxrate/fetch_taxrate',
    SET_TAXRATE: 'services/Settings/taxrate/set_taxrate',
    DELETE_TAXRATE: 'services/Settings/taxrate/delete_taxrate',
    SET_LOADER: 'services/Settings/taxrate/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllTaxrate = ({ taxrateParams }) => ({
    taxrateParams,
    type: ActionTypes.FETCH_TAXRATE,
})

const setTaxrate = ({ allTaxrateData }) => ({
    allTaxrateData,
    type: ActionTypes.SET_TAXRATE,
})

const deleteTaxrate = ({ taxrateParam }) => ({
    taxrateParam,
    type: ActionTypes.DELETE_TAXRATE,
})

const addUpdateTaxrate = ({ taxrateData, actionName, taxrateParams }) => ({
    taxrateData,
    actionName,
    taxrateParams,
    type: ActionTypes.ADD_UPDATE_TAXRATE,
})

export default {
    addUpdateTaxrate,
    fetchAllTaxrate,
    setTaxrate,
    deleteTaxrate,
    setLoader
};
