import { RotateCcw } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom'
import { setToogleHeader } from '../../../core/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSideBar from '../settingssidebar';
import { Form, Spin, Select } from 'antd';
import commonActions from '../../../core/Services/common/actions'
import otpActions from '../../../core/Services/settings/otpsetting/actions'
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as commonSelectors from '../../commonSelectors'
import * as settingSelectors from '../settingsSelector'
import { useDebounce } from 'use-debounce';


const OtpSettings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [otpSettingParams, setOtpSettingParams] = useState({
        pageNo: 1,
        searchText: '',
    });

    const [debouncedText] = useDebounce(otpSettingParams, 1000);

    const data = useSelector((state) => state.toggle_header);
    const isOptionsDataLoading = useSelector(state => commonSelectors.setLoader(state));

    const duration = [
        { id: 5, name: '5 mins' },
        { id: 10, name: '10 mins' },
    ];
    const numbers = [
        { id: 4, name: '4 digits' },
        { id: 5, name: '5 digits' },
    ];
    const sms = [
        { value: 'SMS', label: 'SMS' },
        { value: 'EMail', label: 'EMail' },
    ];
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    //////  OTP Type Options
    const allOtpTypeData = useSelector(state => commonSelectors.setOpttype(state));
    const optData = allOtpTypeData ? allOtpTypeData.toJS() : [];

    const isOtpDataLoading = useSelector(state => settingSelectors.setOtpLoader(state));
    const allOtpData = useSelector(state => settingSelectors.setOtpSettings(state));
    const dataSource = allOtpData ? allOtpData.toJS() : [];
    const otpData = dataSource?.data

    const handleValidateFormData = () => {
        form.validateFields()
            .then(values => {
                handleRecord(values);
            })
            .catch(info => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    }

    const handleRecord = (otpSettingData) => {
        debugger
        otpSettingData.companyId = 1;
        dispatch(otpActions.addOtpSetting({ otpSettingData, actionName: "Add", otpSettingParams: otpSettingParams }));
    };

    useEffect(() => {
        dispatch(commonActions.fetchAllOpttype())
    }, [])

    useEffect(() => {
        dispatch(otpActions.fetchAllOtpSettings({ otpsettingParams: debouncedText }))
    }, [dispatch])

    useEffect(() => {
        if (otpData) {
            form.setFieldsValue({ otpTypeId: otpData?.otpTypeId })
            form.setFieldsValue({ otpDigitLimit: otpData?.otpDigitLimit })
            form.setFieldsValue({ otpExpiretimeLimit: otpData?.otpExpiretimeLimit })
        }
    }, [otpData])

    const handleRefresh = () => {
        dispatch(otpActions.fetchAllOtpSettings({ otpsettingParams: otpSettingParams }))
    }

    return (
        <div>
            {(isOptionsDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <form>
                                        <div className="setting-title">
                                            <h4>OTP Settings</h4>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            initialValues={{}}
                                            name="forForm" className='productActions_addModalWrapper'>
                                            <div className="company-info company-images">
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>OTP Type</h6>
                                                                <p>Your can configure the type</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="otpTypeId"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select an OTP Type",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={optData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select OTP Type"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    />
                                                                </Form.Item>
                                                                {/* <Select
                                                                    options={sms}
                                                                    classNamePrefix="react-select"
                                                                //placeholder="Choose a Duration"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>OTP Digit Limit</h6>
                                                                <p>Select size of the format </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="otpDigitLimit"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select OTP Digit Limit",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={numbers.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Digit Limit"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    />
                                                                </Form.Item>
                                                                {/* <Select
                                                                    options={numbers}
                                                                    classNamePrefix="react-select"
                                                                //placeholder="Choose a Duration"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>OTP Expire Time</h6>
                                                                <p>Select expire time of OTP </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="otpExpiretimeLimit"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select OTP Expire Time",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={duration.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Expire Duration"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>

                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                            >
                                                Cancel
                                            </button>
                                            <Link onClick={handleValidateFormData} to="#" className="btn btn-submit">
                                                Save Changes
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpSettings
