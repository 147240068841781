import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setEmailsetting = (state, { allEmailsettingData }) => {
    if (!allEmailsettingData) return state;
    return state.merge({ allEmailsettingData });
};

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_EMAILSETTING,
        actionFunction: setEmailsetting,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
