import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../smsgateway/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateSmsGateway({ smsgatewayData, actionName, smsgatewayParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-smsgateway`,
        data: smsgatewayData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-smsgateway',
        data: smsgatewayData,
      });
    }
    
    yield put(Actions.fetchAllSmsGateway({ smsgatewayParams }));
    notification('success', actionName === 'update' ? "Sms Gateway Updated Successfully" : "Sms Gateway Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllSmsGateway({ smsgatewayParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-smsgateways',
      params: smsgatewayParams,
    });

    const allSmsGatewayData = data;
    yield put(Actions.setSmsGateway({ allSmsGatewayData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteSmsGatewayById({ smsgatewayParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-smsgateway/` + smsgatewayParam.id,
    });
    yield put(Actions.fetchAllSmsGateway({ smsgatewayParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Sms Gateway Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SMSGATEWAY, fetchAllSmsGateway),
    takeLatest(ActionTypes.ADD_UPDATE_SMSGATEWAY, addUpdateSmsGateway),
    takeLatest(ActionTypes.DELETE_SMSGATEWAY, deleteSmsGatewayById)
  ]);
}
