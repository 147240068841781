export const ActionTypes = {
    ADD_CONNECTEDAPP: 'services/Settings/connectedapp/add_update_connectedapp',
    FETCH_CONNECTEDAPP: 'services/Settings/connectedapp/fetch_connectedapp',
    SET_CONNECTEDAPP: 'services/Settings/connectedapp/set_connectedapp',
    SET_LOADER: 'services/Settings/connectedapp/set_loader',
    UPLOAD_APP_IMAGE: 'services/Settings/connectedapp/upload_app_image',
    SET_UPLOAD_APP_IMAGE: 'services/Settings/connectedapp/set_upload_app_image',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllConnectedApps = ({ connectedAppParams }) => ({
    connectedAppParams,
    type: ActionTypes.FETCH_CONNECTEDAPP,
})

const setConnectedApps = ({ allConnectedAppData }) => ({
    allConnectedAppData,
    type: ActionTypes.SET_CONNECTEDAPP,
})

const addConnectedApp = ({ ConnectedAppData, actionName, ConnectedAppParams }) => ({
    ConnectedAppData,
    actionName,
    ConnectedAppParams,
    type: ActionTypes.ADD_CONNECTEDAPP,
})

const uploadAppLogoImage = ({ appImage }) => ({
    appImage,
    type: ActionTypes.UPLOAD_APP_IMAGE
});

const setUploadAppLogoImage = ({ uploadImageUrl }) => ({
    uploadImageUrl,
    type: ActionTypes.SET_UPLOAD_APP_IMAGE
});
export default {
    fetchAllConnectedApps,
    addConnectedApp,
    setConnectedApps,
    setLoader,
    uploadAppLogoImage,
    setUploadAppLogoImage,
};