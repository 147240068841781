export const ActionTypes = {
    SET_ADMINDASHBOARD: 'services/adminDashboard/set_adminDashboard',
    FETCH_ADMINDASHBOARD: 'services/adminDashboard/fetch_adminDashboard',
    SET_ADMINDASHBOARD_BY_ID: 'services/adminDashboard/set_adminDashboard_by_id',
    SET_LOADER: 'services/adminDashboard/set_loader',
}
 
const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});
const fetchAllAdminDashboard = ({ adminDashboardParams }) => ({
    adminDashboardParams,
    type: ActionTypes.FETCH_ADMINDASHBOARD,
});
const setAdminDashboard = ({ allAdminDashboardData }) => ({
    allAdminDashboardData,
    type: ActionTypes.SET_ADMINDASHBOARD,
})

const setAdminDashboardById = ({ adminDashboardParams }) => ({
    adminDashboardParams,
    type: ActionTypes.SET_ADMINDASHBOARD_BY_ID,
})

export default {
    fetchAllAdminDashboard,
    setAdminDashboard,
    setAdminDashboardById,
    setLoader
};