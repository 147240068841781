import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Image, Input, Select, Upload, Radio, Space, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as inventorySelectors from '../../InventorySelectors';
import productActions from '../../../../core/Services/inventory/products/actions';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import * as ApiResponseSelectors from '../../../selectors';
import '../products.css';
import '../../inventory.css';
import { Link } from "react-router-dom";
import {
    ArrowLeft,
    Calendar,
    ChevronDown,
    ChevronUp,
    Info,
    LifeBuoy,
    List,
    PlusCircle,
    Trash2,
    X,
} from "feather-icons-react/build/IconComponents";
import axios from 'axios';
import commonAction from '../../../../core/Services/common/actions';
import * as commonSelectors from '../../../commonSelectors';
import AddvariantModal from "../../variant/addvariant.js";
const ProductPricingAndStockTab = (props) => {
    const { form, onUplaodImage, currentModalData, isUpdate } = props;
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        dispatch(commonAction.getAllTaxTypes({}));
        dispatch(commonAction.getAllDisscountTypes({}));
    }, []);

    const taxTypesDataSource = useSelector(state => commonSelectors.setAllTaxTypes(state));
    const taxTypes = taxTypesDataSource ? taxTypesDataSource.toJS() : [];
    const disscountTypesDataSource = useSelector(state => commonSelectors.setAllDisscountTypes(state));
    const disscountTypes = disscountTypesDataSource ? disscountTypesDataSource.toJS() : [];

    const [isVariantModalOpen, setVariantModalOpen] = useState(false);
    const [currentVariantModalData, setCurrentVariantModalData] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([])

    const [isProductType, setIsProductType] = useState('Single Product');

    useEffect(() => {
        dispatch(commonAction.getAllVariants());
    }, [currentModalData]);

    const variantsDataSource = useSelector(state => commonSelectors.setAllVAraints(state));
    const variantsData = variantsDataSource ? variantsDataSource.toJS() : [];


    useEffect(() => {
        if (isUpdate) {
            const arrayOfObjects = JSON.parse(currentModalData.formData?.imagesUrls);
            console.log('arrayOfObjects', arrayOfObjects);
            debugger
            arrayOfObjects.forEach((obj, index) => {
                uploadFromURL(obj.Url, index);
            });
        }
    }, [currentModalData]);

    const uploadFromURL = async (url, index) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });

            // Extract the file extension from the URL
            const extension = url.split('.').pop().toLowerCase();
            let mimeType = 'image/png'; // default to png

            // Adjust MIME type based on the file extension
            if (extension === 'jpeg' || extension === 'jpg') {
                mimeType = 'image/jpeg';
            } else if (extension === 'png') {
                mimeType = 'image/png';
            }

            const file = new File([response.data], `downloaded-image ${index}.${extension}`, { type: mimeType });

            const newFile = {
                uid: `-${index}`,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
                originFileObj: file,
            };

            // Check if the file already exists in the fileList
            setFileList(prevFileList => {
                const existingFile = prevFileList.find(item => item.name === newFile.name);
                if (existingFile) {
                    return prevFileList; // If the file already exists, return the previous state
                }
                return [...prevFileList, newFile]; // Else, add the new file
            });

            // Generate preview image
            if (!file.url || !file.preview) {
                file.preview = await getBase64(file);
            }
            setPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    console.log('fileList', fileList)

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        onUplaodImage(newFileList);
    }

    // Set the initial value from currentModalData
    useEffect(() => {
        if (currentModalData.formData) {
            setIsProductType(currentModalData.formData.productType || 'Single Product');
        }
    }, [currentModalData]);

    const handleRadioChange = (e) => {
        setIsProductType(e.target.value);
    };

    const handleModal = (variantData, actionName) => {
        const variantModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? variantData : {},
        };
        setCurrentVariantModalData(variantModalObj);
        setVariantModalOpen(true);
    };


    const toggleVariantModal = () => {
        dispatch(commonAction.getAllVariants());
        setVariantModalOpen(false);
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                productType: currentModalData.formData ? currentModalData.formData.productType : 'Single Product',
                quantity: currentModalData.formData.quantity,
                price: currentModalData.formData.price,
                taxTypeId: currentModalData.formData.taxTypeId,
                disscountTypeId: currentModalData.formData.disscountTypeId,
                disscountValue: currentModalData.formData.disscountValue,
                quantityAlert: currentModalData.formData.quantityAlert,
                variantId: currentModalData.formData.variantId,
                imagesUrls: currentModalData.formData.imagesUrls,
            }}
            name="forForm" className='productActions_addModalWrapper'>
            <div className="input-blocks add-products">
                <Form.Item
                    name="productType"
                    className="actions_addModalFormItem"
                    label={
                        <CustomFormLabel
                            labelkey="product.type.inventory.form.label.name"
                            popoverkey="product.type.inventory.form.label.popover.name"
                        />
                    }
                    rules={[
                        {
                            required: true,
                            message: <IntlMessages id="product.type.inventory.modal.validation.name" />,
                        },
                    ]}
                >
                    <Radio.Group onChange={handleRadioChange} value={isProductType}  >
                        <Space direction='horizontal'>
                            <Radio value={"Single Product"}>
                                <IntlMessages id="single.product.inventory.form.label.name" />
                            </Radio>
                            <Radio value={"Variable Product"}>
                                <IntlMessages id="variable.product.inventory.form.label.name" />
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="quantity"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="quantity.inventory.form.label.name"
                                        popoverkey="quantity.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="quantity.inventory.modal.validation.name" />,
                                    },
                                    {
                                        type: 'number',
                                        min: 1,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={1} className="input_number" />
                            </Form.Item>
                        </div>
                    </div> <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="price"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="price.inventory.form.label.name"
                                        popoverkey="price.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="price.inventory.modal.validation.name" />,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The price must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="taxTypeId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="taxtype.inventory.form.label.name"
                                        popoverkey="taxtype.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="taxtype.inventory.modal.validation.name" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={taxTypes.map(t => ({ label: t.name, value: t.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="disscountTypeId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="discounttype.inventory.form.label.name"
                                        popoverkey="discounttype.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="discounttype.inventory.modal.validation.name" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={disscountTypes.map(t => ({ label: t.name, value: t.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="disscountValue"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="discountvalue.inventory.form.label.name"
                                        popoverkey="discountvalue.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="discountvalue.inventory.modal.validation.name" />,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The discount  must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div> <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="quantityAlert"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="quantityalert.inventory.form.label.name"
                                        popoverkey="quantityalert.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity alert value must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row select-color-add">
                    <div className="col-lg-6 col-sm-6 col-12 ">
                        <div className="input-blocks add-product">
                            <div className="row align-items-center">
                                <div className="col-lg-10">
                                    <div className="input-blocks">
                                        <Form.Item
                                            name="variantId"
                                            className="actions_addModalFormItem"
                                            label={
                                                <CustomFormLabel
                                                    labelkey="variantattribute.inventory.form.label.name"
                                                    popoverkey="variantattribute.inventory.form.label.popover.name"
                                                />
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="variantattribute.inventory.modal.validation.name" />,
                                                },
                                            ]}
                                        >

                                            <Select
                                                classNamePrefix="react-select"
                                                options={variantsData.map(s => ({ label: s.name, value: s.id }))}
                                                placeholder="Choose"
                                                className="input-select"
                                                showSearch
                                                filterOption={(input, option) =>
                                                  option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent="No results found"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-lg-2 ps-0">
                                    <div className="add-icon tab mt-1">
                                        <Link
                                            className="btn btn-filter"
                                            onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                        >
                                            <PlusCircle className="feather feather-plus-circle" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className="accordion-card-one accordion"
                    id="accordionExample3"
                >
                    <div className="accordion-item">
                        <div
                            className="accordion-header"
                            id="headingThree"
                        >
                            <div
                                className="accordion-button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-controls="collapseThree"
                            >
                                <div className="addproduct-icon list col-lg-12 d-flex justify-content-between mb-3">
                                    <h5>
                                        <i
                                            data-feather="image"
                                            className="add-info"
                                        />
                                        <span>Images</span>
                                    </h5>
                                    <Link to="#">
                                        <ChevronDown className="chevron-down-add" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            id="collapseThree"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample3"
                        >
                            <div className="accordion-body">
                                <div className="text-editor add-list add">
                                    <div className="col-lg-12">
                                        <div className="input-blocks">
                                            <Form.Item
                                                name="imagesUrls"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return isError
                                                                ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                : Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Upload
                                                    listType="picture-circle"
                                                    fileList={fileList}
                                                    onPreview={handlePreview}
                                                    onChange={handleChange}
                                                >
                                                    {fileList.length >= 5 ? null : uploadButton}
                                                </Upload>
                                                {previewImage && (
                                                    <Image
                                                        wrapperStyle={{
                                                            display: 'none',
                                                        }}
                                                        preview={{
                                                            visible: previewOpen,
                                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                        }}
                                                        src={previewImage}
                                                    />
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isVariantModalOpen ? (
                <AddvariantModal
                    currentModalData={currentVariantModalData}
                    isModalOpen={isVariantModalOpen}
                    isUpdate={false}
                    toggleModal={toggleVariantModal}
                />
            ) : null}
        </Form>

    );
};

export default ProductPricingAndStockTab;
