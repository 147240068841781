import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../sales/debitnotes/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateDebitNote({ debitNoteData, actionName, debitNoteParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Purchases/edit-crdrtnote`,
        data: debitNoteData,
      });
    } else {
      yield call(api.post, {
        route: '/Purchases/create-crdrtnote',
        data: debitNoteData,
      });
    }
    
    yield put(Actions.fetchAllDebitNotes({ debitNoteParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.DEBIT_NOTE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.DEBIT_NOTE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllDebitNotes({ debitNoteParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch debit notes
    const { data } = yield call(api.get, {
      route: '/Purchases/all-debitnotes',
      params: debitNoteParams,
    });
    // Store the fetched data in Redux store
    const allDebitNoteData = data;
    yield put(Actions.setDebitNotes({ allDebitNoteData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteDebitNoteById({ debitNoteParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/purchases/delete-crdrtnote/` + debitNoteParam.id,
    });
    yield put(Actions.fetchAllDebitNotes({ debitNoteParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.DEBIT_NOTE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetQuotationByQNById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/Purchases/all-quotationByQN?QuotationNo=` + QnParam.id,
    });
    const QuotationData = data;
    yield put(Actions.setQuotationNoById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_DEBIT_NOTE, fetchAllDebitNotes),
    takeLatest(ActionTypes.ADD_UPDATE_DEBIT_NOTE, addUpdateDebitNote),
    takeLatest(ActionTypes.DELETE_DEBIT_NOTE, deleteDebitNoteById),
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, GetQuotationByQNById)
  ]);
}
