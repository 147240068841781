import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import holidayActions from '../../../core/Services/hrm/holiday/actions'
import CustomFormLabel from '../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdateHolidayModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, holidayParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isSwitchActive, setIsSwitchActive] = useState(false);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        form.setFieldsValue({ startDate: date ? moment(date).format('YYYY-MM-DD') : null });
        form.validateFields(["endDate"]);
    }
    const handleEndDateChange = (date) => {
        setEndDate(date);
        form.setFieldsValue({ endDate: date ? moment(date).format('YYYY-MM-DD') : null });
    }

    const validateEndDateTime = (_, value) => {
        if (!value || !startDate) {
            return Promise.resolve();
        }

        const toDateTime = moment(value);
        const fromDateTime = moment(startDate);

        if (!toDateTime.isValid()) {
            return Promise.reject(new Error("Invalid End Date"));
        }

        if (toDateTime.isSameOrBefore(fromDateTime)) {
            return Promise.reject(new Error("End Date should be greater than Start Date"));
        }

        return Promise.resolve();
    };

    const onSwitchChange = (checked) => {
        form.setFieldsValue({ isActive: checked })
        setIsSwitchActive(checked)
    }

    const handleRecord = (actionName, holidayData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            holidayData.id = currentModalData.formData.id
            holidayData.companyId = 1
            holidayData.noOfDays = Number.parseInt(holidayData.noOfDays)
            holidayData.isActive = isSwitchActive
        }
        else {
            holidayData.companyId = 1
            holidayData.noOfDays = Number.parseInt(holidayData.noOfDays)
            holidayData.isActive = isSwitchActive
        }

        dispatch(holidayActions.addUpdateHoliday({ holidayData, actionName, holidayParams: holidayParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
            setIsSwitchActive(currentModalData.formData.isActive)
        }
    }, [isUpdate])

    useEffect(() => {
        if (isUpdate && isUpdate == true && currentModalData && currentModalData.formData) {
            form.setFieldsValue({ startDate: moment(currentModalData.formData.startDate).format('YYYY-MM-DD') })
            form.setFieldsValue({ endDate: moment(currentModalData.formData.endDate).format('YYYY-MM-DD') })
        }
    }, [isUpdate])


    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Holiday"
                ) : (
                    "Add Holiday"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: currentModalData?.formData?.name,
                    noOfDays: currentModalData.formData.noOfDays,

                }}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="name"
                                        label="Add Holiday"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name Required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Required validation will handle empty value
                                                    }

                                                    // Check if the name exceeds 30 characters
                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Name cannot exceed 30 characters."));
                                                    }

                                                    // Check if the name is all numbers
                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // Passes validation
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="startDate"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Start Date"
                                        popoverkey="Start Date"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Start Date Required",
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="endDate"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="End Date"
                                        popoverkey="End Date"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "End Date Required",
                                    },
                                    { validator: validateEndDateTime },
                                ]}
                            >
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div>
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="noOfDays"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="No. Of Days"
                                                popoverkey="Number of Days"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Number of Days Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Duration must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Select Duration'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                        <Switch checked={isSwitchActive} onChange={onSwitchChange} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateHolidayModal;