import React, { useState, useEffect} from "react";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector} from "react-redux";
import { OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import logsAction from "../../../core/Services/people/logs/actions"
import * as PeopleManagementSelectors from '../PeopleManagementSelectors';
import { Popover, Typography, Button } from 'antd';
// import * as ApiResponseSelectors from '../../selectors';
// import apiResponseAction from '../../../core/Services/apiResponse/actions';
import Table from "../../../core/pagination/datatable";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
    RotateCcw,
} from "feather-icons-react/build/IconComponents";

 const logs = () => {
    const dispatch = useDispatch();
    const [logsParams, setlogsParams] = useState({
        pageNo: 1,
        searchText: '',
    });
    const [debouncedText] = useDebounce(logsParams, 1000);
    const isLoading = useSelector(state => PeopleManagementSelectors.logsLoader(state));
    const logsData = useSelector(state => PeopleManagementSelectors.setLogs(state));

    const dataSource = logsData ? logsData.toJS() : [];

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
      };
    // const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  


    useEffect(() => {
        dispatch(logsAction.fetchAllLogs({ logsParams: debouncedText }));
    }, [dispatch, debouncedText, logsParams]);
   


    const handleDataIntoExcel = (filename = 'logs.xlsx') => {
        if (dataSource && dataSource.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(dataSource);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(data, filename);
        } else {
            console.error("No data to export");
        }
    };

    const handleGeneratePDF = () => {
        const doc = new jsPDF();
        const headers = ['Type', 'Exception', 'Stack Trace', 'Time Stamp', 'User'];

        const tableData = dataSource.map((item) => [
            item.Type,
            item.Exception,
            item.StackTrace,
            item.TimeStamp,
            item.User,
        ]);

        doc.autoTable({
            startY: 30,
            head: [headers],
            body: tableData,
        });

        doc.save('logs.pdf');
    };

    const handleRefresh = () => {
        dispatch(logsAction.fetchAllLogs({ logsParams}));
    };
    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>Pdf</Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>Excel</Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>Refresh</Tooltip>
    );
    const columns = [
        {
            title:<IntlMessages id="people.logs.column.label.type" />,
            dataIndex: "exceptionType",
            sorter: (a, b) => a.exceptionType.length - b.exceptionType.length,
        },
        {
            title:<IntlMessages id="people.logs.column.label.exception" />,
            dataIndex: "exceptionMessage",
            sorter: (a, b) => a.exceptionMessage.length - b.exceptionMessage.length,
        },
        {
            title: <IntlMessages id="people.logs.column.label.stacktrace" />,
            dataIndex: "stackTrace",
            render: (text) => {
              const truncatedText = truncateText(text, 10); // Limit to 50 words
        
              return (
                <div>
                  <Typography.Text style={{ wordBreak: 'break-all', whiteSpace: 'normal', display: 'block' }}>
                    {truncatedText}
                  </Typography.Text>
                  {text.split(' ').length > 10 && (
                    <Popover
                      content={
                        <Typography.Text style={{ wordBreak: 'break-all', whiteSpace: 'normal', display: 'block' }}>
                          {text}
                        </Typography.Text>
                      }
                      title="Full Stack Trace"
                      trigger="click"
                    >
                      <a type="link">...more</a>
                    </Popover>
                  )}
                </div>
              );
            },
          },      
        {
            title:<IntlMessages id="people.logs.column.label.timestamp" />,
            dataIndex: "timestamp",
            render: (text) => {
              const date = new Date(text);
              const formattedDate = date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              }).replace(/ /g, ' ');
          
              const formattedTime = date.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,  // This ensures the time is shown in 12-hour format with AM/PM
              });
          
              return `${formattedDate} ${formattedTime}`;
            },
            sorter: (a, b) => a.timestamp.length - b.timestamp.length,
        },
        {
            title:<IntlMessages id="people.logs.column.label.user" />,
            dataIndex: "userId",
            sorter: (a, b) => a.userId.length - b.userId.length,
        },
 
    ];


    return (
        <>
             <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4><IntlMessages id="people.logs.column.heading.logs" /></h4>
              <h6><IntlMessages id="people.logs.column.label.managelogs" /></h6>
            </div>
          </div>
          <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link
                                        onClick={() => handleGeneratePDF()}
                                    >
                                        <ImageWithBasePath
                                            src="assets/img/icons/pdf.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                                        <ImageWithBasePath
                                            src="assets/img/icons/excel.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to="/" className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            {/* /Filter */}

            {/* /Filter */}

            <div className="table-responsive">
               <Table isLoading={isLoading} columns={columns} dataSource={dataSource} />
              </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
        </>
    );
};
export default logs;
