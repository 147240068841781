export const ActionTypes = {
    ADD_UPDATE_SMSGATEWAY: 'services/Settings/smsgateway/add_update_smsgateway',
    FETCH_SMSGATEWAY: 'services/Settings/smsgateway/fetch_smsgateway',
    SET_SMSGATEWAY: 'services/Settings/smsgateway/set_smsgateway',
    DELETE_SMSGATEWAY: 'services/Settings/smsgateway/delete_smsgateway',
    SET_LOADER: 'services/Settings/smsgateway/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllSmsGateway = ({ smsgatewayParams }) => ({
    smsgatewayParams,
    type: ActionTypes.FETCH_SMSGATEWAY,
})

const setSmsGateway = ({ allSmsGatewayData }) => ({
    allSmsGatewayData,
    type: ActionTypes.SET_SMSGATEWAY,
})

const deleteSmsGatewayById = ({ smsgatewayParam }) => ({
    smsgatewayParam,
    type: ActionTypes.DELETE_SMSGATEWAY,
})

const addUpdateSmsGateway = ({ smsgatewayData, actionName, smsgatewayParams }) => ({
    smsgatewayData,
    actionName,
    smsgatewayParams,
    type: ActionTypes.ADD_UPDATE_SMSGATEWAY,
})

export default {
    addUpdateSmsGateway,
    fetchAllSmsGateway,
    setSmsGateway,
    deleteSmsGatewayById,
    setLoader
};
