export const languageSettingsData = [
    {
        id: '1',
        language: 'English',
        code: 'en',
        rtl: true,
        total: 2145,
        done: 1815,
        progress: 80,
        status: 'Active',
        flag: 'assets/img/icons/flag-01.svg',
      },
      {
        id: '2',
        language: 'Arabic',
        code: 'ar',
        rtl: false,
        total: 2045,
        done: 2045,
        progress: 100,
        status: 'Inactive',
        flag: 'assets/img/icons/flag-02.svg',
      },
      {
        id: '3',
        language: 'Chinese',
        code: 'zh',
        rtl: false,
        total: 2245,
        done: 295,
        progress: 5,
        status: 'Active',
        flag: 'assets/img/icons/flag-03.svg',
      },
      {
        id: '4',
        language: 'Hindi',
        code: 'hi',
        rtl: false,
        total: 2535,
        done: 1145,
        progress: 40,
        status: 'Active',
        flag: 'assets/img/icons/flag-04.svg',
      },
]