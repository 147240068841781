export const peopleManagementLoader = state => {
    return state.services.customers.getIn(['showLoader']);
};

export const suppliersLoader = state => {
    return state.services.suppliers.getIn(['showLoader']);
};

export const customersData = state => {
    return state.services.customers.getIn(['allCustomerData']);
};

export const suppliersData = state => {
    return state.services.suppliers.getIn(['allSupplierData']);
 };

 export const storesData = state => {
    return state.services.stores.getIn(['allStoresData']);
 };
 export const warehousesData = state => {
    return state.services.warehouses.getIn(['allWarehousesData']);
 };

 export const customerProfileImage = state => {
    return state.services.customers.getIn(['customerImage']);
  };
 export const supplierProfileImage = state => {
    return state.services.suppliers.getIn(['supplierImage']);
  };
 export const storeProfileImage = state => {
    return state.services.stores.getIn(['storeImage']);
  };

  export const logsLoader = state => {
   return state.services.logs.getIn(['showLoader']);
 };

 export const setLogs = state => {
   return state.services.logs.getIn(['allLogsData']);
 };