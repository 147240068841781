import React, { useState, useEffect } from 'react';
import shipmentActions from '../../../core/Services/sales/shipment/actions.js';
import commonActions from '../../../core/Services/common/actions.js';
import { useDispatch, useSelector } from "react-redux";
import {Modal, Form, DatePicker, Select, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import moment from 'moment';

import * as commonSelectors from '../../commonSelectors';

const AddShipmentModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, shipmentParams } = props;
    const [selectedDate, setSelectedDate] = useState(new Date());
    const SaleOrderData = useSelector(state => commonSelectors.setAllSaleOrder(state));
    const WarehousesData = useSelector(state => commonSelectors.setAllWarehouses(state));
    const SaleOrderdataSource = SaleOrderData ? SaleOrderData.toJS() : [];
    const WarehousedataSource = WarehousesData ? WarehousesData.toJS() : [];

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const period = [
        { value: "Week", label: "Week" },
        { value: "Month", label: "Month" },
        { value: "Year", label: "Year" },
    ]

    useEffect(() => {
        if (isUpdate) {
            setSelectedDate(currentModalData.formData.shipmentDate ? moment(currentModalData.formData.shipmentDate).format('YYYY-MM-DD')  : null);
        }
    }, [currentModalData])

    useEffect(() => {
        dispatch(commonActions.getAllSaleOrder());
        dispatch(commonActions.getAllWarehouses());
    }, [])

    const handleDateChange = (date) => {
        debugger
        setSelectedDate(date);
        form.setFieldValue({ shipmentDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleRecord = (actionName, shipmentData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            shipmentData.id = currentModalData.formData.id;
            shipmentData.companyId = currentModalData.formData.companyId;
            shipmentData.shipmentDate = selectedDate;
        } else {
            shipmentData.companyId = 0;
            shipmentData.shipmentDate = selectedDate;
        }
        shipmentData.isActive = shipmentData.isActive ? true : false;
        dispatch(shipmentActions.addUpdateShipments({ shipmentData, actionName, shipmentParams: shipmentParams }));
        toggleModal();
    };

    return (

        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="sale.shipment.form.modal.title.update" />
                ) : (
                    <IntlMessages id="sale.shipment.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="sale.shipment.form.modal.update" />
                ) : (
                    <IntlMessages id="sale.shipment.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="sale.shipment.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="categoryForm"
                className='categoryActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    saleOrderId: currentModalData.formData.saleOrderId,
                    wareHouseId: currentModalData.formData.wareHouseId,
                    fullShipment: currentModalData.formData.fullShipment,
                }}
            >

                <div className='row'>

                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="saleOrderId"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="sale.shipment.form.sale.order.label.name"
                                        popoverkey="sale.shipment.form.label.popover.sale.order.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="sale.shipment.modal.validation.sale.order.name" />,
                                    },
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    classNamePrefix="react-select"
                                    options={SaleOrderdataSource.map(f => ({ label: f.name, value: f.id }))}
                                    placeholder={CONSTANT_VARIABLES.CATEGORY_SELCT}
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <Form.Item
                                    name="wareHouseId"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.shipment.form.warehouse.label.name"
                                            popoverkey="sale.shipment.form.label.popover.warehouse.name"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.shipment.modal.validation.warehouse.name" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                        classNamePrefix="react-select"
                                        options={WarehousedataSource.map(f => ({ label: f.name, value: f.id }))}
                                        placeholder={CONSTANT_VARIABLES.CATEGORY_SELCT}
                                        className="input-select"
                                        showSearch
                                        filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                    }
                                        notFoundContent="No results found"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <Form.Item
                                    name="shipmentDate"
                                    className='actions_addModalFormItem'
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.shipment.form.warehouse.label.shipment.date"
                                            popoverkey="sale.shipment.form.label.popover.shipment.date"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.shipment.modal.validation.shipment.date" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="YYYY-MM-DD"
                                        placeholderText="Select Date"
                                        className="actions_addModalFormItem"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        {/* <div className="input-blocks"> */}
                        <div className="addCategory_form--status_area">
                            <span className="status-label"> <IntlMessages id="sale.shipment.modal.fullShipment" /></span>
                            <Form.Item
                                name="fullShipment"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Switch size="small" />
                            </Form.Item>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </Form>

        </Modal >

    )
}

export default AddShipmentModal
