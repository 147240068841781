export const ActionTypes = {
    ADD_UPDATE_STOCKTRANSFER: 'services/stock/stocktransfers/add_update_stocktransfer',
    FETCH_STOCKTRANSFER: 'services/stock/stocktransfers/fetch_stocktransfers',
    SET_STOCKTRANSFERS: 'services/stock/stocktransfers/set_stocktransfers',
    DELETE_STOCKTRANSFERS: 'services/stock/stocktransfers/delete_stocktransfers',
    SET_LOADER: 'services/stock/stocktransfers/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllStockTransfers = ({ stocktransferParams }) => ({
    stocktransferParams,
    type: ActionTypes.FETCH_STOCKTRANSFER,
})

const setStockTransfers = ({ allStockTransferData }) => ({
    allStockTransferData,
    type: ActionTypes.SET_STOCKTRANSFERS,
})

const deleteStockTransferById = ({ stocktransferParam }) => ({
    stocktransferParam,
    type: ActionTypes.DELETE_STOCKTRANSFERS,
})

const addUpdateStockTransfers = ({ stocktransferData, actionName, UserParams }) => ({
    stocktransferData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_STOCKTRANSFER,
})

export default {
    addUpdateStockTransfers,
    fetchAllStockTransfers,
    setStockTransfers,
    deleteStockTransferById,
    setLoader
};
