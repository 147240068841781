export const setEmployees = state => {
   return state.services.employee.getIn(['allEmployeeData']);
};

export const setemployeeLoader = state => {
   return state.services.employee.getIn(['showLoader']);
};

export const setDesignations = state => {
   return state.services.designation.getIn(['allDesignationData']);
};

export const setdesignationLoader = state => {
   return state.services.designation.getIn(['showLoader']);
};

export const setDepartments = state => {
   return state.services.department.getIn(['allDepartmentData']);
};

export const setdepartmentLoader = state => {
   return state.services.department.getIn(['showLoader']);
};

export const setHolidayLoader = state => {
   return state.services.holiday.getIn(['showLoader']);
};

export const setAttendanceLoader = state => {
   return state.services.attendance.getIn(['showLoader']);
};

export const setEmployeeAttendanceLoader = state => {
   return state.services.attendance.getIn(['showLoader']);
};

export const setLeaveTypeLoader = state => {
   return state.services.employeeleave.getIn(['showLoader']);
};

export const setPayrollLoader = state => {
   return state.services.payroll.getIn(['showLoader']);
};

export const setShifts = state => {
   return state.services.shifts.getIn(['allShiftData']);
};

export const setAttendance = state => {
   return state.services.attendance.getIn(['allAttendanceData']);
};

export const setLeave = state => {
   return state.services.leave.getIn(['allLeaveData']);
};

export const setLeaveTypes = state => {
   return state.services.leave.getIn(['allLeaveTypeData']);
};

export const setEmployeeAttendanceById = state => {
   return state.services.attendance.getIn(['employeeAttendanceData']);
};

export const setshiftsLoader = state => {
   return state.services.shifts.getIn(['showLoader']);
};

export const setHoliday = state => {
   return state.services.holiday.getIn(['allHolidayData']);
};
export const setEmployeeleaves = state => {
   return state.services.employeeleave.getIn(['allEmployeeleaveData']);
};
export const setPayrolls = state => {
   return state.services.payroll.getIn(['allPayrollData']);
};

export const setPayslip = state => {
   return state.services.payroll.getIn(['allpayslipData']);
};

export const setPayslipLoader = state => {
   return state.services.payroll.getIn(['showLoader']);
};
