import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Badge, Dropdown, Table, Space } from 'antd';
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath.js";
import {
    ChevronUp,
    Filter,
    PlusCircle,
    RotateCcw,
    Calendar
  } from "feather-icons-react/build/IconComponents";

const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

const expandDataSource = Array.from({ length: 3 }).map((_, i) => ({
    key: i.toString(),
    date: '2014-12-24 23:12:00',
    name: 'This is production name',
    upgradeNum: 'Upgraded: 56',
}));

const dataSource = Array.from({ length: 3 }).map((_, i) => ({
    key: i.toString(),
    name: 'Screen',
    platform: 'iOS',
    version: '10.3.4.5654',
    upgradeNum: 500,
    creator: 'Jack',
    createdAt: '2014-12-24 23:12:00',
}));

const expandColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Status',
        key: 'state',
        render: () => <Badge status="success" text="Finished" />,
    },
    {
        title: 'Upgrade Status',
        dataIndex: 'upgradeNum',
        key: 'upgradeNum',
    }
];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Action',
        key: 'operation',
        render: () => <a>Publish</a>,
    },
];

const expandedRowRender = () => (
    <Table columns={expandColumns} dataSource={expandDataSource} pagination={false} />
);

const CashbookList = () => (
    <div className="page-wrapper">
        <div className="content">
        <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Cash Book</h4>
                <h6>Manage your cash book</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ['0'],
                            }}
                            dataSource={dataSource}
                        />
                    </div>
        </div>
    </div>
);

export default CashbookList;
