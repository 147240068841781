import React, { useState, useEffect } from 'react';
import * as posSelectors from '../../posSelectors.js';
import todayExpensesActions from '../../../../core/Services/pos/cashclosing/todayexpenses/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Radio, Space, Select, InputNumber, Input, Form } from 'antd';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants.js";
import IntlMessages from "../../../../shared/components/utility/intlMessages.js";
import './todayexpenses.css';
import '../../pos.css';
import * as ApiResponseSelectors from '../../../selectors.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import commonAction from "../../../../core/Services/common/actions";
import * as commonSelector from '../../../commonSelectors';
import TextEditor from "../../../inventory/texteditor";

const AddExpensesModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, todayExpensesParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [userType, setUserType] = useState("Supplier");
    const todayExpensesData = useSelector(state => posSelectors.setTodayExpense(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
    const expenseTypeData = useSelector(state => commonSelector.setAllExpensetype(state));
    const allExpenseTypeData = expenseTypeData ? expenseTypeData.toJS() : [];
    const supplierData = useSelector(state => commonSelector.setAllSuppliers(state));
    const allSupplierData = supplierData ? supplierData.toJS() : [];
    const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
    const allEmployeeData = employeeData ? employeeData.toJS() : [];
    const expenseCategoryData = useSelector(state => commonSelector.setAllExpenseCategory(state));
    const allExpenseCategoryData = expenseCategoryData ? expenseCategoryData.toJS() : [];
    const [editorData, setEditorData] = useState();

    useEffect(() => {
        dispatch(commonAction.fetchAllexpenstypes());
        dispatch(commonAction.getAllSuppliers());
        dispatch(commonAction.getAllEmployee());
        dispatch(commonAction.getAllExpenseCategory());
    }, [])

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setSelectedDate(currentModalData.formData.closingDate ? new Date(currentModalData.formData.closingDate) : null);
            form.setFieldsValue({
                ...currentModalData.formData,
                closingDate: currentModalData.formData.closingDate ? moment(currentModalData.formData.closingDate).format('YYYY-MM-DD') : null,
                userType: currentModalData.formData.isEmployeeExpense ? "Employee" : "Supplier"
            });
            if (currentModalData && currentModalData.formData.description) {
                let editorFetchedData = currentModalData.formData.description?.replace(/""/g, '');
                setEditorData(editorFetchedData);
            }
            setUserType(currentModalData.formData.isEmployeeExpense ? "Employee" : "Supplier");
        }
    }, [isUpdate, currentModalData, form]);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ expenseDate: date ? moment(date).format('YYYY-MM-DD').toString() : null });
    };

    const handleRadioChange = (e) => {
        setUserType(e.target.value);
        form.setFieldsValue({ userType: e.target.value });
    };

    const handleRecord = (actionName, todayExpensesData) => {
        todayExpensesData.isEmployeeExpense = userType === "Supplier" ? false : true;
        if (userType === "Supplier") {
            todayExpensesData.employeeId = 0;
            todayExpensesData.expenseTypeId = 0;
        }
        if (userType === "Employee") {
            todayExpensesData.supplierId = 0;
        }

        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            todayExpensesData.id = currentModalData.formData.id;
            todayExpensesData.companyId = currentModalData.formData.companyId;
        } else {
            todayExpensesData.id = 0;
            todayExpensesData.companyId = 1;
        }
        todayExpensesData.description = editorData;
        debugger;
        dispatch(todayExpensesActions.addUpdateTodayExpenses({ todayExpensesData, actionName, TodayExpensesParams: todayExpensesParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={700}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="todayExpenses.pos.form.modal.title.update" />
                ) : (
                    <IntlMessages id="todayExpenses.pos.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="todayExpenses.pos.form.modal.update" />
                ) : (
                    <IntlMessages id="todayExpenses.pos.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="todayExpenses.pos.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="todayExpensesForm"
                className='todayExpensesActions_addModalWrapper'
                initialValues={{
                    supplierId: currentModalData.formData ? currentModalData.formData.supplierId : 0,
                    employeeId: currentModalData.formData ? currentModalData.formData.employeeId : 0,
                    expenseTypeId: currentModalData.formData ? currentModalData.formData.expenseTypeId : 0,
                    amount: currentModalData.formData ? currentModalData.formData.amount : 0,
                    headId: currentModalData.formData ? currentModalData.formData.headId : 0,
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="expenseDate"
                                label={<IntlMessages id="pos.todayexpenses.form.label.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="userType"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="pos.todayexpenses.form.label.user.type" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.label.user.type" />,
                                    },
                                ]}
                            >
                                <Radio.Group onChange={handleRadioChange} value={userType}>
                                    <Space direction='horizontal'>
                                        <Radio value={"Employee"}>
                                            <IntlMessages id="pos.todayexpenses.form.label.user.type.employee" />
                                        </Radio>
                                        <Radio value={"Supplier"}>
                                            <IntlMessages id="pos.todayexpenses.form.label.user.type.supplier" />
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                {userType === "Supplier" && (
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                                <Form.Item
                                    name="supplierId"
                                    label={<IntlMessages id="pos.todayexpenses.form.label.supplier.name" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="pos.todayexpenses.form.validation.supplier.name" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={allSupplierData.map(f => ({ label: f.name, value: f.id }))}
                                        classNamePrefix="react-select"
                                        className="input-select"
                                        placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                )}
                {userType === "Employee" && (
                    <>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="employeeId"
                                        label={<IntlMessages id="pos.todayexpenses.form.label.employee.name" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="pos.todayexpenses.form.validation.employee.name" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={allEmployeeData.map(f => ({ label: f.name, value: f.id }))}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="expenseTypeId"
                                        label={<IntlMessages id="pos.todayexpenses.form.label.expenseType" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="pos.todayexpenses.form.validation.expenseType" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={allExpenseTypeData.map(f => ({ label: f.name, value: f.id }))}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="amount"
                                label={<IntlMessages id="pos.todayexpenses.form.label.amount" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.amount" />,
                                    },
                                ]}
                            >
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="headId"
                                label={<IntlMessages id="pos.todayexpenses.form.label.expenseHead" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.expenseHead" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={allExpenseCategoryData.map(f => ({ label: f.name, value: f.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                className="actions_addModalFormItem"
                                name="description"
                                label={<IntlMessages id="pos.todayexpenses.form.label.description" />}
                            >
                                <TextEditor data={editorData} onChange={handleEditorChange} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}

export default AddExpensesModal;
