import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../taxrates/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateTaxrate({ taxrateData, actionName, taxrateParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-taxrate`,
        data: taxrateData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-taxrate',
        data: taxrateData,
      });
    }
    
    yield put(Actions.fetchAllTaxrate({ taxrateParams }));
    notification('success', actionName === 'update' ? "Taxrate Updated Successfully" : "Taxrate Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllTaxrate({ taxrateParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-taxrates',
      params: taxrateParams,
    });

    const allTaxrateData = data;
    yield put(Actions.setTaxrate({ allTaxrateData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteTaxrateById({ taxrateParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-taxrate/` + taxrateParam.id,
    });
    yield put(Actions.fetchAllTaxrate({ taxrateParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Taxrate Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_TAXRATE, fetchAllTaxrate),
    takeLatest(ActionTypes.ADD_UPDATE_TAXRATE, addUpdateTaxrate),
    takeLatest(ActionTypes.DELETE_TAXRATE, deleteTaxrateById)
  ]);
}
