import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Select, Spin } from 'antd';
import ReactSelect from 'react-select';
import { useDebounce } from 'use-debounce';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import recipesActions from '../../../core/Services/recipes/actions';
import commonAction from '../../../core/Services/common/actions';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import * as commonSelectors from '../../commonSelectors';
import TextArea from 'antd/es/input/TextArea.js';

const AddRecipes = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, recipesParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [recipesDetails, setRecipesDetails] = useState([]);
    const [productParams, setProductParams] = useState({ searchText: '' });
    const isLoading = useSelector(state => commonSelectors.setLoader(state));
    const [debouncedText] = useDebounce(productParams.searchText, 1000);
    const [selectedRecipe, setSelectedRecipe] = useState(null); 

    const productsBySearchDataSource = useSelector(state => commonSelectors.setAllProductsBySearch(state));
    const searchedOptions = productsBySearchDataSource ? productsBySearchDataSource.toJS() : [];

    const unitsDataSource = useSelector(state => commonSelectors.setAllUnits(state));
    const units = unitsDataSource ? unitsDataSource.toJS() : [];

    useEffect(() => {
        dispatch(commonAction.getAllUnits());
    }, [dispatch]);

    useEffect(() => {
        if (isUpdate) {
            dispatch(commonAction.getAllProductsBySearch({ productParams: {} }));
        }
    }, [isUpdate, dispatch]);

    useEffect(() => {
        if (debouncedText) {
            dispatch(commonAction.getAllProductsBySearch({ productParams: debouncedText }));
        }
    }, [debouncedText, dispatch]);

    useEffect(() => {
        if (isUpdate && currentModalData?.formData) {
            const { formData } = currentModalData;
            setRecipesDetails(formData.recipeDetails);
            form.setFieldsValue({
                companyId: formData.companyId,
                description: formData.description,
                productId: formData.productId,
            });
            setSelectedRecipe({
                label: formData.productName,
                value: formData.productId,
            });
        }
    }, [currentModalData, isUpdate, form]);


    const handleRecipeChange = (selectedProduct) => {
        setSelectedRecipe(selectedProduct);
    };

    const handleIngredientChange = (selectedProduct) => {
        if (selectedProduct) {
            const exists = recipesDetails.some(p => p.productId === selectedProduct.value);
            if (!exists) {
                setRecipesDetails(prevDetails => [
                    ...prevDetails,
                    {
                        id: 0,
                        recipeId: 0,
                        productId: selectedProduct.value,
                        productName: selectedProduct.label,
                        productImages: selectedProduct.label,
                        unitId: null,
                        unitName: 'kg',
                        weight: 1,
                    }
                ]);
            }
        }
    };

    const handleProductSearch = textValue => {
        setProductParams({ searchText: textValue });
    };

    const handleRemoveProduct = (index) => {
        const updatedDetails = [...recipesDetails];
        updatedDetails.splice(index, 1);
        setRecipesDetails(updatedDetails);
    };

    const handleUnitChange = (index, unitId) => {
        const updatedDetails = [...recipesDetails];
        updatedDetails[index].unitId = unitId;
        updatedDetails[index].unitName = units.find(unit => unit.id === unitId)?.name || 'kg';
        setRecipesDetails(updatedDetails);
    };

    const handleRecord = (actionName, recipesData) => {
        const recipePayload = {
            id: isUpdate ? currentModalData.formData.id : 0,
            companyId: isUpdate ? currentModalData.formData.companyId : 0,
            productId: selectedRecipe.value,
            description: isUpdate ? currentModalData.description || '' : recipesData.description || '',
            recipeDetails: recipesDetails.map(detail => ({
                ...detail,
                id: isUpdate ? detail.id : 0,
                recipeId: isUpdate ? currentModalData.formData.id : 0,
            })),
        };

        dispatch(recipesActions.addUpdateRecipes({ recipesData: recipePayload, actionName, RecipesParams: recipesParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            width={1000}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={isUpdate ? <IntlMessages id="recipes.recipes.inventory.form.modal.title.update" /> : <IntlMessages id="recipes.recipes.inventory.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="recipes.inventory.form.modal.update" /> : <IntlMessages id="recipes.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="recipes.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="recipesForm"
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    productId: currentModalData.formData ? currentModalData.formData.productId : 0,
                    description: currentModalData.formData ? currentModalData.formData.description : '',
                }}
            > <div className="card-body pb-0">
                    <Spin spinning={isLoading} size="large">
                        <div className="row mt-5">
                            <div className="col-lg-6">
                                <Form.Item
                                    name="productId"
                                    label={<IntlMessages id="recipes.form.label.recipe" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="recipes.form.label.recipe" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={searchedOptions.map(p => ({ label: p.productName, value: p.id, }))}
                                        isClearable
                                        onInputChange={handleProductSearch}
                                        onChange={handleRecipeChange}
                                        placeholder={CONSTANT_VARIABLES.SELECT_PRODUCT}
                                        // value={selectedRecipe}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-blocks">
                                    <ReactSelect
                                        options={searchedOptions.filter(option => option.id !== selectedRecipe?.value).map(p => ({ label: p.productName, value: p.id, }))}
                                        isClearable
                                        onInputChange={handleProductSearch}
                                        onChange={handleIngredientChange}
                                        placeholder={CONSTANT_VARIABLES.SELECT_PRODUCT}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="modal-body-table">
                                    <div className="table-responsive">
                                        <table className="table datanew">
                                            <thead>
                                                <tr>
                                                    <th><IntlMessages id="recipes.table.column.product" /></th>
                                                    <th><IntlMessages id="recipes.table.column.unit" /></th>
                                                    <th><IntlMessages id="recipes.table.column.weight" /></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recipesDetails.map((product, index) => (
                                                    <tr key={index}>
                                                        <td className="p-3">{product.productName}</td>
                                                        <td className="p-3">
                                                            <Select
                                                                options={units.map(f => ({ label: f.name, value: f.id }))}
                                                                onChange={(unitId) => handleUnitChange(index, unitId)}
                                                                value={product.unitId}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                placeholder={<IntlMessages id="recipe.form.placeholder.choose" />}
                                                            />
                                                        </td>
                                                        <td className="p-3">
                                                            <Input type="number" min={0} defaultValue={product.weight} onChange={(e) => {
                                                                const updatedDetails = [...recipesDetails];
                                                                updatedDetails[index].weight = Number(e.target.value);
                                                                setRecipesDetails(updatedDetails);
                                                            }} />
                                                        </td>
                                                        <td className="p-3">
                                                            <i
                                                                data-feather="trash-2"
                                                                className="feather-trash-2"
                                                                onClick={() => handleRemoveProduct(index)}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <Form.Item
                                name="description"
                                label={<IntlMessages id="recipes.form.label.description" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="recipes.form.label.description" />,
                                    },
                                ]}
                            >
                                <TextArea />
                            </Form.Item>
                        </div>
                    </Spin>
                </div>
            </Form>
        </Modal>
    );
};

export default AddRecipes;
