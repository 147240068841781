import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { Button, Spin } from 'antd';
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { filterDate } from "../../../core/Common/Constant_Variables/common"
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import {
  Filter, PlusCircle, Sliders, StopCircle, Customer,
  Zap,
} from "react-feather";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import AddCustomer from "./addcustomers";
import * as PeopleManagementSelectors from '../PeopleManagementSelectors';
import customerActions from '../../../core/Services/people/customers/actions';
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as ApiResponseSelectors from '../../selectors';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../../feature-module/common/commonStyle.css';

const Customers = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const [customerParams, setCustomerParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [debouncedText] = useDebounce(customerParams, 1000);
  const isLoading = useSelector(state => PeopleManagementSelectors.peopleManagementLoader(state));
  const customerData = useSelector(state => PeopleManagementSelectors.customersData(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = customerData ? customerData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleCustomerModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(customerActions.fetchAllCustomers({ customerParams: debouncedText }));
  }, [dispatch]);

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );

  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handleDataIntoExcel = (filename = 'customers.xlsx') => {
    if (dataSource && dataSource.length > 0) {
      // debugger
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [
      ['id', 'profileImgUrl', 'customerName', 'customerEmail', 'customerPhone',
        'customerAddress', 'customerCity', 'customerCountry', 'customerDescription']
    ];

    const tableData = dataSource.map((item, index) => [
      item.id,
      item.profileImgUrl,
      item.custormerName,
      item.customerEmail,
      item.custormerPhone,
      item.custormerAddress,
      item.custormerCity,
      item.custormerCountry,
      item.custormerDescription
    ]);
    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Customers.pdf');
  };

  const handleRefresh = () => {
    dispatch(customerActions.fetchAllCustomers({ customerParams }));
  }

  const showConfirmationAlert = (customer) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // debugger
        dispatch(customerActions.deleteCustomers({ customerParam: customer }));
      } else {
        MySwal.close();
      }
    });
  };

  const onChangeSearch = event => {
    setCustomerParams({ ...customerParams, searchText: event.target.value, pageNo: 1 });
  };

  const toggleCustomerModal = (value) => {
    setCustomerModalOpen();
  }

  const handleModal = (customerData, actionName) => {
    const groupModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? customerData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(groupModalObj);
    setCustomerModalOpen(true);
  };

  const columns = [
    {
      title: <IntlMessages id="customer.table.column.name" />,
      dataIndex: "customerName",
      render: (text, record) => (
        <span className="userimgname">
          <Link to="#" className="userslist-img bg-img">
            <ImageWithBasePath alt="" src={record.profileImgUrl} isProfileImage={true} />
          </Link>
          <div>
            <Link to="#">{text}</Link>
          </div>
        </span>
      ),
      sorter: (a, b) => a.customername.length - b.customername.length,
    },
    {
      title: <IntlMessages id="customer.table.column.phone" />,
      dataIndex: "customerPhone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: <IntlMessages id="customer.table.column.email" />,
      dataIndex: "customerEmail",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: <IntlMessages id="customer.table.column.description" />,
      dataIndex: "customerDescription",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: <IntlMessages id="customer.table.column.address" />,
      dataIndex: "customerAddress",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">

            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4><IntlMessages id="customer.table.heading.customerslist" /></h4>
              <h6><IntlMessages id="customer.table.lable.managecustomerslist" /></h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link
                  onClick={() => handleGeneratePDF()}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/pdf.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
           
          </ul>

          <div className="page-btn">
            <Button
              className="ant-btn ant-btn-primary"
              onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
            >
              <PlusCircle className="me-2" />
              <IntlMessages id="customer.table.button.addnewcustomer" />
            </Button>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>

            </div>
            {/* /Filter */}
          
            {isCustomerModalOpen ? (
              <AddCustomer
                currentModalData={currentModalData}
                isModalOpen={isCustomerModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleCustomerModal}
                customerParams={customerParams}
              />
            ) : null}

            <div className="table-responsive">
              <Table loading={isLoading} columns={columns} dataSource={tableData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
