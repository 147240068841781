export const ActionTypes = {
    FETCH_AVAILABLESTOCKS: 'services/stock/availablestocks/fetch_availablestocks',
    SET_AVAILABLESTOCKS: 'services/stock/availablestocks/set_availablestocks',
    SET_LOADER: 'services/stock/availablestocks/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllAvailableStocks = ({ availableStockParams }) => ({
    availableStockParams,
    type: ActionTypes.FETCH_AVAILABLESTOCKS,
})

const setAvailableStocks = ({ allAvailableStockData }) => ({
    allAvailableStockData,
    type: ActionTypes.SET_AVAILABLESTOCKS,
})

export default {
    fetchAllAvailableStocks,
    setAvailableStocks,
    setLoader
};
