import { combineReducers } from 'redux';
import { createReducer } from '../utils/reducer';

// User Management
import * as userManagementReducer from "../Services/userManagement/reducer";
import * as customerReducer from "../Services/people/customers/reducer";
import * as supplierReducer from "../Services/people/suppliers/reducer";
import * as storesReducer from "../Services/people/stores/reducer";
import * as brandReducer from "../Services/inventory/brands/reducer";
import * as unitReducer from "../Services/inventory/units/reducer";
import * as variantReducer from "../Services/inventory/variant/reducer";
import * as warrantyReducer from "../Services/inventory/warranty/reducer";
import * as productReducer from "../Services/inventory/products/reducer";
import * as categoryReducer from "../Services/inventory/categories/reducer";
import * as subCategoryReducer from "../Services/inventory/subCategory/reducer";
import * as warehouseReducer from "../Services/people/warehouses/reducer";
import * as commonReducer from "../Services/common/reducer";
import * as financeAccountsexpenseReducer from "../Services/financeAccounts/expense/reducer";
import * as financeAccountsexpensesCategoryReducer from "../Services/financeAccounts/expensesCategory/reducer";
import * as apiResponseReducer from "../Services/apiResponse/reducer";
import * as purchaseReducer from "../Services/purchases/purchase/reducer";
import * as quotationReducer from "../Services/purchases/quotations/reducer";
import * as purchaseInvoiceReducer from "../Services/purchases/purchaseInvoice/reducer";
import * as purchaseReceiveReducer from "../Services/purchases/purchaseReceive/reducer";
import * as purchaseReturnReducer from "../Services/purchases/purchaseReturn/reducer";
import * as assettypesReducer from "../Services/purchases/assettypes/reducer";
import * as headsReducer from "../Services/purchases/heads/reducer";
import * as employeesReducer from "../Services/hrm/employee/reducer";
import * as designationReducer from "../Services/hrm/designation/reducer";
import * as departmentReducer from "../Services/hrm/department/reducer";
import * as shiftsReducer from "../Services/hrm/shifts/reducer";
import * as sellingTypeListReducer from "../Services/inventory/sellingtype/reducer"; 
import * as attendanceReducer from "../Services/hrm/attendance/reducer";
import * as paymentVoucherReducer from "../Services/purchases/paymentVoucher/reducer";
import * as creditNotesReducer from "../Services/purchases/creditnotes/reducer";
import * as denominationReducer from "../Services/pos/cashclosing/cashdenomination/reducer";
import * as todayExpensesReducer from "../Services/pos/cashclosing/todayexpenses/reducer";
import * as salequotationReducer from "../Services/sales/salequotations/reducer";
import * as saleInvoiceReducer from "../Services/sales/saleInvoice/reducer";
import * as saleReceiveReducer from "../Services/sales/saleReceive/reducer";
import * as saleReturnReducer from "../Services/sales/saleReturn/reducer";
import * as saleOrderReducer from "../Services/sales/salesOrder/reducer";
import * as debitNoteReducer from "../Services/sales/debitnotes/reducer";
import * as holidayReducer from "../Services/hrm/holiday/reducer";
import * as employeeleaveReducer from "../Services/hrm/employeeleave/reducer";
import * as leaveReducer from "../Services/hrm/leave/reducer";
import * as payrollReducer from "../Services/hrm/payroll/reducer";
import * as shipmentReducer from "../Services/sales/shipment/reducer";
import * as managestockReducer from "../Services/stock/managestock/reducer";
import * as stockadjustmentReducer from "../Services/stock/stockadjustment/reducer";
import * as stocktransferReducer from "../Services/stock/stocktransfer/reducer";
import * as vaultReducer from "../Services/stock/vault/reducer";
import * as demandrequestReducer from "../Services/stock/demandrequest/reducer";
import * as requestReducer from "../Services/stock/request/reducer";
import * as advanceBookingReducer from "../Services/pos/advanceBookings/reducer";
import * as userprofileReducer from "../Services/settings/userprofile/reducer";
import * as couponsReducer from "../Services/coupons/reducer";
import * as passowrdReducer from "../Services/settings/passowrd/reducer";
import * as notificationsReducer from "../Services/settings/notifications/reducer";
import * as connectedappReducer from "../Services/settings/connectedapp/reducer";
import * as companysettingReducer from "../Services/settings/companysetting/reducer";
import * as paymentgatewayReducer from "../Services/settings/paymentgateway/reducer";
import * as currencyReducer from "../Services/settings/currency/reducer";
import * as taxratesReducer from "../Services/settings/taxrates/reducer";
import * as banipReducer from "../Services/settings/banip/reducer";
import * as bankReducer from "../Services/settings/banks/reducer";
import * as emailsettingReducer from "../Services/settings/emailsetting/reducer";
import * as localizationReducer from "../Services/settings/localization/reducer";
import * as smsgatewayReducer from "../Services/settings/smsgateway/reducer";
import * as otpsettingReducer from "../Services/settings/otpsetting/reducer";
import * as reportsettingReducer from "../Services/settings/reportsetting/reducer";
import * as printersettingReducer from "../Services/settings/printersetting/reducer";
import * as customfieldReducer from "../Services/settings/customfield/reducer";
import * as adminDashboardReducer from "../Services/dashboard/reducer";
import * as recipesReducer from "../Services/recipes/reducer";
import * as pointofsaleReducer from "../Services/pos/pointofsale/reducer";
import * as logsReducer from "../Services/people/logs/reducer";

export default combineReducers({
    services: combineReducers({
        reportsetting: createReducer(reportsettingReducer),
        printersetting: createReducer(printersettingReducer),
        customfield: createReducer(customfieldReducer),
        smsgateway: createReducer(smsgatewayReducer),
        otpsetting: createReducer(otpsettingReducer),
        localization: createReducer(localizationReducer),
        emailsetting: createReducer(emailsettingReducer),
        banip: createReducer(banipReducer),
        paymentgateway: createReducer(paymentgatewayReducer),
        currency: createReducer(currencyReducer),
        taxrates: createReducer(taxratesReducer),
        bank: createReducer(bankReducer),
        userManagement: createReducer(userManagementReducer),
        customers: createReducer(customerReducer),
        suppliers: createReducer(supplierReducer),
        stores: createReducer(storesReducer),
        brands: createReducer(brandReducer),
        units: createReducer(unitReducer),
        categories: createReducer(categoryReducer),
        variant: createReducer(variantReducer),
        warranty: createReducer(warrantyReducer),
        products: createReducer(productReducer),
        subcategory: createReducer(subCategoryReducer),
        warehouses: createReducer(warehouseReducer),
        common: createReducer(commonReducer),
        expense: createReducer(financeAccountsexpenseReducer),
        expensesCategory: createReducer(financeAccountsexpensesCategoryReducer),
        purchase: createReducer(purchaseReducer),
        quotations: createReducer(quotationReducer),
        apiResponse: createReducer(apiResponseReducer),
        purchaseInvoice:createReducer(purchaseInvoiceReducer),
        purchaseReceive:createReducer(purchaseReceiveReducer),
        purchaseReturn:createReducer(purchaseReturnReducer),
        assettypes:createReducer(assettypesReducer),
        heads:createReducer(headsReducer),
        employee:createReducer(employeesReducer),
        designation:createReducer(designationReducer),
        department:createReducer(departmentReducer),
        shifts:createReducer(shiftsReducer),
        attendance:createReducer(attendanceReducer),
        creditNotes:createReducer(creditNotesReducer),
        paymentVoucher:createReducer(paymentVoucherReducer),
        denominations:createReducer(denominationReducer),
        todayexpenses:createReducer(todayExpensesReducer),
        sellingType: createReducer(sellingTypeListReducer),
        coupons: createReducer(couponsReducer),
        salequotations:createReducer(salequotationReducer),
        saleInvoice:createReducer(saleInvoiceReducer),
        saleReceive:createReducer(saleReceiveReducer),
        saleReturn:createReducer(saleReturnReducer),
        saleOrder:createReducer(saleOrderReducer),
        debitNote:createReducer(debitNoteReducer),
        holiday:createReducer(holidayReducer),
        employeeleave:createReducer(employeeleaveReducer),
        leave:createReducer(leaveReducer),
        payroll:createReducer(payrollReducer),
        shipment:createReducer(shipmentReducer),
        managestock:createReducer(managestockReducer),
        stockadjustment:createReducer(stockadjustmentReducer),
        stocktransfer:createReducer(stocktransferReducer),
        vault:createReducer(vaultReducer),
        demandrequest:createReducer(demandrequestReducer),
        request:createReducer(requestReducer),
        advanceBookings:createReducer(advanceBookingReducer),
        userprofile:createReducer(userprofileReducer),
        passowrd:createReducer(passowrdReducer),
        notifications:createReducer(notificationsReducer),
        connectedapp:createReducer(connectedappReducer),
        companysetting:createReducer(companysettingReducer),
        adminDashboard:createReducer(adminDashboardReducer),
        recipes:createReducer(recipesReducer),
        pointofsale:createReducer(pointofsaleReducer),
        logs:createReducer(logsReducer),
        
    }),
});