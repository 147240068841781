import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../purchases/purchase/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePurchase({ purchaseData, actionName, purchaseParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: '/purchases/edit-purchase',
        data: purchaseData,
      });
    } else {
      yield call(api.post, {
        route: '/purchases/create-purchase',
        data: purchaseData,
      });
    }
    
    yield put(Actions.fetchAllPurchases({ purchaseParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.PURCHASE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.PURCHASE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPurchases({ purchaseParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch purchases
    const { data } = yield call(api.get, {
      route: '/purchases/all-purchases',
      params: purchaseParams,
    });

    // Store the fetched data in Redux store
    const allPurchaseData = data;
    yield put(Actions.setPurchases({ allPurchaseData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deletePurchaseById({ purchaseParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/purchases/delete-purchase/` + purchaseParam.id,
    });
    yield put(Actions.fetchAllPurchases({ purchaseParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.PURCHASE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetPurchaseById({ purchaseParamId }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/Purchases/all-purchasereceivedbyId?Id=` + purchaseParamId,
    });
    const purchaseData = data;
    yield put(Actions.setPurchaseById({ purchaseData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetQuotationByQNById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/Purchases/all-quotationByQN?Id=` + QnParam,
    });
    const QuotationData = data;
    yield put(Actions.SetQuotationByQNById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

const getImmutableData = (data) => {
  const immutableJson = Immutable.fromJS(data);
  return immutableJson.get ? immutableJson.get('data') : immutableJson;
};

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PURCHASE, fetchAllPurchases),
    takeLatest(ActionTypes.ADD_UPDATE_PURCHASE, addUpdatePurchase),
    takeLatest(ActionTypes.DELETE_PURCHASES, deletePurchaseById),
    takeLatest(ActionTypes.GET_PURCHASE_BY_ID, GetPurchaseById),
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, GetQuotationByQNById)
  ]);
}
