import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import departmentActions from '../../../core/Services/hrm/department/actions'
import CustomFormLabel from '../../common/CustomFormLabel';


const AddUpdateDepartmentModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, departmentParams, formOptionsData } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, departmentData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            departmentData.id = currentModalData?.formData?.id;
            departmentData.companyId = 1
        }
        else {
            departmentData.companyId = 1
        }

        dispatch(departmentActions.addUpdateDepartments({ departmentData, actionName, departmentParams: departmentParams }));
        toggleModal();
    };


    return (
        <Modal
            open={isModalOpen}
            width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Department"
                ) : (
                    "Add New Department"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: currentModalData?.formData?.name,
                    subtitle: currentModalData?.formData?.subTitle,
                }}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Department Name Required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // If no value, only required validation is shown
                                                    }

                                                    if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                                                        return Promise.reject(new Error("Department Name must be a valid alphanumeric string."));
                                                    }

                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Department Name cannot exceed 30 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Department Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // Pass all validations
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="subtitle"
                                        label="SubTitle"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Department Subtitle Required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Only trigger required validation when empty
                                                    }

                                                    if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                                                        return Promise.reject(new Error("Department Subtitle must be a valid alphanumeric string."));
                                                    }

                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Department Subtitle cannot exceed 30 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Department Subtitle cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // All validations passed
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Subtitle'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateDepartmentModal;