import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setRecipes = (state, { allRecipesData }) => {
    if (!allRecipesData) return state;
    return state.merge({ allRecipesData });
}

const setRecipesById = (state, { recipesParam }) => {
    return state.merge({ recipesParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_RECIPES,
        actionFunction: setRecipes,
    },
    {
        actionType: ActionTypes.SET_RECIPES_BY_ID,
        actionFunction: setRecipesById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
