import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Switch, Select, TimePicker, Checkbox } from 'antd';
import CustomFormLabel from '../../../common/CustomFormLabel';
import dayjs from 'dayjs';

const ShiftInformationTab = (props) => {
    const { form, currentModalData, isUpdate, weekOffsOptions, fromTime, setFromTime, toTime, setToTime, isReccuringShift, setReccuringShift } = props;
    const dispatch = useDispatch();


    const [isSwitchActive, setIsSwitchActive] = useState(false);
    const [weekOffId, setWeekOffId] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(null);

    const handleFromTimeChange = (time, timeString) => {
        setFromTime(timeString)
        form.setFieldValue({ from: dayjs(time) })
        form.validateFields(["to"]);
    }

    const handleToTimeChange = (time, timeString) => {
        setToTime(timeString)
        form.setFieldValue({ to: dayjs(time) })
    }

    const handleRecurringShiftChange = (e) => {
        setReccuringShift(e.target.checked)
    }

    const handleWeekOffChange = (value) => {
        setWeekOffId(value)
        form.setFieldValue({ weekOffId: value })
    }

    const validateToTime = (_, value) => {
        if (!value || !fromTime) {
            return Promise.resolve();
        }

        const formattedToTime = dayjs(value).format("HH:mm:ss");
        if (formattedToTime <= fromTime) {
            return Promise.reject(new Error("To Time should be greater than From Time"));
        }
        return Promise.resolve();
    };

    const onSwitchChange = (checked) => {
        form.setFieldsValue({ isActive: checked })
        setIsSwitchActive(checked)
    }

    const disableShiftsBasedOnWeekOff = (day) => {
        if (weekOffId === 1 && (day === 'Sunday' || day === 'Monday')) return true;
        else if (weekOffId === 2 && (day === 'Saturday' || day === 'Sunday')) return true;
        else if (weekOffId === 3 && (day === 'Saturday' || day === 'Tuesday')) return true;
        return false;
    };

    const setWeekDaysShiftCheckActive = () => {
        currentModalData.formData.weekDaysJson.forEach(d => {
            let weekShiftsArray = [];
            if (d.isIstShift) weekShiftsArray.push('1st')
            if (d.is2ndShift) weekShiftsArray.push('2nd')
            if (d.is3rdShift) weekShiftsArray.push('3rd')
            if (d.is4rthShift) weekShiftsArray.push('4th')
            if (d.is5thShift) weekShiftsArray.push('5th')

            form.setFieldsValue({ [`shifts-${d.day}`]: weekShiftsArray });
        });
    }

    const setWeekDaysSwitchActive = () => {
        currentModalData.formData.weekDaysJson.forEach(d => {
            form.setFieldsValue({ [`switch-${d.day}`]: d.isActive });
        });
    }

    const checkAll = (day, checkedValues) => {
        debugger
        if (isAllSelected == day) {
            form.setFieldsValue({ [`shifts-${day}`]: [] });
            setIsAllSelected(null)
        }
        else if (isAllSelected != day && !checkedValues.includes('0th') && checkedValues.length < 5) {
            form.setFieldsValue({ [`shifts-${day}`]: checkedValues });
        }
        else {
            setIsAllSelected(day)
            form.setFieldsValue({ [`shifts-${day}`]: ['0th', '1st', '2nd', '3rd', '4th', '5th'] });
        }

    }

    const checkIfDisabled = (day) => {
        if (day == isAllSelected) return true;
        else false;
    }

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            setWeekOffId(currentModalData.formData.weekOffId)
            setFromTime(currentModalData.formData.from)
            setToTime(currentModalData.formData.to)
            setReccuringShift(currentModalData?.formData?.reccuringShift)
            setIsSwitchActive(currentModalData?.formData?.isActive)
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
            form.setFieldsValue({ from: dayjs(currentModalData.formData.from, 'HH:mm:ss') })
            form.setFieldsValue({ to: dayjs(currentModalData.formData.to, 'HH:mm:ss') })
            setWeekDaysSwitchActive()
            setWeekDaysShiftCheckActive()
        }
    }, [isUpdate])

    useEffect(() => {
        form.validateFields(['weekOffId'])
        .then(() => {
          console.log('weekOffId is valid');
        })
        .catch((errorInfo) => {
          console.log('weekOffId validation failed', errorInfo);
        });
    }, [])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    shiftName: currentModalData?.formData?.shiftName,
                    weekOffId: currentModalData?.formData?.weekOffId,
                }}
                style={{ marginTop: '20px' }}
            >

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="shiftName"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Shift Name"
                                                popoverkey="Shift Name"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Shift Name Required",
                                            },
                                            {
                                                max: 30,
                                                message: "Shift Name cannot exceed 30 characters.",
                                            }
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Shift Name' />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="from"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="From"
                                        popoverkey="From"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "From Time Required",
                                    },
                                ]}
                            >
                                <TimePicker
                                    onChange={handleFromTimeChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="to"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="To"
                                        popoverkey="To"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "To Time Required",
                                    },
                                    { validator: validateToTime },
                                ]}
                            >
                                <TimePicker
                                    onChange={handleToTimeChange}
                                    defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="weekOffId"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Weekoff"
                                                popoverkey="Weekoff"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Week off Required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            classNamePrefix="react-select"
                                            options={weekOffsOptions.map(s => ({ label: s.name, value: s.id }))}
                                            placeholder="Choose"
                                            className="input-select"
                                            onChange={handleWeekOffChange}
                                            showSearch
                                            filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                            notFoundContent="No results found"
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="" style={{ display: 'flex' }}>
                    <div className="col-lg-3">
                        <div className="text-left" style={{ marginLeft: '5px' }}>
                            <span style={{ fontWeight: 'bolder' }}>Days</span>
                        </div>
                    </div>

                    <div className="col-lg-9">
                        <div className="text-center">
                            <span style={{ fontWeight: 'bolder' }}>Weeks</span>
                        </div>
                    </div>
                </div>
                <hr />

                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                    <div className="" style={{ display: 'flex' }}>
                        <div className="col-lg-3">
                            <div className="" style={{ display: 'flex' }}>
                                <Form.Item
                                    name={`switch-${day}`}
                                    valuePropName="checked"
                                >
                                    <Switch size="small" disabled={!weekOffId || disableShiftsBasedOnWeekOff(day)} />
                                </Form.Item>
                                <span style={{ marginTop: '5px' }}>&nbsp;&nbsp;&nbsp;{day}</span>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="actions_addModalFormItem">
                                <Form.Item name={`shifts-${day}`}
                                >
                                    <Checkbox.Group onChange={(checkedValues) => checkAll(day, checkedValues)} value="all" disabled={!weekOffId || disableShiftsBasedOnWeekOff(day)}>
                                        <Checkbox value="0th">All</Checkbox>
                                        <Checkbox disabled={checkIfDisabled(day)} value="1st">1st</Checkbox>
                                        <Checkbox disabled={checkIfDisabled(day)} value="2nd">2nd</Checkbox>
                                        <Checkbox disabled={checkIfDisabled(day)} value="3rd">3rd</Checkbox>
                                        <Checkbox disabled={checkIfDisabled(day)} value="4th">4th</Checkbox>
                                        <Checkbox disabled={checkIfDisabled(day)} value="5th">5th</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                ))}

                <div className="">
                    <div className="">
                        <div className="">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="reccuringShift"
                                        className="actions_addModalFormItem"
                                    >
                                        <Checkbox onChange={handleRecurringShiftChange} checked={isReccuringShift}>Recurring Shift</Checkbox>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontWeight: 'bold' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                            <Switch checked={isSwitchActive} onChange={onSwitchChange} size='small' />
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ShiftInformationTab;
