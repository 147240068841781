import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as inventorySelectors from '../InventorySelectors';
import warrantyActions from '../../../core/Services/inventory/warranty/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, InputNumber, Modal } from 'antd';
import { Row, Col, Form, Input, Select, Upload, Image, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../inventory.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import { string } from 'prop-types';

const AddWarrentyModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, warrantyParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const period = [
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ]

  const handleRecord = (actionName, warrentyData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      warrentyData.id = currentModalData.formData.id;
      warrentyData.companyId = currentModalData.formData.companyId;
    } else {
      warrentyData.companyId = 0;
    }
    warrentyData.isActive = warrentyData.isActive ? true : false;
    dispatch(warrantyActions.addUpdateWarranties({ warrentyData, actionName, warrantyParams: warrantyParams }));
    toggleModal();
  };

  return (

    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="warranty.inventory.form.modal.title.update" />
        ) : (
          <IntlMessages id="warranty.inventory.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="warranty.inventory.form.modal.update" />
        ) : (
          <IntlMessages id="warranty.inventory.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="warranty.inventory.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="categoryForm"
        className='categoryActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
          warrantyName: currentModalData.formData.warrantyName,
          warrantyDuration: currentModalData.formData.warrantyDuration,
          warrantyPeriod: currentModalData.formData.warrantyPeriod,
          warrantyDescription: currentModalData.formData.warrantyDescription,
          isActive: currentModalData.formData.isActive,
        }}
      >

        <div className='row'>

          <div className="col-lg-12">
            <div className="input-blocks">
              <Form.Item
                name="warrantyName"
                className='actions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warranty.inventory.form.warranty.label.name"
                    popoverkey="warranty.inventory.form.label.popover.warranty.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="warranty.inventory.modal.validation.name" />,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="warrantyDuration"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="warranty.inventory.form.warranty.label.duration"
                      popoverkey="warranty.inventory.form.label.popover.warranty.duration"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="warranty.inventory.modal.validation.duration" />,
                    },
                    {
                      type: 'number',
                      min: 0,
                      message: 'The warranty duration must be a valid number',
                    },
                  ]}
                >
                  <InputNumber min={0}  className="input_number"/>
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="warrantyPeriod"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="warranty.inventory.form.warranty.label.period"
                      popoverkey="warranty.inventory.form.label.popover.warranty.period"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="warranty.inventory.modal.validation.period" />,
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    classNamePrefix="react-select"
                    options={period.map(f => ({ label: f.label, value: f.value }))}
                    placeholder={CONSTANT_VARIABLES.CATEGORY_SELCT}
                    className="input-select"
                    showSearch
                    filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="input-blocks">
              <Form.Item
                name="warrantyDescription"
                className='actions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warranty.inventory.form.warranty.label.warranty.description"
                    popoverkey="warranty.inventory.form.label.popover.description"
                  />
                }
              >
                <TextArea />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-12">
            {/* <div className="input-blocks"> */}
            <div className="addCategory_form--status_area">
              <span className="status-label"> <IntlMessages id="category.inventory.modal.status" /></span>
              <Form.Item
                name="isActive"
                className='actions_addModalFormItem'
                valuePropName="checked"
              >
                <Switch size="small" />
              </Form.Item>
              {/* </div> */}
            </div>
          </div>
        </div>
      </Form>

    </Modal >
 
)
}

export default AddWarrentyModal
