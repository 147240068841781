import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock, DollarSign, MinusCircle, Briefcase, BarChart, Watch } from "feather-icons-react/build/IconComponents";

import shiftActions from '../../../core/Services/hrm/shifts/actions'
import ShiftInformationTab from './components/ShiftInformationTab';
import BreakTimingsTab from './components/BreakTimingsTab';


const AddUpdateShiftModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, shiftParams, weekOffsOptions } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("1");
    const [editorData, setEditorData] = useState();

    const [fromTime, setFromTime] = useState(null)
    const [toTime, setToTime] = useState(null)
    
    const [morningBreakFromTime, setMorningBreakFromTime] = useState(null)
    const [morningBreakToTime, setMorningBreakToTime] = useState(null)

    
    const [lunchBreakFromTime, setLunchBreakFromTime] = useState(null)
    const [lunchBreakToTime, setLunchBreakToTime] = useState(null)

    
    const [eveningBreakFromTime, setEveningBreakFromTime] = useState(null)
    const [eveningBreakToTime, setEveningBreakToTime] = useState(null)


    const [isReccuringShift, setReccuringShift] = useState(false)

    const getWeekDaysJson = (shiftData) => {
        const weekDaysJson = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
            const isActive = shiftData[`switch-${day}`] || false;
            const selectedShifts = shiftData[`shifts-${day}`] || [];
      
            return {
              day,
              isActive,
              isIstShift: selectedShifts.includes("1st"),
              is2ndShift: selectedShifts.includes("2nd"),
              is3rdShift: selectedShifts.includes("3rd"),
              is4rthShift: selectedShifts.includes("4th"),
              is5thShift: selectedShifts.includes("5th"),
            };
          });

          return weekDaysJson;
    }

    const getShiftRecord = (shiftData) => {
        let newShiftObj = {}
        newShiftObj.shiftName = shiftData.shiftName;
        newShiftObj.from = fromTime;
        newShiftObj.to = toTime;
        newShiftObj.weekOffId = shiftData.weekOffId;
        newShiftObj.weekDaysJson = getWeekDaysJson(shiftData);
        newShiftObj.reccuringShift = isReccuringShift;
        newShiftObj.isActive = shiftData.isActive;
        newShiftObj.morningBreakFrom = morningBreakFromTime;
        newShiftObj.morningBreakTo = morningBreakToTime;
        newShiftObj.lunchBreakFrom = lunchBreakFromTime;
        newShiftObj.lunchBreakTo = lunchBreakToTime;
        newShiftObj.eveningBreakFrom = eveningBreakFromTime;
        newShiftObj.eveningBreakTo = eveningBreakToTime;
        newShiftObj.description = editorData;

        return newShiftObj
    }

    const handleRecord = (actionName, shiftData) => {
        debugger
        let newShiftData = getShiftRecord(shiftData);
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            newShiftData.id = currentModalData?.formData?.id
            newShiftData.companyId = 1
        }
        else {
            newShiftData.companyId = 1
        }

        dispatch(shiftActions.addUpdateShifts({ shiftData: newShiftData, actionName, shiftParams: shiftParams }));
        toggleModal();
    };

    const handleNext = () => {
        form.validateFields()
            .then(() => {
                if (activeTab === "1") {
                    setActiveTab("2");
                }
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    const handleTabChange = (nextTabKey) => {
        form.validateFields()
            .then(() => {
                setActiveTab(nextTabKey);
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    return (
        <Modal
            open={isModalOpen}
            width={650}
            zIndex={999999}
            onCancel={() => toggleModal(true)}
            footer={[
                activeTab !== "2" ? (
                    <Button key="next" type="primary" onClick={handleNext} style={{ marginRight: '5px' }}>
                        <IntlMessages id="product.inventory.form.modal.next" />
                    </Button>
                ) : (
                    <Button
                        key="submit"
                        type="primary"
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            form.validateFields()
                                .then(values => {
                                    handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                                })
                                .catch(info => {
                                    console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                                });
                        }}
                    >
                        {isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
                    </Button>
                ),
                <Button color='orange' key="cancel" onClick={() => toggleModal(true)}>
                    <IntlMessages id="product.inventory.form.modal.cancelText" />
                </Button>,
            ]}
            title={isUpdate ? "Update Shift" : "Add New Shift"}
            okText={isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="product.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <div className="card-body add-product pb-0">
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab={<span><Info className="add-info" /> Shift Information</span>} key="1">
                        <ShiftInformationTab form={form} currentModalData={currentModalData} isUpdate={isUpdate} weekOffsOptions={weekOffsOptions} fromTime = {fromTime} setFromTime = {setFromTime} toTime = {toTime} setToTime = {setToTime} isReccuringShift = {isReccuringShift} setReccuringShift = {setReccuringShift} />
                    </TabPane>
                    <TabPane tab={<span><Watch className="add-info" /> Break Timings</span>} key="2">
                        <BreakTimingsTab form={form} currentModalData={currentModalData} isUpdate={isUpdate} morningBreakFromTime={morningBreakFromTime} setMorningBreakFromTime = {setMorningBreakFromTime} morningBreakToTime={morningBreakToTime} setMorningBreakToTime = {setMorningBreakToTime} lunchBreakFromTime={lunchBreakFromTime} setLunchBreakFromTime = {setLunchBreakFromTime} lunchBreakToTime={lunchBreakToTime} setLunchBreakToTime = {setLunchBreakToTime} eveningBreakFromTime={eveningBreakFromTime} setEveningBreakFromTime = {setEveningBreakFromTime} eveningBreakToTime={eveningBreakToTime} setEveningBreakToTime = {setEveningBreakToTime} editorData = {editorData} setEditorData = {setEditorData}/>
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default AddUpdateShiftModal;