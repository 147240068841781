import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ChevronUp,
  Eye,
  FileText,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Box, Filter, Sliders, StopCircle, User } from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import AddQuotation from "./addquotation";
import EditQuotation from "../../../core/modals/sales/editquotation";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import quotationAction from "../../../core/Services/sales/salequotations/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as saleSelector from '../saleSelectors.js';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const QuotationList = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const data = useSelector((state) => state.toggle_header); 
  const [quotationParams, setquotationParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isQuotationModalOpen, setQuotationModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(quotationParams, 1000);

  const isLoading = useSelector(state => saleSelector.setLoader(state));
  const QuotationData = useSelector(state => saleSelector.setSaleQuotations(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = QuotationData ? QuotationData.toJS() : [];

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleUserModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(quotationAction.fetchAllSaleQuotations({ quotationParams: debouncedText }));
  }, [dispatch]);

  const toggleQuotationModal = (value) => {
    setQuotationModalOpen();
  }
  
  

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(quotationAction.deleteSaleQuotationById({ salequotationParam: row }));
      } else {
        MySwal.close();
      }
    });
  };
  const handleDataIntoExcel = (filename = 'Quotations.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Sr.', 'supplierName', 'quotationNo', 'quotationDate', 'status', 'grandTotal', 'paid', 'due']];
  
  const tableData = dataSource.map((item, index) => [
    index + 1,
    item.supplierName,
    item.quotationNo,
    item.quotationDate,
    item.status,
    item.grandTotal,
    item.paid,
    item.due,
  ]);
  
    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Quotations.pdf');
  };

  const handleRefresh = () => {
    dispatch(quotationAction.fetchAllSaleQuotations({ quotationParams }));
  }

  const handleModal = (quotationData, actionName) => {

    const quotationModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? quotationData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(quotationModalObj);
    setQuotationModalOpen(true);
  };

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const products = [
    { value: "Choose product", label: "Choose product" },
    { value: "Bold V3.2", label: "Bold V3.2" },
    { value: "Apple Series 5 Watch", label: "Apple Series 5 Watch" },
  ];
  const status = [
    { value: "Choose product", label: "Choose Status" },
    { value: "Ordered", label: "Ordered" },
    { value: "Sent", label: "Sent" },
  ];
  const customername = [
    { value: "Choose Custmer", label: "Choose Customer" },
    { value: "walk-in-customer", label: "walk-in-customer" },
    { value: "John Smith", label: "John Smith" },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const columns = [
    {
      title: "Quotation No",
      dataIndex: "saleQuotationNo",
      sorter: (a, b) => a.saleQuotationNo.length - b.saleQuotationNo.length,
    },
    {
      title: "Other Tax",
      dataIndex: "otherTax",
      sorter: (a, b) => a.customername.length - b.customername.length,
    },
    {
      title: "Disscount",
      dataIndex: "disscount",
      sorter: (a, b) => a.disscount.length - b.disscount.length,
    },
    {
      title: "Sale Date",
      dataIndex: "saleQuotationDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.saleQuotationDate.length - b.saleQuotationDate.length,
    },
    
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === "Sent" && (
            <span className="badges status-badge">{text}</span>
          )}
          {text === "Ordered" && (
            <span className="badges order-badge">{text}</span>
          )}
          {text === "Pending" && (
            <span className="badges unstatus-badge">{text}</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Grand Total ($)",
      dataIndex: "grandtotal",
      sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-quotations"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];


  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Quotation List</h4>
                <h6>Manage Your Quotation</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
            <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="purchases.quotations.table.button.addnewquotation" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <div className="d-flex align-items-center">
                    <Link
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                    >
                      <Filter
                        className="filter-icon"
                        onClick={toggleFilterVisibility}
                      />
                      <span onClick={toggleFilterVisibility}>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Box className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={products}
                          placeholder="Choose Product"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={status}
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={customername}
                          placeholder="Choose Custmer"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <div className="input-groupicon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Reference"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isQuotationModalOpen ? (
              <AddQuotation
                currentModalData={currentModalData}
                isModalOpen={isQuotationModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleQuotationModal}
              />
            ) : null}
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} isLoading={isLoading} dataSource={dataSource} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default QuotationList;
