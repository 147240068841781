// /* eslint-disable no-unused-vars */
// import React from  'react';
// import { useState } from 'react';
// import { TagsInput } from "react-tag-input-component";



// const ReactTagsInput =()=> {

//   const [tags, setTags] = useState(["JPG ","GIF","PNG "]);
//         return (
//             <div>
//                   <TagsInput
//                   tags={tags}
//                   value={tags}

//             //    placeHolder="Meta Keywords"
//                   />
//             </div>
//         )
//     }

// export default ReactTagsInput;

import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";

const ReactTagsInput = ({ value = [], onChange }) => {
  const [tags, setTags] = useState([]); // Start with an empty tag list
  const exampleFormats = ["JPG", "GIF", "PNG"]; // Example formats

  // UseEffect to update the tags when the value (backend data) changes
  useEffect(() => {
    if (value.length > 0) {
      setTags(value); // Set the actual allowed file types from the backend
    }
  }, [value]);

  const handleChange = (newTags) => {
    setTags(newTags);
    onChange(newTags); // Pass the new tags back to the parent component
  };

  return (
    <div>
      {/* Display example formats */}
      <div>
        <p className="mb-2 text-gray">Example formats: {exampleFormats.join(", ")}</p>
      </div>

      {/* Tags input component for actual allowed file types */}
      <TagsInput
        value={tags}
        onChange={handleChange}
        inputProps={{ placeholder: "Add file types" }}
      />
    </div>
  );
};

export default ReactTagsInput;

