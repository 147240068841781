import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPrinterSettings = (state, { allPrinterSettingData }) => {
    if (!allPrinterSettingData) return state;
    return state.merge({ allPrinterSettingData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_PRINTERSETTING,
        actionFunction: setPrinterSettings,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
