export const ActionTypes = {
  ADD_UPDATE_DEBIT_NOTE: 'services/purchases/debitNote/add_update_debitNote',
  FETCH_DEBIT_NOTE: 'services/purchases/debitNote/fetch_debitNotes',
  SET_DEBIT_NOTES: 'services/purchases/debitNote/set_debitNotes',
  DELETE_DEBIT_NOTE: 'services/purchases/debitNote/delete_debitNote',
  GET_DEBIT_NOTE_BY_ID: 'services/purchases/debitNote/get_debitNote_by_id',
  SET_DEBIT_NOTE_BY_ID: 'services/purchases/debitNote/set_debitNote_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/debitNote/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/debitNote/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllDebitNotes = ({ debitNoteParams }) => ({
  debitNoteParams,
  type: ActionTypes.FETCH_DEBIT_NOTE,
})

const setDebitNotes = ({ allDebitNoteData }) => ({
  allDebitNoteData,
  type: ActionTypes.SET_DEBIT_NOTES,
})

const deleteDebitNoteById = ({ debitNoteParam }) => ({
  debitNoteParam,
  type: ActionTypes.DELETE_DEBIT_NOTE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateDebitNote = ({ debitNoteData, actionName, UserParams }) => ({
  debitNoteData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_DEBIT_NOTE,
})

const getDebitNoteById = ({ debitNoteParam }) => ({
  debitNoteParam,
  type: ActionTypes.GET_DEBIT_NOTE_BY_ID,
})

const setDebitNoteById = ({ debitNoteParam }) => ({
  debitNoteParam,
  type: ActionTypes.SET_DEBIT_NOTE_BY_ID,
})

export default {
  addUpdateDebitNote,
  fetchAllDebitNotes,
  setDebitNotes,
  deleteDebitNoteById,
  getDebitNoteById,
  setDebitNoteById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
