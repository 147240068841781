export const ActionTypes = {
  ADD_UPDATE_PAYMENT_VOUCHER: 'services/purchases/paymentVoucher/add_update_paymentVoucher',
  FETCH_PAYMENT_VOUCHER: 'services/purchases/paymentVoucher/fetch_paymentVouchers',
  SET_PAYMENT_VOUCHERS: 'services/purchases/paymentVoucher/set_paymentVouchers',
  DELETE_PAYMENT_VOUCHER: 'services/purchases/paymentVoucher/delete_paymentVoucher',
  GET_PAYMENT_VOUCHER_BY_ID: 'services/purchases/paymentVoucher/get_paymentVoucher_by_id',
  SET_PAYMENT_VOUCHER_BY_ID: 'services/purchases/paymentVoucher/set_paymentVoucher_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/paymentVoucher/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/paymentVoucher/get_quotation_no_by_id',
  GET_INVOICE_BY_ID: 'services/purchases/paymentVoucher/get_invoice_by_id',
  SET_INVOICE_BY_ID: 'services/purchases/paymentVoucher/set_invoice_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllPaymentVouchers = ({ paymentVoucherParams }) => ({
  paymentVoucherParams,
  type: ActionTypes.FETCH_PAYMENT_VOUCHER,
})

const setPaymentVouchers = ({ allPaymentVoucherData }) => ({
  allPaymentVoucherData,
  type: ActionTypes.SET_PAYMENT_VOUCHERS,
})

const deletePaymentVoucher = ({ paymentVoucherParam }) => ({
  paymentVoucherParam,
  type: ActionTypes.DELETE_PAYMENT_VOUCHER,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdatePaymentVoucher = ({ paymentVoucherData, actionName, paymentVoucherParams }) => ({
  paymentVoucherData,
  actionName,
  paymentVoucherParams,
  type: ActionTypes.ADD_UPDATE_PAYMENT_VOUCHER,
})

const getPaymentVoucherById = ({ paymentVoucherParam }) => ({
  paymentVoucherParam,
  type: ActionTypes.GET_PAYMENT_VOUCHER_BY_ID,
})

const setPaymentVoucherById = ({ paymentVoucherParam }) => ({
  paymentVoucherParam,
  type: ActionTypes.SET_PAYMENT_VOUCHER_BY_ID,
})

const getInvoiceByNo = ({ invoiceParam }) => ({
  invoiceParam,
  type: ActionTypes.GET_INVOICE_BY_ID,
})

const setInvoiceByNo = ({invoiceData }) => ({
 invoiceData,
  type: ActionTypes.SET_INVOICE_BY_ID,
})
export default {
  addUpdatePaymentVoucher,
  fetchAllPaymentVouchers,
  setPaymentVouchers,
  deletePaymentVoucher,
  getPaymentVoucherById,
  setPaymentVoucherById,
  setQuotationNoById,
  getQuotationNoById,
  getInvoiceByNo,
  setInvoiceByNo,
  setLoader
};
