export const ActionTypes = {
    ADD_UPDATE_VAULT: 'services/stock/vaults/add_update_vault',
    FETCH_VAULT: 'services/stock/vaults/fetch_vaults',
    SET_VAULTS: 'services/stock/vaults/set_vaults',
    SET_LOADER: 'services/stock/vaults/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllVaults = ({ vaultParams }) => ({
    vaultParams,
    type: ActionTypes.FETCH_VAULT,
})

const setVaults = ({ allVaultData }) => ({
    allVaultData,
    type: ActionTypes.SET_VAULTS,
})

const addUpdateVaults = ({ vaultData, actionName, vaultParams }) => ({
    vaultData,
    actionName,
    vaultParams,
    type: ActionTypes.ADD_UPDATE_VAULT,
})

export default {
    addUpdateVaults,
    fetchAllVaults,
    setVaults,
    setLoader
};
