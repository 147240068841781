import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../../core/redux/action";
import Select from "react-select";
import { useDebounce } from 'use-debounce';
import AddSubcategoryModal from "./addsubcategory";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import subCategoryAction from "../../../core/Services/inventory/subCategory/actions.js";
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as inventorySelectors from '../InventorySelectors';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const SubCategories = () => {
  const dispatch = useDispatch();
  const [subCategoryParams, setsubCategoryParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isSubCategoryModalOpen, setSubCategoryModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(subCategoryParams, 1000);
  const data = useSelector((state) => state.toggle_header);
  const isLoading = useSelector(state => inventorySelectors.inventorysubcategoryLoader(state));
  const subCategoryData = useSelector(state => inventorySelectors.setSubcategories(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = subCategoryData ? subCategoryData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];;

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleSubCategoryModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(subCategoryAction.fetchAllSubcategories({ subcategoryParams: debouncedText }));
  }, [dispatch]);

  const toggleSubCategoryModal = (value) => {
    setSubCategoryModalOpen();
  }

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const columns = [
    {
      title:<IntlMessages id="inventorysubcategories.table.column.category" />,
      dataIndex: "subCategoryName",
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title:<IntlMessages id="inventorysubcategories.table.column.parentcategory" />,
      dataIndex: "categoryName",
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },
    {
      title:<IntlMessages id="inventorysubcategories.table.column.categorycode" />,
      dataIndex: "subCategoryCode",
      sorter: (a, b) => a.categorycode.length - b.categorycode.length,
    },
    {
      title:<IntlMessages id="inventorysubcategories.table.column.description" />,
      dataIndex: "subCategoryDescription",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    // {
    //   title:<IntlMessages id="inventorysubcategories.table.column.createdby" />,
    //   dataIndex: "createdby",
    //   sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },
    {
      title: <IntlMessages id="inventorysubcategories.table.column.status" />,
      dataIndex: "isActive",
      render: (text, row) => {
        return (
          <div>
            <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>{text ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
      sorter: (a, b) => a.isActive.length - b.isActive.length,
    },

    {
      title:<IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-categories"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(subCategoryAction.deleteSubcategories({ subcategoryParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const handleDataIntoExcel = (filename = 'subCategories.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [
      ['id', 'subCategoryName', 'subCategoryCode', 'subCategoryDescription', 'isActive']
    ];

    const tableData = dataSource.map((item, index) => [
      item.id,
      item.subCategoryName,
      item.subCategoryCode,
      item.subCategoryDescription,
      item.isActive
    ]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('subCategories.pdf');
  };

  const handleRefresh = () => {
    dispatch(subCategoryAction.fetchAllSubcategories({ subcategoryParams: debouncedText }));
  }

  const handleModal = (subCategoryData, actionName) => {

    const subCategoryModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? subCategoryData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(subCategoryModalObj);
    setSubCategoryModalOpen(true);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="inventorysubcategories.table.heading.inventorysubcategories" /></h4>
                <h6><IntlMessages id="inventorysubcategories.table.label.manageinventorysubcategories" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="inventorysubcategories.table.button.addsubcategory" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >

              </div>
              {isSubCategoryModalOpen ? (
                <AddSubcategoryModal
                  currentModalData={currentModalData}
                  isModalOpen={isSubCategoryModalOpen}
                  isUpdate={currentModalData.formData.id ? true : false}
                  toggleModal={toggleSubCategoryModal}
                />
              ) : null}
              {/* /Filter */}
              <div className="table-responsive">
                <Table isLoading={isLoading} columns={columns} dataSource={tableData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default SubCategories;
