import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import saleReturnAction from '../../../core/Services/sales/saleReturn/actions.js';
import saleOrderAction from '../../../core/Services/sales/salesOrder/actions.js';
import commonActions from '../../../core/Services/common/actions.js';
import * as commonSelectors from '../../commonSelectors';
import { Modal, Form, Select, InputNumber } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import * as saleSelectors from '../saleSelectors';

const AddSalesReturns = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, saleReturnParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const saleOrderData = useSelector(state => commonSelectors.setAllSaleOrder(state));
  const saleOrderdataSource = saleOrderData ? saleOrderData.toJS() : [];
  const saleOrderIdData = useSelector(state => saleSelectors.setSaleOrderById(state));
  const saleOrderByIdData = saleOrderIdData ? saleOrderIdData.toJS() : [];
  const saleOrderByIdDatasource = saleOrderByIdData.data;
  console.log(saleOrderByIdData);
  useEffect(() => {
    dispatch(commonActions.getAllSaleOrder());
  }, [dispatch])

  useEffect(() => {
    if (saleOrderByIdDatasource) {
      form.setFieldsValue({
        otherTax: saleOrderByIdDatasource?.otherTax,
        disscount: saleOrderByIdDatasource?.disscount,
        shippingCost: saleOrderByIdDatasource?.shippingCost,
      })
    }
  }, [saleOrderByIdData]);

  const handleSaleOrder = (value) => {
    dispatch(saleOrderAction.getSaleOrderById({ saleOrderParamId: value }));

  }

  const handleCheckbox = (value, check) => {

  }
  const handleRecord = (actionName, saleReturnData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      saleReturnData.id = currentModalData.formData.id;
      saleReturnData.companyId = currentModalData.formData.companyId;
      saleReturnData.notesHtml = editorData;
    } else {
      saleReturnData.companyId = 0;
      saleReturnData.saleReturnDetails = selectedDate;
    }

    dispatch(saleReturnAction.addUpdateSaleReturn({ saleReturnData, actionName, saleReturnParams: saleReturnParams }));
    toggleModal();
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      width={1140}
      zIndex={99999}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="sale.return.form.modal.title.update" />
        ) : (
          <IntlMessages id="sale.return.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="sale.receive.form.modal.update" />
        ) : (
          <IntlMessages id="sale.receive.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="sale.receive.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="categoryForm"
        className='categoryActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
          saleOrderId: currentModalData.formData.saleOrderId,
          otherTax: currentModalData.formData.otherTax,
          disscount: currentModalData.formData.disscount,
          shippingCost: currentModalData.formData.shippingCost,
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="input-blocks">
              <Form.Item
                name="saleOrderId"
                className='actions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="sale.return.form.sale.order.label.sale.Order"
                    popoverkey="sale.return.form.label.popover.sale.Order"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="sale.return.modal.validation.sale.Order" />,
                  },
                ]}
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={saleOrderdataSource.map(f => ({ label: f.name, value: f.id }))}
                  placeholder="Choose"
                  className="input-select"
                  onChange={handleSaleOrder}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent="No results found"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                className='actions_addModalFormItem'
                name="otherTax"
                initialValue={0}
                label={
                  <CustomFormLabel
                    labelkey="sale.return.form.purchase.label.order.tax"
                    popoverkey="sale.return.form.label.popover.purchase.order.tax"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="sale.return.modal.validation.order.tax" />,
                  },
                ]}
              >
                 <InputNumber min={0} className="input_number" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                className='actions_addModalFormItem'
                name="disscount"
                initialValue={0}
                label={
                  <CustomFormLabel
                    labelkey="sale.return.form.purchase.label.disscount"
                    popoverkey="sale.return.form.label.popover.purchase.disscount"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="sale.return.modal.validation.disscount" />,
                  },
                ]}
              >
                 <InputNumber min={0} className="input_number" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                className='actions_addModalFormItem'
                name="shippingCost"
                initialValue={0}
                label={
                  <CustomFormLabel
                    labelkey="sale.return.form.purchase.label.shipping"
                    popoverkey="sale.return.form.label.popover.purchase.shipping"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="sale.return.modal.validation.shipping" />,
                  },
                ]}
              >
                 <InputNumber min={0} className="input_number" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="modal-body-table">
            <div className="table-responsive">
              <table className="table  datanew">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Sale Quantity</th>
                    <th>Return Quantity</th>
                    <th>Discount</th>
                    <th>Tax %</th>
                    <th>Unit Cost</th>
                    <th>Total Cost</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{form.getFieldValue('saleOrderId') ? (isUpdate ? currentModalData.formData?.saleOrderDetails?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-5">{item.productName}</td>
                    <td className="p-5">{item.quantity}</td>
                    <td className="p-5">{item.disscount}</td>
                    <td className="p-5">{item.taxPercentage}</td>
                    <td className="p-5">{item.taxAmount}</td>
                    <td className="p-5">{item.unitPrice}</td>
                    <td className="p-5">{item.totalCost}</td>
                    <td className="p-5"></td>
                  </tr>
                )) : saleOrderByIdDatasource && saleOrderByIdDatasource.saleOrderDetails?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-5">{item.productName}</td>
                    <td className="p-5">{item.quantity}</td>
                    <td className="p-5">{item.disscount}</td>
                    <td className="p-5">{item.taxPercentage}</td>
                    <td className="p-5">{item.taxAmount}</td>
                    <td className="p-5">{item.unitCost}</td>
                    <td className="p-5">{item.totalCost}</td>
                    <td className="p-5">
                      <input type="checkbox" onChange={handleCheckbox} />
                    </td>
                  </tr>
                ))) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Form>
    </Modal >
  );
};

export default AddSalesReturns;
