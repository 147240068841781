import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../heads/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateHeads({ headData, actionName, headParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Purchases/edit-head`,
        data: headData,
      });
    } else {
      yield call(api.post, {
        route: '/Purchases/create-head',
        data: headData,
      });
    }
    
    yield put(Actions.fetchAllHeads({ headParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.HEAD_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.HEAD_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllHeads({ headParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch heads
    const { data } = yield call(api.get, {
      route: '/Purchases/all-heads',
      params: headParams,
    });

    // Store the fetched data in Redux store
    const allHeadData = data;
    yield put(Actions.setHeads({ allHeadData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteHeadById({ headParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Purchases/delete-head/` + headParam.id,
    });
    yield put(Actions.fetchAllHeads({headParam}));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.HEAD_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

const getImmutableData = (data) => {
  const immutableJson = Immutable.fromJS(data);
  return immutableJson.get ? immutableJson.get('data') : immutableJson;
};

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_HEAD, fetchAllHeads),
    takeLatest(ActionTypes.ADD_UPDATE_HEAD, addUpdateHeads),
    takeLatest(ActionTypes.DELETE_HEADS, deleteHeadById)
  ]);
}
