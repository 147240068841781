import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../companysetting/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addCompanySetting({ companySettingData, actionName, companySettingParams }) {
  debugger
  try {
    // Upload Logo
    if (companySettingData.logoImage) {
      const file = companySettingData.logoImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete companySettingData.logoImage;
      if (imageUrl) {
        companySettingData.logoUrl = imageUrl.url;
      }
    }

    // Upload Icon
    if (companySettingData.iconImage) {
      const file = companySettingData.iconImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete companySettingData.iconImage;
      if (imageUrl) {
        companySettingData.iconUrl = imageUrl.url;
      }
    }

    // Upload FavIcon
    if (companySettingData.favIconImage) {
      const file = companySettingData.favIconImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete companySettingData.favIconImage;
      if (imageUrl) {
        companySettingData.faviconUrl = imageUrl.url;
      }
    }

    // Upload Dark Logo
    if (companySettingData.darkIconImage) {
      const file = companySettingData.darkIconImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete companySettingData.darkIconImage;
      if (imageUrl) {
        companySettingData.darkLogoUrl = imageUrl.url;
      }
    }

    if (actionName === 'Add') {
      yield call(api.post, {
        route: `/Settings/create-companysetting`,
        data: companySettingData,
      });
    }
   
    yield put(Actions.fetchAllCompanySettings({ companySettingParams }));
    notification('success', CONSTANT_VARIABLES.COMPANYSETTING_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllCompanySettings({ companySettingParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-companysettings',
      params: companySettingParams,
    });

    const allCompanySettingData = data;
    yield put(Actions.setCompanySettings({ allCompanySettingData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

function* uploadLogoImage({ logoImage }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const file = logoImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    const uploadLogoImageUrl = data;
    yield put(Actions.setLogoImage({ uploadLogoImageUrl }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

function* uploadIconImage({ iconImage }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const file = iconImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    const uploadIconImageUrl = data;
    yield put(Actions.setIconImage({ uploadIconImageUrl }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

function* uploadFavIconImage({ favImage }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const file = favImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    const uploadFavImageUrl = data;
    yield put(Actions.setFavIconImage({ uploadFavImageUrl }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

function* uploadDarkLogoImage({ darkImage }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const file = darkImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    const uploadDarkImageUrl = data;
    yield put(Actions.setDarkLogoImage({ uploadDarkImageUrl }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_COMPANYSETTING, fetchAllCompanySettings),
    takeLatest(ActionTypes.ADD_COMPANYSETTING, addCompanySetting),
    takeLatest(ActionTypes.UPLOAD_LOGO_IMAGE, uploadLogoImage),
    takeLatest(ActionTypes.UPLOAD_ICON_IMAGE, uploadIconImage),
    takeLatest(ActionTypes.UPLOAD_FAV_IMAGE, uploadFavIconImage),
    takeLatest(ActionTypes.UPLOAD_DARK_IMAGE, uploadDarkLogoImage),
  ]);
}
