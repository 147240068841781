import React, { useState, useEffect } from 'react';
import { Modal, Form, InputNumber, Select } from "antd";
import TextEditor from "../../inventory/texteditor";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import purchaseInvoiceActions from '../../../core/Services/purchases/paymentVoucher/actions';
import commonAction from "../../../core/Services/common/actions";
import * as purchasesSelector from '../purchasesSelector';
import * as commonSelector from '../../commonSelectors';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../../../feature-module/common/commonStyle.css';
import "../purchases.css";

const AddPaymentVoucharModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, paymentVoucherParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedVoucherDate, setSelectedVoucherDate] = useState(null);
  const [selectedVoucherDueDate, setSelectedVoucherDueDate] = useState(null);
  const allInvoiceData = useSelector(state => commonSelector.setAllInvoicesData(state));
  const invoiceNos = allInvoiceData ? allInvoiceData.toJS() : [];
  const inoviceData = useSelector(state => purchasesSelector.setInvoiceByNo(state));
  const invoice = inoviceData ? inoviceData.toJS().data : {};
  const [editorData, setEditorData] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  useEffect(() => {
    dispatch(commonAction.getAllInvoicesData());
  }, [dispatch]);

  useEffect(() => {
    if (!isUpdate) {
      setInvoiceDetails(invoice.purchaseRecievedDetails || []);
    }
  }, [invoice, isUpdate]);

  useEffect(() => {
    if (isUpdate && currentModalData) {
      setSelectedVoucherDate(currentModalData.formData.voucharDate ? new Date(currentModalData.formData.voucharDate) : null);
      setSelectedVoucherDueDate(currentModalData.formData.voucharDueDate ? new Date(currentModalData.formData.voucharDueDate) : null);

      setInvoiceDetails(currentModalData.formData?.purchaseRecievedDetails || []);
      let editorFetchedData = currentModalData.formData?.notesHtml.replace(/""/g, '');
      setEditorData(editorFetchedData);
    }
  }, [currentModalData, isUpdate]);

  const handleDateChange = (date) => {
    setSelectedVoucherDate(date);
    form.setFieldsValue({ voucharDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleVoucherDueDateChange = (date) => {
    setSelectedVoucherDueDate(date);
    form.setFieldsValue({ voucharDueDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleRecord = (actionName, paymentVoucherData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      paymentVoucherData.id = currentModalData.formData?.id;
      paymentVoucherData.notesHtml = editorData;
      paymentVoucherData.companyId = currentModalData.formData?.companyId;
      paymentVoucherData.purchaseInvoiceId = currentModalData.formData?.purchaseInvoiceId;
    } else {
      paymentVoucherData.companyId = 0;
      paymentVoucherData.purchaseInvoiceId = invoice.id;
      paymentVoucherData.notesHtml = editorData;
    }
    dispatch(purchaseInvoiceActions.addUpdatePaymentVoucher({ paymentVoucherData, actionName, paymentVoucherParams }));
    toggleModal();
  };

  const handleChangeInvoice = (e) => {
    dispatch(purchaseInvoiceActions.getInvoiceByNo({ invoiceParam: e }));
  };

  const TotalCost = invoice?.purchaseRecievedDetails?.reduce((acc, item) => acc + item.totalCost, 0) || 0;

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    let stringifyData = `${data}`
    setEditorData(stringifyData);
  };

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="purchases.payment.voucher.form.modal.title.update" />
        ) : (
          <IntlMessages id="purchases.payment.voucher.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          purchaseInvoiceId: currentModalData.formData?.purchaseInvoiceId,
          voucharDate: currentModalData.formData ? moment(currentModalData.formData.voucharDate).format('YYYY-MM-DD') : null,
          voucharDueDate: currentModalData.formData ? moment(currentModalData.formData.voucharDueDate).format('YYYY-MM-DD') : null,
          due: currentModalData.formData?.due,
          paid: currentModalData.formData?.paid,
        }}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="purchaseInvoiceId"
                    className='actions_addModalFormItem'
                    label={
                      <CustomFormLabel
                        labelkey="purchases.payment.voucher.form.modal.select.bill"
                        popoverkey="purchases.payment.voucher.form.modal.select.bill"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.payment.voucher.modal.validation.product.name" />,
                      },
                    ]}
                  >
                    <Select
                      options={invoiceNos.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      onChange={handleChangeInvoice}
                      className='input-select'
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent={<IntlMessages id="purchases.payment.voucher.form.modal.not.found" />}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="paid"
                  className="actions_addModalFormItem"
                  label={
                    <CustomFormLabel
                      labelkey="purchases.payment.voucher.form.modal.paid.bill" 
                      popoverkey="purchases.payment.voucher.form.modal.paid.bill" 
                    />
                  }
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: <IntlMessages id="purchases.payment.voucher.form.modal.validation.paid.bill" />,
                    },
                  ]}

                >
                  <InputNumber min={0} className="input_number" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="due"
                  className="actions_addModalFormItem"
                  label={
                    <CustomFormLabel
                      labelkey="purchases.payment.voucher.form.modal.due.bill" 
                      popoverkey="purchases.payment.voucher.form.modal.due.bill" 
                    />
                  }
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: <IntlMessages id="purchases.payment.voucher.form.modal.validation.paid.bill" />,
                    },
                  ]}
                >
                  <InputNumber min={0} className="input_number" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="voucharDate"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="purchases.payment.voucher.form.modal.voucher.date" 
                      popoverkey="purchases.payment.voucher.form.modal.voucher.date" 
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="purchases.payment.voucher.form.modal.validation.voucher.date" />,
                    },
                  ]}
                >
                  <DatePicker
                    selected={selectedVoucherDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="voucharDueDate"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="purchases.payment.voucher.form.modal.voucher.due.date"
                      popoverkey="purchases.payment.voucher.form.modal.voucher.due.date"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="purchases.payment.voucher.form.modal.validation.voucher.due.date" />,

                    },
                  ]}
                >
                  <DatePicker
                    selected={selectedVoucherDueDate}
                    onChange={handleVoucherDueDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="modal-body-table">
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.product" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.qty" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.discount" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.tax" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.taxAmount" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.unitPrice" /></th>
                      <th><IntlMessages id="purchase.payment.voucher.invoice.table.column.totalCost" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetails.map((item, index) => (
                      <tr key={index}>
                        <td className="p-5">{item.productName}</td>
                        <td className="p-5">{item.quantity}</td>
                        <td className="p-5">{item.disscount}</td>
                        <td className="p-5">{item.taxPercentage}</td>
                        <td className="p-5">{item.taxAmount}</td>
                        <td className="p-5">{item.unitCost}</td>
                        <td className="p-5">{item.totalCost}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><IntlMessages id="purchase.payment.voucher.invoice.table.totalCost" /></td>
                      <td>{TotalCost}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><IntlMessages id="purchase.payment.voucher.invoice.table.otherTax" /></td>
                      <td>{invoice.otherTax}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><IntlMessages id="purchase.payment.voucher.invoice.table.discount" /></td>
                      <td>{invoice.disscount}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><IntlMessages id="purchase.payment.voucher.invoice.table.grandTotal" /></td>
                      <td>{TotalCost + (invoice.otherTax || 0) - (invoice.disscount || 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div className="col-lg-12">
          <div className="input-blocks summer-description-box">
            <Form.Item
              name="notesHtml"
              className='actions_addModalFormItem'
              label={
                <CustomFormLabel
                  labelkey="purchase.payment.voucher.form.purchase.label.notes"
                  popoverkey="purchase.payment.voucher.form.label.popover.purchase.note"
                />
              }
            >
              <TextEditor data={editorData} onChange={handleEditorChange} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPaymentVoucharModal;
