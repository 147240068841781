import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../paymentgateway/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePaymentGateways({ paymentGatewayData, actionName, paymentGatewayParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-paymentgateway`,
        data: paymentGatewayData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-paymentgateway',
        data: paymentGatewayData,
      });
    }
    
    yield put(Actions.fetchAllPaymentGateways({ paymentGatewayParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.PAYMENTGATEWAY_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.PAYMENTGATEWAY_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPaymentGateways({ paymentGatewayParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-paymentgateways',
      params: paymentGatewayParams,
    });

    const allPaymentGatewayData = data;
    yield put(Actions.setPaymentGateways({ allPaymentGatewayData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PAYMENTGATEWAY, fetchAllPaymentGateways),
    takeLatest(ActionTypes.ADD_UPDATE_PAYMENTGATEWAY, addUpdatePaymentGateways),
  ]);
}
