export const ActionTypes = {
  ADD_UPDATE_SALE_INVOICE: 'services/sales/saleInvoice/add_update_saleInvoice',
  FETCH_SALE_INVOICE: 'services/sales/saleInvoice/fetch_saleInvoices',
  SET_SALE_INVOICE: 'services/sales/saleInvoice/set_saleInvoices',
  DELETE_SALE_INVOICE: 'services/sales/saleInvoice/delete_saleInvoices',
  GET_SALE_INVOICE_BY_ID: 'services/sales/saleInvoice/get_saleInvoice_by_id',
  SET_SALE_INVOICE_BY_ID: 'services/sales/saleInvoice/set_saleInvoice_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/sales/saleInvoice/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/sales/saleInvoice/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllSaleInvoices = ({ saleInvoiceParams }) => ({
  saleInvoiceParams,
  type: ActionTypes.FETCH_SALE_INVOICE,
})

const setSaleInvoices = ({ allSaleInvoiceData }) => ({
  allSaleInvoiceData,
  type: ActionTypes.SET_SALE_INVOICE,
})

const deleteSaleInvoiceById = ({ saleInvoiceParam }) => ({
  saleInvoiceParam,
  type: ActionTypes.DELETE_SALE_INVOICE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateSaleInvoice = ({ saleInvoiceData, actionName, UserParams }) => ({
  saleInvoiceData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_SALE_INVOICE,
})

const getSaleInvoiceById = ({ saleInvoiceParam }) => ({
  saleInvoiceParam,
  type: ActionTypes.GET_SALE_INVOICE_BY_ID,
})

const setSaleInvoiceById = ({ saleInvoiceParam }) => ({
  saleInvoiceParam,
  type: ActionTypes.SET_SALE_INVOICE_BY_ID,
})

export default {
  addUpdateSaleInvoice,
  fetchAllSaleInvoices,
  setSaleInvoices,
  deleteSaleInvoiceById,
  getSaleInvoiceById,
  setSaleInvoiceById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
