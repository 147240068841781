import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setposotherscategories = (state, { allCategoryData }) => {
    if (!allCategoryData) return state;
    return state.merge({ allCategoryData });
}

const setposothersproductsbycategory = (state, { allsubcategoryData }) => {
    if (!allsubcategoryData) return state;
    return state.merge({ allsubcategoryData });
}


const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_POS_OTHERS_CATEGORIES,
        actionFunction: setposotherscategories,
    },
    {
        actionType: ActionTypes.SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
        actionFunction: setposothersproductsbycategory,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
