import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { useDebounce } from 'use-debounce';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImportPurchases from "../../../core/modals/purchases/importpurchases";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Table from "../../../core/pagination/datatable";
import { purchaseslist } from "../../../core/json/purchaselistdata";
import ReceivePurchases from "./addreceivepurchases";
import ReceivedActions from "../../../core/Services/purchases/purchaseReceive/actions";
import * as PurchasesSelector from "../purchasesSelector";
import * as ApiResponseSelectors from '../../selectors';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { filterDate } from "../../../core/Common/Constant_Variables/common";

const PurchasesReceivedNotesList = () => {
  const purchasedata = purchaseslist;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [purchaseReceiveParams, setPurchaseReceive] = useState({
    pageSize: 10,
    pageNumber: 1,
    searchText: '',
  });

  const [editModalProps, setEditModalProps] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [debouncedText] = useDebounce(purchaseReceiveParams.searchText, 1000);
  const [isUpdate, setIsUpdate] = useState(false);
  const selectAllPurchaseReceives = useSelector(state => PurchasesSelector.setPurchaseReceives(state));
  const dataSource = selectAllPurchaseReceives ? selectAllPurchaseReceives.toJS() : [];
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const allPurchaseReceives = dataSource.data;
  const isLoading = useSelector(state => PurchasesSelector.setLoader(state));

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    dispatch(ReceivedActions.fetchAllPurchaseReceives({ purchaseReceiveParams }));
  }, [dispatch, debouncedText, currentPage, pageSize]);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      handleAddModal();
    }
  }, [apiResponseStatus]);

  const handleSearch = textValue => {
    setPurchaseReceive((prevParams) => ({
      ...prevParams,
      searchText: textValue,
    }));
  };

  const setEditProps = (row, key) => {
    setIsUpdate(true);
    const modalData = allPurchaseReceives.filter(e => e.grnNo == key.grnNo);
    setEditModalProps(modalData[0]);
    handleAddModal();
  }

  const columns = [
    {
      title: <IntlMessages id="purchasenotes.table.column.suppliername" />,
      dataIndex: "supplierName",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: <IntlMessages id="purchasenotes.table.column.reference" />,
      dataIndex: "reference",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },

    {
      title: <IntlMessages id="purchasenotes.table.column.date" />,
      dataIndex: "receivedDate",
      render: (text) => {
        return <div>{filterDate(text)}</div>
      },
      sorter: (a, b) => a.receivedDate.length - b.receivedDate.length,
    },

    {
      title: <IntlMessages id="purchasenotes.table.column.status" />,
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badges ${text === "Received" ? "status-badge" : "badge-bgdanger"
            }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: <IntlMessages id="purchasenotes.table.column.grandtotal" />,
      dataIndex: "grandTotal",
      sorter: (a, b) => a.grandTotal.length - b.grandTotal.length,
    },
    {
      title: <IntlMessages id="purchasenotes.table.column.paid" />,
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: <IntlMessages id="purchasenotes.table.column.due" />,
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },

    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (row, key) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" onClick={() => setEditProps(row, key)}>
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2" to="#" onClick={showConfirmationAlert}>
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDataIntoExcel = (filename = 'PurchaseRecieves.xlsx') => {
    if (allPurchaseReceives && allPurchaseReceives.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(purchasedata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Supplier Name', 'Notes', 'Status', 'Grand Total', 'Paid', 'Due', 'Created By']];

    const tableData = allPurchaseReceives?.map((item, index) => [item.supplierName, item.reference, item.status, item.grandTotal, item.paid, item.due, item.createdBy]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('PurchaseRecieves.pdf');
  };

  const handleRefresh = () => {
    dispatch(ReceivedActions.fetchAllPurchaseReceives({ purchaseReceiveParams }));
  }


  const handleAddModal = () => {
    const action = isModalOpen ? false : true;
    if (action === false) {
      setIsUpdate(false)
      setEditModalProps(null)
    }
    setIsModalOpen(action)
  }

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.current);
    setPageSize(paginationData.pageSize);
    setProductParams(prevParams => ({
      ...prevParams,
      pageNumber: paginationData.current,
      pageSize: paginationData.pageSize,
    }));
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(ReceivedActions.deletePurchaseReceives({ PurchaseReceiveParam: row }));
        MySwal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          className: "btn btn-success",
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else {
        MySwal.close();
      }

    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchasenotes.table.heading.purchasenotes" /></h4>
                <h6><IntlMessages id="purchasenotes.table.label.managepurchasenotes" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={handleAddModal}
                >
                  <PlusCircle className="me-2" />
                  <IntlMessages id="purchasenotes.table.button.addnewnotes" />
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => handleSearch(e.target.value)}

                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Filter */}

              {isModalOpen ?
                <ReceivePurchases
                  editModalProps={editModalProps}
                  isModalOpen={isModalOpen}
                  toggleModal={handleAddModal}
                  isUpdate={isUpdate}
                />
                : null}
              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table
                  isLoading={isLoading}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: dataSource?.totalRecords,
                    showSizeChanger: false,
                  }}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <ImportPurchases />
    </div>
  );
};

export default PurchasesReceivedNotesList;
