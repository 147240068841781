import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setStockTransfers = (state, { allStockTransferData }) => {
    if (!allStockTransferData) return state;
    return state.merge({ allStockTransferData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_STOCKTRANSFERS,
        actionFunction: setStockTransfers,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
