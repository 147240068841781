import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, TimePicker } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import attendanceActions from '../../../core/Services/hrm/attendance/actions'
import CustomFormLabel from '../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import dayjs from 'dayjs';


const AddUpdateAttendanceModal = (props) => {
    debugger
    const { currentModalData, isModalOpen, isUpdate, toggleModal, attendanceParams, employeeOptions } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [checkInTime, setCheckInTime] = useState(null)
    const [checkOutTime, setCheckOutTime] = useState(null)

    const handleRecord = (actionName, attendanceData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            attendanceData.id = currentModalData.formData.id
            attendanceData.companyId = 1
            attendanceData.checkIn = checkInTime
            attendanceData.checkOut = checkOutTime
        }
        else {
            attendanceData.companyId = 1
            attendanceData.checkIn = checkInTime
            attendanceData.checkOut = checkOutTime
        }

        dispatch(attendanceActions.addUpdateAttendance({ attendanceData, actionName, attendanceParams: attendanceParams }));
        toggleModal();
    };

    const handleCheckInChange = (time, timeString) => {
        setCheckInTime(timeString)
        form.setFieldValue({ checkIn: dayjs(time) })
        form.validateFields(["checkOut"]);
    }

    const handleCheckOutChange = (time, timeString) => {
        setCheckOutTime(timeString)
        form.setFieldValue({ checkOut: dayjs(time) })
    }

    const validateToTime = (_, value) => {
        if (!value || !checkInTime) {
            return Promise.resolve();
        }

        const formattedToTime = dayjs(value).format("HH:mm:ss");
        if (formattedToTime <= checkInTime) {
            return Promise.reject(new Error("Check Out should be greater than Check In"));
        }
        return Promise.resolve();
    };

    const handleEmployeeChange = (selectedEmployeeId) => {
        form.setFieldValue({ employeeId: selectedEmployeeId })
    }

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            setCheckInTime(currentModalData.formData.checkIn)
            setCheckOutTime(currentModalData.formData.checkOut)
            form.setFieldsValue({ checkIn: dayjs(currentModalData.formData.checkIn, 'HH:mm:ss') })
            form.setFieldsValue({ checkOut: dayjs(currentModalData.formData.checkOut, 'HH:mm:ss') })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Attendance"
                ) : (
                    "Add New Attendance"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    employeeId: currentModalData?.formData?.employeeId,
                }}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="employeeId"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Employee Name"
                                                popoverkey="Employee Name"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Employee Name Required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            classNamePrefix="react-select"
                                            options={employeeOptions.map(s => ({ label: s.name, value: s.id }))}
                                            placeholder="Choose"
                                            className="input-select"
                                            onChange={handleEmployeeChange}
                                            showSearch
                                            filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                            notFoundContent="No results found"
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="checkIn"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Clock In"
                                        popoverkey="Clock In"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Clock In Required",
                                    },
                                ]}
                            >
                                <TimePicker onChange={handleCheckInChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="checkOut"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="Clock Out"
                                        popoverkey="Clock Out"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Clock Out Required",
                                    },
                                    { validator: validateToTime },
                                ]}
                            >
                                <TimePicker onChange={handleCheckOutChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateAttendanceModal;