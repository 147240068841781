export const ActionTypes = {
    
    // New Action Types
    CHANGE_PASSWORD: 'services/settings/password/change_password',
    FORGOT_PASSWORD: 'services/settings/password/forgot_password',
    RESET_PASSWORD: 'services/settings/password/reset_password',
    ENABLE_2FA: 'services/settings/password/enable-2fa',
    DISABLE_2FA: 'services/settings/password/disable-2fa',
    GET_STATUS_2FA: 'services/settings/password/get_status-2fa',
    SET_STATUS_2FA: 'services/settings/password/set_status-2fa',
}

// Existing action creators
const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});



// New action creators for password management
const changePassword = ({ passwordData, callback }) => ({
    passwordData,
    callback,
    type: ActionTypes.CHANGE_PASSWORD,
});

const forgotPassword = ({ email }) => ({
    email,
    type: ActionTypes.FORGOT_PASSWORD,
});

const resetPassword = ({ resetToken, newPassword }) => ({
    resetToken,
    newPassword,
    type: ActionTypes.RESET_PASSWORD,
});

const enable2fa = ({ faData }) => ({
    faData,
    type: ActionTypes.ENABLE_2FA,
});

const disable2fa = ({ faData }) => ({
    faData,
    type: ActionTypes.DISABLE_2FA,
});

const getstatus2fa = () => ({
    type: ActionTypes.GET_STATUS_2FA,
});

const setstatus2fa = ({ allfaData }) => ({
    allfaData,
    type: ActionTypes.SET_STATUS_2FA,
});

export default {
    setLoader,
    changePassword,
    forgotPassword,
    resetPassword,
    enable2fa,
    disable2fa,
    getstatus2fa,
    setstatus2fa
};
