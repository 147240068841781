export const ActionTypes = {
    ADD_UPDATE_BANIP: 'services/Settings/banip/add_update_banip',
    FETCH_BANIP: 'services/Settings/banip/fetch_banip',
    SET_BANIP: 'services/Settings/banip/set_banip',
    DELETE_BANIP: 'services/Settings/banip/delete_banip',
    SET_LOADER: 'services/Settings/banip/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllBanip = ({ banipParams }) => ({
    banipParams,
    type: ActionTypes.FETCH_BANIP,
})

const setBanip = ({ allBanipData }) => ({
    allBanipData,
    type: ActionTypes.SET_BANIP,
})

const deleteBanipById = ({ banipParam }) => ({
    banipParam,
    type: ActionTypes.DELETE_BANIP,
})

const addUpdateBanip = ({ banipData, actionName, banipParams }) => ({
    banipData,
    actionName,
    banipParams,
    type: ActionTypes.ADD_UPDATE_BANIP,
})

export default {
    addUpdateBanip,
    fetchAllBanip,
    setBanip,
    deleteBanipById,
    setLoader
};