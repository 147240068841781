import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import IntlMessages from "../../../../shared/components/utility/intlMessages";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import Swal from "sweetalert2";
import { Calendar } from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setToogleHeader } from "../../../../core/redux/action";
import apiResponseAction from '../../../../core/Services/apiResponse/actions';
import * as posSelectors from '../../posSelectors.js';
import * as ApiResponseSelectors from '../../../selectors';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants";
import { Button, DatePicker } from "antd";

export const CashSummary = () => {
    return (
        <React.Fragment>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4><IntlMessages id="pos.cashclosing.cash.summary.page.title" /></h4>
                                <h6><IntlMessages id="pos.cashclosing.cash.summary.page.manage" /></h6>
                            </div>
                        </div>
                    </div>

                    {/* Product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="row w-100 justify-content-between">
                                    <div className="col-4">
                                        <div className="search-input d-flex align-items-center">
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select Date"
                                                className="actions_addModalFormItem"
                                            />
                                            <Calendar className="info-img" />
                                        </div>
                                    </div>

                                    <div className="col-6 d-flex flex-end">
                                        <div className="search-path">
                                            <div className="page-btn">
                                                <a className="btn btn-added bg-warning px-3 py-2">
                                                    <IntlMessages id="pos.cashclosing.cash.summary.table.button.search" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">

                            <table className="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td><strong>Total Sale:</strong></td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total Exp.:</strong></td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Net Position:</strong></td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total Entered Cash:</strong></td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cash Diff.:</strong></td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col">
                            <Button className="me-2 bg-warning p-5 text-white">
                                <h3>
                                    <IntlMessages id="pos.cashclosing.cash.summary.button.transfer.to.vault" />
                                </h3>
                            </Button>
                            <Button className="bg-danger p-5 ">
                                <h3 className="text-white">
                                    <IntlMessages id="pos.cashclosing.cash.summary.button.print.summary" />
                                </h3>
                            </Button>
                        </div>
                    </div>
                    {/* /Product list */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default CashSummary;
