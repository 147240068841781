import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setReportSettings = (state, { allReportSettingsData }) => {
    if (!allReportSettingsData) return state;
    return state.merge({ allReportSettingsData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_REPORTSETTING,
        actionFunction: setReportSettings,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
