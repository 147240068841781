import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../localization/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateLocalization({ localizationData, actionName, localizationParams }) {
  debugger
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-localization`,
        data: localizationData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-localization',
        data: localizationData,
      });
    }

    yield put(Actions.fetchAllLocalization({ localizationParams }));
    notification('success', actionName === 'update' ? "Localization Updated Successfully" : "Localization Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllLocalization({ localizationParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-localizations',
      params: localizationParams,
    });

    const allLocalizationData = data;
    yield put(Actions.setLocalization({ allLocalizationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteLocalizationById({ localizationParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-localization/` + localizationParam.id,
    });
    yield put(Actions.fetchAllLocalization({ localizationParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Localization Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_LOCALIZATION, fetchAllLocalization),
    takeLatest(ActionTypes.ADD_UPDATE_LOCALIZATION, addUpdateLocalization),
    takeLatest(ActionTypes.DELETE_LOCALIZATION, deleteLocalizationById),
  ]);
}
