import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../request/actions';
import notification from "../../../../shared/components/Notification";

function* fetchAllAvailableStocks({ availableStockParams }) {
  try {
    debugger;
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch available stocks
    const { data } = yield call(api.get, {
      route: '/Stock/all-availablestocks?DepartmentId='+ availableStockParams.DepartmentId,
    });

    // Store the fetched data in Redux store
    const allAvailableStockData = data;
    yield put(Actions.setAvailableStocks({ allAvailableStockData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_AVAILABLESTOCKS, fetchAllAvailableStocks)
  ]);
}
