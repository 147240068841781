import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import AddAdvanceBookingsModal from "./modal/addBooking.js";
import AddAdvanceMoreModal from "./modal/addMoreAdvance.js";
import AddFinalBillModal from "./modal/addFinalBill.js";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
  Calendar
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

import { setToogleHeader } from "../../../core/redux/action";
import AdvanceBookingsAction from "../../../core/Services/pos/advanceBookings/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as posSelectors from '../posSelectors.js';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { Card, Dropdown, Row, Col, Form, Select, DatePicker, Button, Menu, Table, Space, Spin, Pagination } from 'antd';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AddFinalBill from "./modal/addFinalBill.js";
import commonActions from '../../../core/Services/common/actions.js'
import * as commonSelectors from '../../commonSelectors.js'
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import './advanceBookings.css'

export const AdvanceBookings = () => {
  const dispatch = useDispatch();

  const [isAdvanceBookingsModalOpen, setAdvanceBookingsModalOpen] = useState(false);
  const [isAdvanceMoreModalOpen, setAdvanceMoreModalOpen] = useState(false);
  const [isFinalBillModalOpen, setFinalBillModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [totalRecords, setTotalRecords] = useState(12);

  const [AdvanceBookingsParams, setAdvanceBookingsParams] = useState({
    departmentId: null,
    fromdate: null,
    todate: null,
    pageNumber: currentPage,
    pageSize: pageSize
  });

  const handlePageChange = (page, pageSize) => {
    // setAdvanceBookingsParams({...AdvanceBookingsParams, pageNumber: page, pageSize: pageSize})
    setCurrentPage(page);
    setPageSize(pageSize);
  };




  const [debouncedText] = useDebounce(AdvanceBookingsParams, 1000);

  const [deptId, setDeptId] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)

  const handleDeptChange = (id) => {
    setDeptId(id)
    setAdvanceBookingsParams({ ...AdvanceBookingsParams, departmentId: id })
  }

  const handleFromDateChange = (date, dateString) => {
    setFrom(dateString);
    setAdvanceBookingsParams({ ...AdvanceBookingsParams, fromdate: dateString })
  };

  const handleToDateChange = (date, dateString) => {
    setTo(dateString);
    setAdvanceBookingsParams({ ...AdvanceBookingsParams, todate: dateString })
  };

  const isOptionsLoading = useSelector(state => commonSelectors.setLoader(state));

  const allDepartmentsData = useSelector(state => commonSelectors.setAllDepartment(state));
  const departments = allDepartmentsData ? allDepartmentsData.toJS() : [];

  const allCustomersData = useSelector(state => commonSelectors.setAllCustomer(state));
  const customers = allCustomersData ? allCustomersData.toJS() : [];

  const allCountriesData = useSelector(state => commonSelectors.setAllNationalities(state));
  const countriesData = allCountriesData ? allCountriesData.toJS() : [];

  const allFuncTypesData = useSelector(state => commonSelectors.setAllFunctionTypes(state));
  const functionTypes = allFuncTypesData ? allFuncTypesData.toJS() : [];

  const [loaderActive, setLoaderActive] = useState(false)
  const isLoading = useSelector(state => posSelectors.AdvanceBookingsLoader(state));
  useEffect(() => {
    setLoaderActive(isLoading)
  }, [isLoading])
  const AdvanceBookingsData = useSelector(state => posSelectors.setAdvanceBookings(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const AdvanceBookingsDataSource = AdvanceBookingsData ? AdvanceBookingsData.toJS() : [];
  const dataSource = AdvanceBookingsDataSource?.data;
  const data = useSelector((state) => state.toggle_header);

  const getFormOptions = () => {
    let formOptions = {}
    formOptions.customersData = customers;
    formOptions.countriesData = countriesData;
    formOptions.departmentsData = departments;
    formOptions.functionTypes = functionTypes;

    return formOptions;
  }


  useEffect(() => {
    dispatch(commonActions.getAllDepartment())
    dispatch(commonActions.getAllCustomer())
    dispatch(commonActions.getAllNationalities())
    dispatch(commonActions.getAllFunctionTypes())
  }, [])

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleAdvanceBookingsModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    fetchAdvanceBookingsData();
  }, [dispatch, currentPage, pageSize]);

  const fetchAdvanceBookingsData = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
      departmentId: deptId,
      fromdate: from,
      todate: to
    };
    dispatch(AdvanceBookingsAction.fetchAllAdvanceBookings({ advanceBookingsParams: params }));
  };

  const toggleAdvanceBookingsModal = (value) => {
    setAdvanceBookingsModalOpen();
  }

  const toggleAdvanceMoreModal = (value) => {
    setAdvanceMoreModalOpen();
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );


  const handleDataIntoExcel = (filename = 'AdvanceBookings.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['AdvanceBookingsId', 'AdvanceBookingsName', 'AdvanceBookingsSlug', 'isActive']];
    const tableData = dataSource.map((item, index) => [item.id, item.AdvanceBookingsName, item.AdvanceBookingsSlug, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('AdvanceBookings.pdf');
  };

  const handleRefresh = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
      departmentId: deptId,
      fromdate: from,
      todate: to
    };
    dispatch(AdvanceBookingsAction.fetchAllAdvanceBookings({ advanceBookingsParams: params }));
  }
  const handleModal = (AdvanceBookingsData, actionName) => {

    const AdvanceBookingsModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? AdvanceBookingsData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(AdvanceBookingsModalObj);
    setAdvanceBookingsModalOpen(true);
  };

  const handleAddMoreModal = (AdvanceBookingsData, actionName, advanceBillData) => {
    const AdvanceBookingsModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? advanceBillData : AdvanceBookingsData,
    };
    setCurrentModalData(AdvanceBookingsModalObj);
    setAdvanceMoreModalOpen(true);
  };
  const handleAddFinalBillModal = (actionName) => {
    setFinalBillModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AdvanceBookingsAction.deleteAdvanceBookings({ AdvanceBookingsParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const showConfirmationAlertForBill = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AdvanceBookingsAction.deleteAdvanceBills({ advanceBillsParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const items = [
    {
      key: '1',
      label: 'Add more Advance',
    },
    {
      key: '2',
      label: 'Edit',
    },
    {
      key: '3',
      label: 'Print',
    },
  ];

  const refs = useRef([]);
  var idx;

  const handleBookingPrint = (index) => {
    idx = index;
    handlePrint()
  };

  const handlePrint = useReactToPrint({
    content: () => refs.current[idx],
    documentTitle: 'No',
  });

  const handleMenuClick = (e, index, data) => {
    switch (e.key) {
      case '1':
        handleAddMoreModal(data, CONSTANT_VARIABLES.ADD, null);
        break;
      case '2':
        handleModal(data, CONSTANT_VARIABLES.UPDATE);
        break;
      case '3':
        handleBookingPrint(index);
        break;
      default:
        setModalContent('');
    }
  };

  const columns = [
    { title: '#', dataIndex: 'advanceBookingId', key: 'advanceBookingId' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Amount', dataIndex: 'advanceAmount', key: 'advanceAmount' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, row) => (
        <>
          <Link
            className="me-2 p-2"
            onClick={() => handleAddMoreModal(null, CONSTANT_VARIABLES.UPDATE, row)}
            data-bs-target="#edit-units"
          >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <i
            data-feather="trash-2"
            className="feather-trash-2"
            onClick={() => showConfirmationAlertForBill(row)}
            style={{ cursor: 'pointer' }}
          ></i>
        </>
      ),
    },
  ];

  useEffect(() => {
    let test = dataSource;
    console.log(test)
  }, [dataSource])

  function getTotalBill(bookingDetails) {
    return bookingDetails.reduce((total, item) => total + item.advanceAmount, 0);
  }

  const getRemainingBill = (billDetails, advanceAmount) => {
    let totalBill = billDetails.reduce((total, item) => total + item.advanceAmount, 0);
    return totalBill - advanceAmount;
  }

  const handleFetchData = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
      departmentId: deptId,
      fromdate: from,
      todate: to
    };
    // setAdvanceBookingsParams({ ...AdvanceBookingsParams, departmentId: deptId, fromdate: from, todate: to })
    dispatch(AdvanceBookingsAction.fetchAllAdvanceBookings({ advanceBookingsParams: params }));
  }


  return (
    <>
      {(loaderActive || isOptionsLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="pos.AdvanceBookings.table.heading.AdvanceBookings" /></h4>
                <h6><IntlMessages id="pos.AdvanceBookings.table.label.manage.AdvanceBookings" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="pos.AdvanceBookings.table.button.addnew.AdvanceBookings" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <Form layout="inline" className="w-100 p-2">
                <div className="row w-100 align-items-center">
                  <div className="col-9 row w-100 justify-content-start">
                    <div className="col-3">
                      <Form.Item
                        name="points"
                        label={<IntlMessages id="searchFilter.label.points" />}
                        className="form-item"
                      >
                        <Select
                          // options={departments}
                          options={departments?.map(s => ({ label: s.name, value: s.id }))}
                          classNamePrefix="react-select"
                          className="input-select"
                          placeholder={<IntlMessages id="searchFilter.placeholder.points" />}
                          onChange={handleDeptChange}
                        />

                      </Form.Item>
                    </div>

                    <div className="col-3">
                      <Form.Item
                        name="startDate"
                        label={<IntlMessages id="searchFilter.label.fromDate" />}
                        className="form-item"
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          label={<IntlMessages id="pos.advanceBookings.form.label.date" />}
                          className="actions_addModalFormItem"
                          onChange={handleFromDateChange}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-3">
                      <Form.Item
                        name="endDate"
                        label={<IntlMessages id="searchFilter.label.toDate" />}
                        className="form-item"
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          label={<IntlMessages id="pos.advanceBookings.form.label.date" />}
                          className="actions_addModalFormItem"
                          onChange={handleToDateChange}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-3 d-flex align-items-center justify-content-end">
                      <Form.Item>
                        <Button
                          type="primary"
                          className="btn btn-added bg-warning pe-3 mb-2"
                          onClick={handleFetchData}
                        >
                          <IntlMessages id="searchFilter.button.search" />
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {dataSource && dataSource?.map((d, index) => (
            <div key={d?.id}>
              <Card className=" mb-5" ref={(el) => refs.current[index] = el}>
                <Dropdown
                  overlay={
                    <Menu
                      items={items}
                      onClick={(e) => handleMenuClick(e, index, d)}

                    />
                  }
                  trigger={['click']}
                >
                  <Button style={{ float: 'right' }} className="bg-warning text-white py-3 actions-button">
                    <Space>
                      Actions
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <div className="row justify-content-center">

                  <div className=" col-5 text-center">
                    <h1>{d?.eventName}</h1>
                    <h5 className="text-gray">
                      {moment(d?.bookingDate).format('dddd, DD MMMM YYYY')}<br />
                      {moment(d?.startTime, 'HH:mm:ss').format('h:mm A')} - {moment(d?.endTime, 'HH:mm:ss').format('h:mm A')}
                    </h5>
                  </div>
                </div>

                <div className="row justify-content-around mt-4">
                  <div className=" col-lg-3 col-sm-6 "><h5>Name: {d?.customerName}</h5></div>
                  <div className=" col-lg-3 col-sm-6 "><h5>Contact Number: {d?.contactNumber}</h5></div>
                  <div className=" col-lg-3 col-sm-6 "><h5>Total Qty.: {d?.maleQuantity + d?.feMaleQuantity}</h5></div>

                </div>
                <div className="row justify-content-around mt-2">
                  <div className=" col-lg-3 col-sm-6 "><h5>Male: {d?.maleQuantity}</h5></div>
                  <div className=" col-lg-3 col-sm-6 "><h5>Female: {d?.feMaleQuantity}</h5></div>
                  <div className=" col-lg-3 col-sm-6 "><h5>Per Head: {d?.perHeadAmount}</h5></div>
                </div>

                <div className="mt-3">
                  <h2>Menu: &nbsp;</h2><div className="ms-5" dangerouslySetInnerHTML={{ __html: d?.menu }} />
                </div>

                <div className="mt-3">
                  <h2>Function Bill Details</h2>
                  <Table
                    dataSource={d?.advanceBookingDetails}
                    columns={columns}
                    pagination={false}
                    footer={() => (
                      <>
                        <div className="row mt-1">
                          <div className="col-7" ><h6>Total Paid</h6></div>
                          <div className="col-3" ><h6 style={{ marginLeft: '70px' }}>{d?.totalPaid}</h6></div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-7" ><h6>Total Bill</h6></div>
                          <div className="col-3" ><h6 style={{ marginLeft: '70px' }}>{d?.totalBill}</h6></div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-7" ><h6>Remaining Bill</h6></div>
                          <div className="col-3" ><h6 style={{ marginLeft: '70px' }}>{d?.totalRemaining}</h6></div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </Card>
            </div>
          ))}

          <Pagination
            className="p-3 d-flex justify-content-end"
            current={currentPage}
            pageSize={pageSize}
            total={AdvanceBookingsDataSource?.totalRecords}
            onChange={handlePageChange}
          />
        </div>
      </div >
      {
        isAdvanceBookingsModalOpen ? (
          <AddAdvanceBookingsModal
            currentModalData={currentModalData}
            isModalOpen={isAdvanceBookingsModalOpen}
            isUpdate={currentModalData.formData.id ? true : false}
            toggleModal={toggleAdvanceBookingsModal}
            formOptions={getFormOptions()}
            advanceBookingsParams={AdvanceBookingsParams}
          />
        ) : null
      }
      {
        isAdvanceMoreModalOpen ? (
          <AddAdvanceMoreModal
            currentModalData={currentModalData}
            isModalOpen={isAdvanceMoreModalOpen}
            isUpdate={currentModalData.formData.advanceBookingId ? true : false}
            toggleModal={toggleAdvanceMoreModal}
            advanceBookingsParams={AdvanceBookingsParams}
          />
        ) : null
      }
    </>
  );
};

export default AdvanceBookings;