import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setAvailableStocks = (state, { allAvailableStockData }) => {
    if (!allAvailableStockData) return state;
    return state.merge({ allAvailableStockData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_AVAILABLESTOCKS,
        actionFunction: setAvailableStocks,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
