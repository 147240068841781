import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronUp, PlusCircle, RotateCcw } from 'feather-icons-react/build/IconComponents';
import { setToogleHeader } from '../../../core/redux/action';
import SettingsSideBar from '../settingssidebar';
import AddUpdateConnectedAppModal from './components/addUpdateConnectedAppModal';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as connectedAppSelectors from '../settingsSelector'
import connectedAppActions from '../../../core/Services/settings/connectedapp/actions'
import { useDebounce } from 'use-debounce';
import { Image, Spin, Switch } from 'antd';

const ConnectedApps = () => {

    const dispatch = useDispatch();

    const [isConnectedAppModalOpen, setConnectedAppModalOpen] = useState(false);
    const [currentModalData, setCurrentModalData] = useState({});
    const [connectedAppParams, setConnectedAppParams] = useState({
        pageNo: 1,
        searchText: '',
    });

    const [debouncedText] = useDebounce(connectedAppParams, 1000);

    const isLoading = useSelector(state => connectedAppSelectors.setConnectedAppLoader(state));
    const allConnectedAppData = useSelector(state => connectedAppSelectors.setConnectedApps(state))
    const dataSource = allConnectedAppData ? allConnectedAppData.toJS() : [];
    const connectedAppData = dataSource?.data

    useEffect(() => {
        dispatch(connectedAppActions.fetchAllConnectedApps({ connectedAppParams: debouncedText }));
    }, [dispatch, debouncedText, connectedAppParams]);

    const handleRefresh = () => {
        dispatch(connectedAppActions.fetchAllConnectedApps({ connectedAppParams }));
    }

    const data = useSelector((state) => state.toggle_header);

    const toggleConnectedAppModal = (value) => {
        setConnectedAppModalOpen();
    }

    const handleModal = (connectedAppData, actionName) => {
        const connectedAppModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? connectedAppData : {},
        };
        setCurrentModalData(connectedAppModalObj);
        setConnectedAppModalOpen(true);
    };

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    )

    const handleConnectApp = (app) => {
        const ConnectedAppData = {};
        ConnectedAppData.id = app.id;
        ConnectedAppData.companyId = 1;
        ConnectedAppData.appLogoUrl = app.appLogoUrl;
        ConnectedAppData.appName = app.appName;
        ConnectedAppData.isConnected = 1;
        dispatch(connectedAppActions.addConnectedApp({ ConnectedAppData: ConnectedAppData, actionName: "update", ConnectedAppParams: connectedAppParams }))
    }

    const handleAppDisconnect = (checked, app) => {
        const ConnectedAppData = {};
        ConnectedAppData.id = app.id;
        ConnectedAppData.companyId = 1;
        ConnectedAppData.appLogoUrl = app.appLogoUrl;
        ConnectedAppData.appName = app.appName;
        ConnectedAppData.isConnected = checked ? 1 : 0;
        dispatch(connectedAppActions.addConnectedApp({ ConnectedAppData: ConnectedAppData, actionName: "update", ConnectedAppParams: connectedAppParams }))
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <div className="d-flex purchase-pg-btn">
                                <div className="page-btn me-2">
                                    <Button
                                        className="btn btn-added"
                                        onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                    >
                                        <PlusCircle className="me-2" />
                                        Connect App
                                    </Button>
                                </div>
                            </div>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>

                        </ul>
                    </div>
                    {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
                    {isConnectedAppModalOpen ? (
                        <AddUpdateConnectedAppModal
                            currentModalData={currentModalData}
                            isModalOpen={isConnectedAppModalOpen}
                            isUpdate={currentModalData.formData.id ? true : false}
                            toggleModal={toggleConnectedAppModal}
                            connectedAppParams={connectedAppParams}
                        />
                    ) : null}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <div className="setting-title">
                                        <h4>Connected Apps</h4>
                                    </div>
                                    <div className="row">
                                        {connectedAppData ? connectedAppData.map(app => (
                                            <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                                                <div className="connected-app-card">
                                                    <ul>
                                                        <li>
                                                            <div className="app-icon">
                                                                <Image src={app.appLogoUrl} alt="" style={{ width: '50px', height: '50px', borderRadius:'50%' }} />
                                                            </div>
                                                            {app.isConnected == 1
                                                                ?
                                                                <div className="connect-btn">
                                                                    <Link to="#">Connected</Link>
                                                                </div>
                                                                :
                                                                <div className="connect-btn not-connect">
                                                                    <Link onClick={() => handleConnectApp(app)} to="#">Connect</Link>
                                                                </div>
                                                            }

                                                        </li>
                                                        <li>
                                                            <div className="security-type">
                                                                <div className="security-title">
                                                                    <h5>{app.appName}</h5>
                                                                </div>
                                                            </div>
                                                            <Switch onChange={(checked) => handleAppDisconnect(checked, app)} className='pe-2' size='small' checked={app.isConnected == 1 ? true : false} style={{ backgroundColor: app.isConnected == 1 ? 'lightgreen' : 'lightgray' }} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectedApps
