import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Spin } from "antd";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import StockActions from '../../../core/Services/stock/managestock/actions';
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import { debounce } from 'lodash';

const ManageStockModal = (props) => {
  const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const allWarehouses = warehouseData ? warehouseData.toJS() : [];
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const allProducts = productData ? productData.toJS() : [];

  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
    dispatch(commonAction.getAllWarehouses());
    dispatch(commonAction.getAllEmployee());
  }, [])

  useEffect(() => {
    setLoading(false);
  }, [productData])

  const searchProducts = (text) => {
    setLoading(true);
    dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
  };
  const debouncedSearchProducts = debounce(searchProducts, 1000);

  const handleRecord = (actionName, managestockData) => {
    debugger;
    const data = {
      warehouseId: managestockData.warehouse,
      employeeId: managestockData.person,
      productId: managestockData.product,
      departmentId: managestockData.shop,
      quantity: managestockData.quantity,
      unitPrice: managestockData.unitPrice
    }
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      data.id = currentModalData.id;
      data.companyId = currentModalData.companyId;
      if (typeof managestockData.product === 'string') {
        if (allProducts.length > 0) {
          const productId = allProducts.filter(p => p.productName === managestockData.product);
          if (productId.length === 0) {
            data.productId = currentModalData.productId;
          } else {
            data.productId = productId[0].id;
          }
        } else {
          data.productId = currentModalData.productId;
        }
      }
    } else {
      data.id = 0;
      data.companyId = 1;
    }

    dispatch(StockActions.addUpdateManageStocks({ managestockData: data, actionName, UserParams: {} }));

    toggleModal();
  }

  return (
    <Modal
      open={isModalOpen}
      width={800}
      zIndex={99999}
      onClose={() => toggleModal()}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal()}
      title={
        isUpdate ? (
          <IntlMessages id="stock.modal.editstock" />
        ) : (
          <IntlMessages id="stock.modal.addstock" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          warehouse: currentModalData ? currentModalData.warehouseId : null,
          shop: currentModalData ? currentModalData.departmentId : null,
          person: currentModalData ? currentModalData.employeeId : null,
          product: currentModalData ? currentModalData.productName : null,
          quantity: currentModalData ? currentModalData.quantity : null,
          unitPrice: currentModalData ? currentModalData.unitPrice : null,
        }}
      >
        <div>
          <div className="modal-body custom-modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="input-blocks">
                  <Form.Item
                    name="warehouse"
                    label={
                      <IntlMessages id="stock.table.column.warehouse" />
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={allWarehouses.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-blocks">
                  <Form.Item
                    name="shop"
                    label={
                      <IntlMessages id="stock.table.column.shop" />
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <Form.Item
                    name="person"
                    label={
                      <IntlMessages id="stock.table.column.person" />
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <Form.Item
                    name="product"
                    label={
                      <IntlMessages id="stock.table.column.product" />
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Product"
                      onSearch={debouncedSearchProducts}
                      filterOption={false}
                      options={allProducts.map((f) => ({ label: f.productName, value: f.id }))}
                      notFoundContent={loading ? <Spin size="small" /> : null}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-18">
                <Form.Item
                  name="quantity"
                  label={
                    <IntlMessages id="stock.table.column.quantity" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Quantity"
                    onChange={(e) => {
                      const value = e.target.value ? Number(e.target.value) : null;
                      form.setFieldsValue({ quantity: value });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-18">
                <Form.Item
                  name="unitPrice"
                  label={
                    <IntlMessages id="stock.table.column.unitprice" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Unit Price"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ManageStockModal;
