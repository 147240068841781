export const ActionTypes = {
  ADD_UPDATE_PURCHASE: 'services/purchases/purchase/add_update_purchase',
  FETCH_PURCHASE: 'services/purchases/purchase/fetch_purchases',
  SET_PURCHASES: 'services/purchases/purchase/set_purchases',
  DELETE_PURCHASES: 'services/purchases/purchase/delete_purchases',
  GET_PURCHASE_BY_ID: 'services/purchases/purchase/get_purchase_by_id',
  SET_PURCHASE_BY_ID: 'services/purchases/purchase/set_purchase_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/purchase/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/purchase/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllPurchases = ({ purchaseParams }) => ({
  purchaseParams,
  type: ActionTypes.FETCH_PURCHASE,
})

const setPurchases = ({ allPurchaseData }) => ({
  allPurchaseData,
  type: ActionTypes.SET_PURCHASES,
})

const deletePurchaseById = ({ purchaseParam }) => ({
  purchaseParam,
  type: ActionTypes.DELETE_PURCHASES,
})

const GetQuotationByQNById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const SetQuotationByQNById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdatePurchase = ({ purchaseData, actionName, UserParams }) => ({
  purchaseData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_PURCHASE,
})


const getPurchaseById = ({ purchaseParamId }) => ({
  purchaseParamId,
  type: ActionTypes.GET_PURCHASE_BY_ID,
})

const setPurchaseById = ({ purchaseData }) => ({
  purchaseData,
  type: ActionTypes.SET_PURCHASE_BY_ID,
})

export default {
  addUpdatePurchase,
  fetchAllPurchases,
  setPurchases,
  deletePurchaseById,
  getPurchaseById,
  setPurchaseById,
  SetQuotationByQNById,
  GetQuotationByQNById,
  setLoader
};
