
export const ActionTypes = {
    ADD_UPDATE_SHIFT: 'services/hrm/shift/add_update_shift',
    FETCH_SHIFT: 'services/hrm/shift/fetch_shifts',
    SET_SHIFTS: 'services/hrm/shift/set_shifts',
    DELETE_SHIFTS: 'services/hrm/shift/delete_shifts',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllShifts = ({ shiftParams }) => ({
    shiftParams,
    type: ActionTypes.FETCH_SHIFT,
})

const setShifts = ({ allShiftData }) => ({
    allShiftData,
    type: ActionTypes.SET_SHIFTS,
})

const deleteShifts = ({ shiftParam }) => ({
    shiftParam,
    type: ActionTypes.DELETE_SHIFTS,
})

const addUpdateShifts = ({ shiftData, actionName, UserParams }) => ({
    shiftData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_SHIFT,
})

export default {
    addUpdateShifts,
    fetchAllShifts,
    setShifts,
    deleteShifts,
    setLoader
};
