import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import banIpActions from '../../../../core/Services/settings/banip/actions'


const AddUpdateBanIpModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, banIpParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, banIpData) => {
        debugger
        banIpData.companyId = 1
        banIpData.isActive = banIpData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            banIpData.id = currentModalData.formData.id
        }

        dispatch(banIpActions.addUpdateBanip({ banipData: banIpData, actionName, banipParams: banIpParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ ipAddress: currentModalData.formData.ipAddress })
            form.setFieldsValue({ reason: currentModalData.formData.reason })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
        }
    }, [isUpdate])


    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Ban IP Address"
                ) : (
                    "Add New Ban IP Address"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="ipAddress"
                                        label="IP Address"
                                        rules={[
                                            {
                                                required: true,
                                                message: "IP Address is required.",
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9]))|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])))$/
                                                ),
                                                message: "Please enter a valid IPv4 or IPv6 address.",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter IP Address'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="reason"
                                className="actions_addModalFormItem"
                                label="Reason For Ban"
                                rules={[
                                    {
                                        required: true,
                                        message: "Reason Required",
                                    },
                                    {
                                        max: 500,
                                        message: "Reason cannot exceed 100 characters.",
                                    },
                                ]}
                            >
                                <TextArea placeholder='Enter Reason For Banning IP Address' />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10 d-flex">
                                    <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <Switch size='small' className='pe-2 mb-1' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateBanIpModal;