import { ChevronUp, File, Info, RotateCcw } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { setToogleHeader } from '../../../core/redux/action';
// import Select from 'react-select';
import Map from 'feather-icons-react/build/IconComponents/Map';
import ReactTagsInput from '../reacttaginputs';
import SettingsSideBar from '../settingssidebar';
import { Form, Space, Spin, Switch, Select } from 'antd';
import CreditCard from 'feather-icons-react/build/IconComponents/CreditCard';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonActions from '../../../core/Services/common/actions'
import localizationActions from '../../../core/Services/settings/localization/actions'
import * as commonSelectors from '../../commonSelectors'
import * as settingSelectors from '../settingsSelector'
import { useDebounce } from 'use-debounce';

const LocalizationSettings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    const [localizationParams, setLocalizationParams] = useState({
        pageNo: 1,
        searchText: '',
    });

    const [debouncedText] = useDebounce(localizationParams, 1000);

    const data = useSelector((state) => state.toggle_header);

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const isOptionsDataLoading = useSelector(state => commonSelectors.setLoader(state));

    useEffect(() => {
        dispatch(commonActions.getAllNationalities())
        dispatch(commonActions.fetchAllcurrency())
        dispatch(commonActions.fetchAllLanguage())
        dispatch(commonActions.fetchAllTimezone())
        dispatch(commonActions.fetchAllDateformate())
        dispatch(commonActions.fetchAllTimeformate())
    }, [])

    useEffect(() => {
        dispatch(localizationActions.fetchAllLocalization({ localizationParams: debouncedText }))
    }, [dispatch])


    //////  All Localization data
    const isLocalizationDataLoading = useSelector(state => settingSelectors.setLocalizationLoader(state));
    const allLocalizationData = useSelector(state => settingSelectors.setLocalization(state));
    const dataSource = allLocalizationData ? allLocalizationData.toJS() : [];
    const localizationData = dataSource?.data

    //////  Countries Options
    const allCountriesData = useSelector(state => commonSelectors.setAllNationalities(state));
    const countriesData = allCountriesData ? allCountriesData.toJS() : [];

    //////  Currency Options
    const allCurrenciesData = useSelector(state => commonSelectors.setAllCurrency(state));
    const currenciesData = allCurrenciesData ? allCurrenciesData.toJS() : [];

    //////  Language Options
    const allLanguagesData = useSelector(state => commonSelectors.setLanguage(state));
    const languagesData = allLanguagesData ? allLanguagesData.toJS() : [];

    //////  Timezone Options
    const allTimezoneData = useSelector(state => commonSelectors.setTimezone(state));
    const timezoneData = allTimezoneData ? allTimezoneData.toJS() : [];

    //////  Date Format Options
    const allDateformatData = useSelector(state => commonSelectors.setDateformate(state));
    const dateformatData = allDateformatData ? allDateformatData.toJS() : [];

    //////  Time Format Options
    const allTimeformatData = useSelector(state => commonSelectors.setTimeformate(state));
    const timeformatData = allTimeformatData ? allTimeformatData.toJS() : [];

    useEffect(() => {
        if (localizationData) {
            form.setFieldsValue({ languageId: localizationData?.languageId })
            form.setFieldsValue({ isLanguageSwitch: localizationData?.isLanguageSwitch })
            form.setFieldsValue({ timeZoneStr: localizationData?.timeZoneStr })
            form.setFieldsValue({ dateFormatId: localizationData?.dateFormatId })
            form.setFieldsValue({ timeFormatId: localizationData?.timeFormatId })
            form.setFieldsValue({ curencyId: localizationData?.curencyId })
            form.setFieldsValue({ restrictedCountriesId: localizationData?.restrictedCountriesId })
            form.setFieldsValue({ allowedFiles: localizationData?.allowedFiles })
            form.setFieldsValue({ maxFileSize: localizationData?.maxFileSize })
        }
    }, [localizationData])


    const handleValidateFormData = () => {
        form.validateFields()
            .then(values => {
                handleRecord(values);
            })
            .catch(info => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    }

    const handleRecord = (localizationData) => {
        debugger
        localizationData.companyId = 1;
        dispatch(localizationActions.addUpdateLocalization({ localizationData, actionName: "Add", localizationParams: localizationParams }));
    };

    const handleRefresh = () => {
        dispatch(localizationActions.fetchAllLocalization({ localizationParams }));
    }

    return (
        <div>
            {(isOptionsDataLoading || isLocalizationDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>

                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <form>
                                        <div className="setting-title">
                                            <h4>Localization</h4>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            initialValues={{}}
                                            name="forForm" className='productActions_addModalWrapper'>
                                            <div className="company-info company-images">
                                                <div className="card-title-head">
                                                    <h6>
                                                        <span>
                                                            <Info />
                                                        </span>
                                                        Basic Information
                                                    </h6>
                                                </div>
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Language</h6>
                                                                <p>Select Language of the Website</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="languageId"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Language Required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={languagesData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Language"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    // onChange={handleCountryChange}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Language Switcher</h6>
                                                                <p>To display in all the pages</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                                    <Form.Item
                                                                        name="isLanguageSwitch"
                                                                        className="actions_addModalFormItem"
                                                                    >
                                                                        <Switch className='pe-2' size='small' />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Timezone</h6>
                                                                <p>Select Time zone in website</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="timeZoneStr"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Timezone Required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={timezoneData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Timezone"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    // onChange={handleCountryChange}
                                                                    />
                                                                </Form.Item>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Date format</h6>
                                                                <p>Select date format to display in website</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="dateFormatId"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select Date Format",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={dateformatData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Date Format"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    // onChange={handleCountryChange}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Time Format</h6>
                                                                <p>Select time format to display in website</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="timeFormatId"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select Time Format",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={timeformatData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Time Format"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    // onChange={handleCountryChange}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-info company-images">
                                                <div className="card-title-head">
                                                    <h6>
                                                        <span>
                                                            <CreditCard />
                                                        </span>
                                                        Currency Settings
                                                    </h6>
                                                </div>
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Currency</h6>
                                                                <p>Select currency </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">
                                                                <Form.Item
                                                                    name="curencyId"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select Currency",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={currenciesData.map(s => ({ label: s.name, value: s.id }))}
                                                                        placeholder="Select Currency"
                                                                        className="input-select"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    // onChange={handleCountryChange}
                                                                    />
                                                                </Form.Item>                                                  </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-info company-images">
                                                <div className="card-title-head">
                                                    <h6>
                                                        <span>

                                                            <Map />
                                                        </span>
                                                        Country Settings
                                                    </h6>
                                                </div>
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Countries Restriction</h6>
                                                                <p>Select country </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select">

                                                                <Form.Item
                                                                    name="restrictedCountriesId"
                                                                    className="actions_addModalFormItem"
                                                                >
                                                                    <Select
                                                                        mode="multiple"
                                                                        allowClear
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        placeholder="Please select"
                                                                        // onChange={handleChange}
                                                                        options={countriesData.map(s => ({ label: s.name, value: s.id }))}
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        notFoundContent="No results found"
                                                                        optionFilterProp="label"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-images">
                                                <div className="card-title-head">
                                                    <h6>
                                                        <span>

                                                            <File />
                                                        </span>
                                                        File Settings
                                                    </h6>
                                                </div>
                                                <div className="localization-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info">
                                                                <h6>Allowed Files</h6>
                                                                <p>Select files </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-8 col-sm-4">
                                                            <div className="localization-select w-100">
                                                                <div className="input-blocks">
                                                                    <Form.Item
                                                                        name="allowedFiles"
                                                                        className="actions_addModalFormItem"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please add at least one file type",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <ReactTagsInput />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-4">
                                                            <div className="setting-info mb-sm-0">
                                                                <h6>Max File Size</h6>
                                                                <p>File size </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="localization-select d-flex align-items-center mb-0">
                                                                <Form.Item
                                                                    name="maxFileSize"
                                                                    className="actions_addModalFormItem"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please select Max. File Size",
                                                                        },
                                                                        {
                                                                            pattern: /^\d+$/,
                                                                            message: "Size number must contain only digits.",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                </Form.Item>
                                                                <span className="ms-2 mb-3"> MB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                            //data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <Link onClick={handleValidateFormData} to="#" className="btn btn-submit">
                                                Save Changes
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default LocalizationSettings;