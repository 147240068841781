export const ActionTypes = {
    ADD_UPDATE_USERPROFILE: 'services/Settings/userprofiles/add_update_userprofile',
    FETCH_USERPROFILE: 'services/Settings/userprofiles/fetch_userprofiles',
    SET_USERPROFILES: 'services/Settings/userprofiles/set_userprofiles',
    DEACTIVATE_ACOUNT:'services/Settings/userprofiles/deactivate_account',
    SET_LOADER: 'services/Settings/userprofiles/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllUserProfiles = ({ userprofileParams }) => ({
    userprofileParams,
    type: ActionTypes.FETCH_USERPROFILE,
})

const setUserProfiles = ({ allUserProfileData }) => ({
    allUserProfileData,
    type: ActionTypes.SET_USERPROFILES,
})

const addUpdateUserProfiles = ({ userprofileData, actionName, UserParams }) => ({
    userprofileData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_USERPROFILE,
})

const deactivateaccount = ({ UserId }) => ({
    UserId,
    type: ActionTypes.DEACTIVATE_ACOUNT,
})

export default {
    addUpdateUserProfiles,
    fetchAllUserProfiles,
    setUserProfiles,
    deactivateaccount,
    setLoader
};
