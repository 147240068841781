import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../employee/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateEmployees({ employeeData, actionName, employeeParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    if (employeeData.userImage) {
      const file = employeeData.userImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete employeeData.userImage;
      if (imageUrl) {
        employeeData.profileUrl = imageUrl.url;
      }
    }

    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-employee`,
        data: employeeData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-employee',
        data: employeeData,
      });
    }

    yield put(Actions.fetchAllEmployees({ employeeParams }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.EMPLOYEE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.EMPLOYEE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllEmployees({ employeeParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch employees
    const { data } = yield call(api.get, {
      route: '/HRM/all-employees',
      params: employeeParams,
    });

    // Store the fetched data in Redux store
    const allEmployeeData = data;
    yield put(Actions.setEmployees({ allEmployeeData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteEmployeeById({ employeeParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-employee/` + employeeParam.id,
    });
    yield put(Actions.fetchAllEmployees({ employeeParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.EMPLOYEE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_EMPLOYEE, fetchAllEmployees),
    takeLatest(ActionTypes.ADD_UPDATE_EMPLOYEE, addUpdateEmployees),
    takeLatest(ActionTypes.DELETE_EMPLOYEES, deleteEmployeeById)
  ]);
}
