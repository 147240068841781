import { MapPin, RotateCcw, User } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Button, Flex, Form, Select, Spin, Upload, Image } from 'antd';
import { UploadOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import commonActions from '../../../core/Services/common/actions'
import profileActions from '../../../core/Services/settings/userprofile/actions'
import * as commonSelectors from '../../commonSelectors'
import * as profileSelectors from '../../settings/settingsSelector'
import { useDebounce } from 'use-debounce';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import axios from 'axios';

const GeneralSettings = () => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    const [userprofileParams, setUserprofileParams] = useState({ pageNo: 1, searchText: '' });
    const [countryId, setCountryId] = useState(null)
    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [stateId, setStateId] = useState(null)
    const [isStateSelected, setIsStateSelected] = useState(false)
    const [isFormBeingEdited, setIsFormBeingEdited] = useState(false);

    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isError, setisError] = useState(false);

    const [debouncedText] = useDebounce(userprofileParams, 1000);

    //Selectors
    const isLoading = useSelector(state => commonSelectors.setLoader(state));
    const isProfileDataLoading = useSelector(state => profileSelectors.setLoader(state));

    //// Countries Data
    const allCountriesData = useSelector(state => commonSelectors.setAllNationalities(state));
    const countriesData = allCountriesData ? allCountriesData.toJS() : [];

    //// States Data
    const allStatesData = useSelector(state => commonSelectors.setAllStates(state));
    const statesData = allStatesData ? allStatesData.toJS() : [];

    //// Cities Data
    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    //// User Profile Data
    const allUserProfileData = useSelector(state => profileSelectors.setUserProfiles(state));
    const dataSource = allUserProfileData ? allUserProfileData.toJS() : {};
    const userProfileData = dataSource?.data;

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        uploadFromURL(userProfileData?.profileUrl)
    }, [userProfileData?.profileUrl]);

    const uploadFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...fileList,
                {
                    uid: `-${fileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    // Other Function
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    const handleRefresh = () => {
        dispatch(profileActions.fetchAllUserProfiles({ userprofileParams }))
    }

    const handleCountryChange = (selectedCountryId) => {
        setIsFormBeingEdited(true);
        form.setFieldsValue({ stateId: null })
        form.setFieldsValue({ cityId: null })
        setIsStateSelected(false)
        dispatch(commonActions.getAllStatesByCountryId({ stateParams: selectedCountryId }))
        setIsCountrySelected(true);
    }

    const handleStateChange = (selectedStateId) => {
        setIsFormBeingEdited(true);
        form.setFieldsValue({ cityId: null })
        dispatch(commonActions.getAllCitiesByStateId({ cityParams: selectedStateId }));
        setIsStateSelected(true);
    };

    // Update User Profile Data
    const handleValidateFormData = () => {
        form.validateFields()
            .then(values => {
                handleRecord(values);
            })
            .catch(info => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    }

    const handleRecord = (userprofileData) => {
        debugger
        userprofileData.userImage = fileList[0];
        dispatch(profileActions.addUpdateUserProfiles({ userprofileData, actionName: "update", userprofileParams: userprofileParams }));
        toggleModal();
    };

    // UseEffect
    useEffect(() => {
        dispatch(commonActions.getAllNationalities())
    }, [])

    useEffect(() => {
        if (countryId != null) {
            dispatch(commonActions.getAllStatesByCountryId({ stateParams: countryId }))
        }
    }, [countryId])

    useEffect(() => {
        if (stateId != null) {
            dispatch(commonActions.getAllCitiesByStateId({ cityParams: userProfileData.stateId }))
        }
    }, [stateId])

    useEffect(() => {
        dispatch(profileActions.fetchAllUserProfiles({ userprofileParams: debouncedText }))
    }, [debouncedText])

    useEffect(() => {
        debugger
        if (userProfileData && !isFormBeingEdited) {
            setCountryId(userProfileData.countryId)
            setStateId(userProfileData.stateId)
            setIsCountrySelected(true)
            setIsStateSelected(true)
            form.setFieldsValue({ firstName: userProfileData.firstName })
            form.setFieldsValue({ lastName: userProfileData.lastName })
            form.setFieldsValue({ userName: userProfileData.userName })
            form.setFieldsValue({ phoneNumber: userProfileData.phoneNumber })
            form.setFieldsValue({ email: userProfileData.email })
            form.setFieldsValue({ address: userProfileData.address })
            form.setFieldsValue({ countryId: userProfileData.countryId })
            form.setFieldsValue({ stateId: userProfileData.stateId })
            form.setFieldsValue({ cityId: userProfileData.cityId })
            form.setFieldsValue({ postalCode: userProfileData.postalCode })
        }
    }, [userProfileData])

    return (
        <div>
            {(isLoading || isProfileDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>

                        </ul>
                    </div>


                    {/* Before */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <form>
                                        <div className="setting-title">
                                            <h4>Profile Settings</h4>
                                        </div>
                                        <div className="card-title-head">
                                            <h6>
                                                <span>

                                                    <User className="feather-chevron-up" />
                                                </span>
                                                Employee Information
                                            </h6>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            initialValues={{
                                            }}
                                            name="forForm" className='productActions_addModalWrapper'>

                                            {/* Profile Image */}
                                            <div className="row" style={{ marginBottom: '20px' }}>
                                                <div className="col-lg-6">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="profileUrl"
                                                            className="actions_addModalFormItem"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        return isError
                                                                            ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                            : Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Upload
                                                                listType="picture-card"
                                                                fileList={fileList}
                                                                onPreview={handlePreview}
                                                                onChange={handleChange}
                                                            >
                                                                {fileList.length >= 1 ? null : uploadButton}
                                                            </Upload>
                                                            {/* {previewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: previewOpen,
                                                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                    }}
                                                                    src={previewImage}
                                                                />
                                                            )} */}
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Profile Image */}

                                            {/* Name and Username */}
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="firstName"
                                                            className="actions_addModalFormItem"
                                                            label="First Name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: <IntlMessages id="hrm.employee.form.validation.first.name" />,
                                                                },
                                                                {
                                                                    type: 'string',
                                                                    message: 'The input must be a valid string.',
                                                                },
                                                                {
                                                                    pattern: /^[A-Za-z\s]+$/,
                                                                    message: 'First name should only contain alphabets.',
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: 'First name must be at least 1 characters.',
                                                                },
                                                                {
                                                                    max: 50,
                                                                    message: 'First name cannot exceed 50 characters.',
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="lastName"
                                                            className="actions_addModalFormItem"
                                                            label="Last Name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: <IntlMessages id="hrm.employee.form.validation.last.name" />,
                                                                },
                                                                {
                                                                    type: 'string',
                                                                    message: 'The input must be a valid string.',
                                                                },
                                                                {
                                                                    pattern: /^[A-Za-z\s]+$/,
                                                                    message: 'Last name should only contain alphabets.',
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: 'Last name must be at least 1 characters.',
                                                                },
                                                                {
                                                                    max: 50,
                                                                    message: 'Last name cannot exceed 50 characters.',
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="userName"
                                                            className="actions_addModalFormItem"
                                                            label="User Name"
                                                        >
                                                            <input type="text" disabled />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Name and Username */}


                                            {/* Phone Number and Email */}
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="phoneNumber"
                                                            className="actions_addModalFormItem"
                                                            label="Phone Number"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Phone Number Required",
                                                                },
                                                                {
                                                                    pattern: /^\d+$/,
                                                                    message: "Contact number must contain only digits.",
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: "Contact number must be at least 1 digits.",
                                                                },
                                                                {
                                                                    max: 20,
                                                                    message: "Contact number cannot exceed 20 digits.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="email"
                                                            className="actions_addModalFormItem"
                                                            label="Email"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Email Required",
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: "Please enter a valid email address.",
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: 'Email be at least 1 characters.',
                                                                },
                                                                {
                                                                    max: 255,
                                                                    message: 'Email cannot exceed 255 characters.',
                                                                },
                                                            ]}
                                                        >
                                                            <input type="email" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Phone Number and Email */}

                                            {/* Our Addresses */}
                                            <div className="card-title-head">
                                                <h6>
                                                    <span>

                                                        <MapPin className="feather-chevron-up" />
                                                    </span>
                                                    Our Address
                                                </h6>
                                            </div>
                                            {/* Our Addresses */}

                                            {/* Address */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="address"
                                                            className="actions_addModalFormItem"
                                                            label="Address"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Address Required",
                                                                },
                                                                {
                                                                    max: 500,
                                                                    message: "Address cannot exceed 500 characters.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Address */}

                                            {/* Country, State, City, Postal Code */}
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="countryId"
                                                            className="actions_addModalFormItem"
                                                            label="Country"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Country Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={countriesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                onChange={handleCountryChange}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="stateId"
                                                            className="actions_addModalFormItem"
                                                            label="State / Province"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "State / Province Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={statesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                disabled={!isCountrySelected}
                                                                onChange={handleStateChange}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="cityId"
                                                            className="actions_addModalFormItem"
                                                            label="City"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "City Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={citiesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                disabled={!isStateSelected}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="postalCode"
                                                            className="actions_addModalFormItem"
                                                            label="Postal Code"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Postal Code Required",
                                                                },
                                                                {
                                                                    pattern: /^[A-Za-z0-9\s-]+$/,
                                                                    message: "Zip code must be a valid alphanumeric string.",
                                                                },
                                                                {
                                                                    max: 20,
                                                                    message: "Zip code cannot exceed 20 characters.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Country, State, City, Postal Code */}
                                        </Form>
                                        <div className="text-end settings-bottom-btn">
                                            <button type="button" className="btn btn-cancel me-2">
                                                Cancel
                                            </button>
                                            <Link to="#" onClick={handleValidateFormData} className="btn btn-submit">
                                                Save Changes
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GeneralSettings
