/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Sliders,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import { Filter } from "react-feather";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import Select from "react-select";
import EditIpAddress from "../../../core/modals/settings/editipaddress";
import AddIpAddress from "../../../core/modals/settings/addipaddress";
import SettingsSideBar from "../settingssidebar";
import Table from "../../../core/pagination/datatable";
import { banIpAddressData } from "../../../core/json/banIpAddress";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddUpdateBanIpModal from "./components/addUpdateBanIpModal";
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import banIpActions from '../../../core/Services/settings/banip/actions'
import * as settingSelectors from '../settingsSelector'
import { useDebounce } from "use-debounce";
import { Spin } from "antd";

const BanIpaddress = () => {
  const datas = banIpAddressData;
  const data = useSelector((state) => state.toggle_header);
  const dispatch = useDispatch();

  const [isBanIpModalOpen, setBanIpModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [banIpParams, setBanIpParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [debouncedText] = useDebounce(banIpParams, 1000);

  const isBanIpdataLoading = useSelector(state => settingSelectors.setBanIpLoader(state));
  const allBanIpData = useSelector(state => settingSelectors.setBanip(state))
  const dataSource = allBanIpData ? allBanIpData.toJS() : [];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(banIpActions.deleteBanipById({ banipParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const handleModal = (banIpData, actionName) => {
    const banIpModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? banIpData : {},
    };
    setCurrentModalData(banIpModalObj);
    setBanIpModalOpen(true);
  };

  useEffect(() => {
    dispatch(banIpActions.fetchAllBanip({ banipParams: debouncedText }));
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(banIpActions.fetchAllBanip({ banipParams: banIpParams }));
  }

  const columns = [
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      sorter: (a, b) => a.ip.length - b.ip.length,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
    },

    {
      title: "Status",
      dataIndex: "isActive",
      render: (text) => (
        <span
          className={`badge ${text === true ? "badge-linesuccess" : "badge badge-linedanger"
            }`}
        >
          {text === true
            ? "Active"
            : "Inactive"
          }
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <div className="action-table-data justify-content-end">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
            >
              <i className="feather-edit" style={{ cursor: "pointer" }}></i>
            </Link>
            <a
              className="confirm-text p-2"
              href="#"
              onClick={() => showConfirmationAlert(row)}
            >
              <i className="feather-trash-2" style={{ cursor: "pointer" }}></i>
            </a>
          </div>
        </div>
      ),
    },
  ];

  const toggleBanIpModal = (value) => {
    setBanIpModalOpen();
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Settings</h4>
                <h6>Manage your settings on portal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap w-50">
                  <div className="setting-title">
                    <h4>Ban IP Address</h4>
                  </div>
                  <div className="page-header bank-settings justify-content-end">
                    <div className="page-btn">
                      <Link
                        onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                        to="#"
                        className="btn btn-added"
                      >
                        <PlusCircle className="me-2" />
                        Add New Ban IP
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card table-list-card">
                        <div className="card-body">
                          <div className="table-top">
                            <div className="search-set">
                              <div className="search-input">
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search"
                                  aria-controls="DataTables_Table_0"
                                  value={searchText}
                                  onChange={handleSearch}
                                />
                                <Link to className="btn btn-searchset">
                                  <i
                                    data-feather="search"
                                    className="feather-search"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          {isBanIpModalOpen ? (
                            <AddUpdateBanIpModal
                              currentModalData={currentModalData}
                              isModalOpen={isBanIpModalOpen}
                              isUpdate={currentModalData.formData.id ? true : false}
                              toggleModal={toggleBanIpModal}
                              banIpParams={banIpParams}
                            />
                          ) : null}
                          <div className="table-responsive">
                            <Table
                              isLoading={isBanIpdataLoading}
                              columns={columns}
                              dataSource={dataSource}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanIpaddress;
