import React, { useState, useEffect } from 'react';
import addVaultActions from '../../../../src/core/Services/stock/vault/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Radio, Space, Select, InputNumber, Input, Form, Table } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";
import IntlMessages from "../../../shared/components/utility/intlMessages.js";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import TextEditor from "../../inventory/texteditor";

const AddVaultModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const cashNotes = [5000, 1000, 500, 100, 50, 20, 10];
    const [userType, setUserType] = useState("Supplier");
    const [cashMode, setCashMode] = useState("Credit");
    const [tableData, setTableData] = useState(cashNotes.map(note => ({ cashNote: note, numberOfNotes: 0 })));
    const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
    const allDepartmentData = departmentData ? departmentData.toJS() : [];
    const expenseTypeData = useSelector(state => commonSelector.setAllExpensetype(state));
    const allExpenseTypeData = expenseTypeData ? expenseTypeData.toJS() : [];
    const supplierData = useSelector(state => commonSelector.setAllSuppliers(state));
    const allSupplierData = supplierData ? supplierData.toJS() : [];
    const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
    const allEmployeeData = employeeData ? employeeData.toJS() : [];
    const expenseCategoryData = useSelector(state => commonSelector.setAllExpenseCategory(state));
    const allExpenseCategoryData = expenseCategoryData ? expenseCategoryData.toJS() : [];
    const [editorData, setEditorData] = useState();
    const params = {
        pageNumber: 1,
        pageSize: 10,
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    useEffect(() => {
        dispatch(commonAction.getAllDepartment());
        dispatch(commonAction.fetchAllexpenstypes());
        dispatch(commonAction.getAllSuppliers());
        dispatch(commonAction.getAllEmployee());
        dispatch(commonAction.getAllExpenseCategory());
    }, [])

    useEffect(() => {
        if (currentModalData && currentModalData.isCredit) {
            setCashMode("Credit");
        } else {
            setCashMode("Debit");
        }

        if (currentModalData && currentModalData.employeeId > 0) {
            setUserType("Employee");
        } else {
            setUserType("Supplier");
        }

        if (currentModalData && currentModalData.description) {
            let editorFetchedData = currentModalData.description?.replace(/""/g, '');
            setEditorData(editorFetchedData);
        }

        if (currentModalData && currentModalData.cashDemoninations.length > 0) {
            setTableData(
                cashNotes.map(note => {
                    const numberOfNotes = currentModalData.cashDemoninations
                        .filter(e => e.denominationValue === note)
                        .map(e => e.quantity)
                        .reduce((acc, quantity) => acc + quantity, 0);

                    handleInputChange(numberOfNotes, note);

                    return {
                        cashNote: note,
                        numberOfNotes
                    };
                })
            );
        }
    }, [currentModalData])

    function resetFields() {
        form.setFieldsValue({
            supplierId: '',
            employeeId: '',
            expenseTypeId: '',
            departmentId: '',
            headId: ''
        });
        setEditorData('')
    }
    const handleCashModeChange = (e) => {
        setCashMode(e.target.value);
        resetFields();
        form.setFieldsValue({ cashMode: e.target.value });
    };
    const handleRadioChange = (e) => {
        setUserType(e.target.value);
        resetFields();
        form.setFieldsValue({ userType: e.target.value });
    };

    const handleRecord = (actionName, vaultData) => {
        let list = [];
        for (let [key, value] of Object.entries(vaultData)) {
            if (key.startsWith('notes_')) {
                let x = parseInt(key.split('_')[1], 10);
                cashNotes.forEach(e => {
                    if (e === x) {
                        list.push({
                            denominationValue: e,
                            quantity: value
                        })
                    }
                });
            }
        }

        const data = {
            id: isUpdate ? currentModalData.id : 0,
            companyId: isUpdate ? currentModalData.companyId : 1,
            isCredit: vaultData.cashMode === "Credit" ? true : false,
            departmentId: vaultData.departmentId,
            headId: vaultData.headId,
            description: editorData,
            supplierId: cashMode === "Debit" && userType === "Supplier" ? vaultData.supplierId : 0,
            employeeId: cashMode === "Debit" && userType === "Employee" ? vaultData.employeeId : 0,
            expenseTypeId: cashMode === "Debit" && userType === "Employee" ? vaultData.expenseTypeId : 0,
            cashDemoninations: list
        }
        debugger;
        dispatch(addVaultActions.addUpdateVaults({ vaultData: data, actionName, vaultParams: params }));

        toggleModal();
    };

    const handleInputChange = (value, note) => {
        const updatedTableData = tableData.map(row => row.cashNote === note ? { ...row, numberOfNotes: value } : row);
        setTableData(updatedTableData);
        form.setFieldsValue({
            [`notes_${note}`]: value,
        });
    };

    const columns = [
        {
            title: 'Note',
            dataIndex: 'cashNote',
            key: 'cashNote',
        },
        {
            title: '',
            key: 'multiplicationSign',
            render: () => 'X',
        },
        {
            title: 'Note #',
            key: 'numberOfNotes',
            render: (text, record) => (
                <Form.Item
                    name={`notes_${record.cashNote}`}
                    noStyle
                    rules={[{ required: true, message: 'Please enter the number of notes' }]}
                >
                    <InputNumber
                        value={record.numberOfNotes}
                        onChange={(value) => handleInputChange(value, record.cashNote)}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Total',
            key: 'total',
            render: (text, record) => {
                return record.numberOfNotes * record.cashNote;
            },
        },
    ];

    const calculateGrandTotal = () => {
        return tableData.reduce((total, row) => total + (row.numberOfNotes * row.cashNote), 0);
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={900}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="addVault.stock.form.modal.title.edit" />
                ) : (
                    <IntlMessages id="addVault.stock.form.modal.title.add" />
                )
            }
            okText={(
                <IntlMessages id="addVault.stock.form.modal.add" />
            )
            }
            cancelText={<IntlMessages id="addVault.stock.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="addVaultForm"
                className="addVaultActions_addModalWrapper"
                initialValues={{
                    cashMode: currentModalData && currentModalData.isCredit ? "Credit" : "Debit",
                    userType: currentModalData && currentModalData.employeeId > 0 ? "Employee" : "Supplier",
                    supplierId: currentModalData && currentModalData.supplierId ? currentModalData.supplierId : undefined,
                    employeeId: currentModalData && currentModalData.employeeId ? currentModalData.employeeId : undefined,
                    expenseTypeId: currentModalData && currentModalData.expenseTypeId ? currentModalData.expenseTypeId : undefined,
                    departmentId: currentModalData && currentModalData.departmentId ? currentModalData.departmentId : undefined,
                    headId: currentModalData && currentModalData.headId ? currentModalData.headId : undefined,
                    description: currentModalData ? currentModalData.description : "",
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="cashMode"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="stock.addVault.form.label.cash.mode" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="stock.addVault.form.label.cash.mode" />,
                                    },
                                ]}
                            >
                                <Radio.Group onChange={handleCashModeChange} value={cashMode}>
                                    <Space direction="horizontal">
                                        <Radio value={"Credit"}>
                                            <IntlMessages id="stock.addVault.form.label.cash.mode.credit" />
                                        </Radio>
                                        <Radio value={"Debit"}>
                                            <IntlMessages id="stock.addVault.form.label.cash.mode.debit" />
                                        </Radio>

                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    {cashMode === "Debit" && (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                                <Form.Item
                                    name="userType"
                                    className="actions_addModalFormItem"
                                    label={<IntlMessages id="stock.addVault.form.label.user.type" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="stock.addVault.form.label.user.type" />,
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={handleRadioChange}>
                                        <Space direction="horizontal">
                                            <Radio value={"Employee"}>
                                                <IntlMessages id="stock.addVault.form.label.user.type.employee" />
                                            </Radio>
                                            <Radio value={"Supplier"}>
                                                <IntlMessages id="stock.addVault.form.label.user.type.supplier" />
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    )}
                </div>

                {cashMode === "Debit" && userType === "Supplier" && (
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                                <Form.Item
                                    name="supplierId"
                                    label={<IntlMessages id="stock.addVault.form.label.supplier.name" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="stock.addVault.form.validation.supplier.name" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={allSupplierData.map(f => ({ label: f.name, value: f.id }))}
                                        classNamePrefix="react-select"
                                        className="input-select"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        notFoundContent="No results found"
                                        placeholder={<IntlMessages id="stock.addVault.form.placeholder.choose" />}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                )}

                {cashMode === "Debit" && userType === "Employee" && (
                    <>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="employeeId"
                                        label={<IntlMessages id="stock.addVault.form.label.employee.name" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="stock.addVault.form.validation.employee.name" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={allEmployeeData.map(f => ({ label: f.name, value: f.id }))}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            notFoundContent="No results found"
                                            placeholder={<IntlMessages id="stock.addVault.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="expenseTypeId"
                                        label={<IntlMessages id="stock.addVault.form.label.expenseType" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="stock.addVault.form.validation.expenseType" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={allExpenseTypeData.map(f => ({ label: f.name, value: f.id }))}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            notFoundContent="No results found"
                                            placeholder={<IntlMessages id="stock.addVault.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="row">

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="departmentId"
                                label={<IntlMessages id="stock.addVault.form.label.point" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="stock.addVault.form.validation.point" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={allDepartmentData.map(f => ({ label: f.name, value: f.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    placeholder={<IntlMessages id="stock.addVault.form.placeholder.choose" />}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="headId"
                                label={<IntlMessages id="stock.addVault.form.label.expenseHead" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="stock.addVault.form.validation.expenseHead" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={allExpenseCategoryData.map(f => ({ label: f.name, value: f.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    placeholder={<IntlMessages id="stock.addVault.form.placeholder.choose" />}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                className="actions_addModalFormItem"
                                name="description"
                                label={<IntlMessages id="stock.addVault.form.label.description" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="stock.addVault.form.validation.description" />,
                                    },
                                ]}
                            >
                                {/* <Input.TextArea className="actions_addModalFormItem" rows={4} /> */}
                                <TextEditor data={editorData} onChange={handleEditorChange} />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    footer={() => (
                        <div className="table-footer">
                            <strong>Grand Total: {calculateGrandTotal()}</strong>
                        </div>
                    )}
                />

            </Form>
        </Modal>
    );
}

export default AddVaultModal;
