import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setAdvanceBookings = (state, { allAdvanceBookingsData }) => {
    if (!allAdvanceBookingsData) return state;
    return state.merge({ allAdvanceBookingsData });
}

const setAdvanceBookingsById = (state, { advanceBookingsParam }) => {
    return state.merge({ advanceBookingsParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_ADVANCEBOOKINGS,
        actionFunction: setAdvanceBookings,
    },
    {
        actionType: ActionTypes.SET_ADVANCEBOOKINGS_BY_ID,
        actionFunction: setAdvanceBookingsById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
