import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import ImageWithBasePath from "../../../core/img/imagewithbasebath.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable.js";
import AddRecipes from "./addrecipes.js";
import IntlMessages from "../../../shared/components/utility/intlMessages.js";
import recipesAction from "../../../core/Services/recipes/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions.js';
import * as recipesSelector from '../recipesSelectors.js';
import * as ApiResponseSelectors from '../../selectors.js';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const RecipesList = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const data = useSelector((state) => state.toggle_header);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [recipesParams, setRecipeParams] = useState({
    pageSize: 10,
    pageNumber: 1,
    searchText: '',
  });
  const [isRecipesModalOpen, setRecipesModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(recipesParams.searchText, 1000);

  const isLoading = useSelector(state => recipesSelector.setLoader(state));
  const RecipesData = useSelector(state => recipesSelector.setRecipes(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = RecipesData ? RecipesData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleUserModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(recipesAction.fetchAllRecipes({ recipesParams }));
  }, [dispatch, debouncedText, currentPage, pageSize]);

  const toggleRecipesModal = (value) => {
    setRecipesModalOpen();
  }

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.current);
    setPageSize(paginationData.pageSize);
    setRecipeParams(prevParams => ({
      ...prevParams,
      pageNumber: paginationData.current,
      pageSize: paginationData.pageSize,
    }));
  };

  const handleRecipeSearch = textValue => {
    setRecipeParams((prevParams) => ({
      ...prevParams,
      searchText: textValue,
    }));
  };

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(recipesAction.deleteRecipesById({ recipesParam: row }));
      } else {
        MySwal.close();
      }
    });
  };
  const handleDataIntoExcel = (filename = 'Recipes.xlsx') => {
    if (dataSource && dataSource.length > 0) {
      const formattedData = dataSource.map(item => ({
        productName: item.productName,
        description: item.description,
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };


  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Sr.', 'Product Name', 'description']];

    const tableData = dataSource.map((item, index) => [
      index + 1,
      item.productName,
      item.description,
    ]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Recipes.pdf');
  };

  const handleRefresh = () => {
    dispatch(recipesAction.fetchAllRecipes({ recipesParams }));
  }

  const handleModal = (recipesData, actionName) => {

    const recipesModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? recipesData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(recipesModalObj);
    setRecipesModalOpen(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const columns = [
    {
      title: <IntlMessages id="recipes.table.column.product.name" />,
      dataIndex: "productName",
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: <IntlMessages id="recipes.table.column.description" />,
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },

    {
      title: <IntlMessages id="recipes.table.column.action" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-recipes"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];


  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4> <IntlMessages id="recipes.page.title.recipes" /></h4>
                <h6> <IntlMessages id="recipes.page.sub.title..manage.recipes" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="recipes.page.add.new.recipes" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => handleRecipeSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>

              </div>
              {/* /Filter */}

              {isRecipesModalOpen ? (
                <AddRecipes
                  currentModalData={currentModalData}
                  isModalOpen={isRecipesModalOpen}
                  isUpdate={currentModalData.formData.id ? true : false}
                  toggleModal={toggleRecipesModal}
                />
              ) : null}
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  isLoading={isLoading}
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: tableData?.totalRecords,
                    showSizeChanger: false,
                  }}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default RecipesList;
