import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import Table from "../../../../core/pagination/datatable";
import AddTodayExpensesModal from "./addexpense.js";
import IntlMessages from "../../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../../../core/redux/action";
import todayExpensesAction from "../../../../core/Services/pos/cashclosing/todayexpenses/actions.js"
import apiResponseAction from '../../../../core/Services/apiResponse/actions';
import * as posSelectors from '../../posSelectors.js';
import * as ApiResponseSelectors from '../../../selectors';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const TodayExpenses = () => {
  const dispatch = useDispatch();
  const [todayExpensesParams, setTodayExpensesParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    currentdate: '',
  });
  const [isTodayExpensesModalOpen, setTodayExpensesModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(todayExpensesParams, 1000);
  const [searchText, setSearchText] = useState("");
  const isLoading = useSelector(state => posSelectors.todayExpensesLoader(state));
  const TodayExpensesData = useSelector(state => posSelectors.setTodayExpense(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = TodayExpensesData ? TodayExpensesData.toJS() : [];
  const allTodayExpensesData = dataSource.data;
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleTodayExpensesModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(todayExpensesAction.fetchAllTodayExpenses({ todayExpensesParams: todayExpensesParams }));
  }, []);

  const toggleTodayExpensesModal = (value) => {
    setTodayExpensesModalOpen();
  }

  const [selectedDate, setSelectedDate] = useState(new Date());

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleDataIntoExcel = (filename = 'Todayexpenses.xlsx') => {
    if (allTodayExpensesData && allTodayExpensesData.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(allTodayExpensesData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['todayExpensesId', 'todayExpensesName', 'todayExpensesSlug', 'isActive']];
    const tableData = allTodayExpensesData.map((item, index) => [item.id, item.todayExpensesName, item.todayExpensesSlug, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Todayexpenses.pdf');
  };

  const handleRefresh = () => {
    dispatch(todayExpensesAction.fetchAllTodayExpenses({ todayExpensesParams }));
  }

  const columns = [
    {
      title: <IntlMessages id="pos.todayexpenses.table.column.supplier.name" />,
      dataIndex: "name",
      sorter: (a, b) => a.todayExpensesName.length - b.todayExpensesName.length,
    },
    {
      title: <IntlMessages id="pos.todayexpenses.table.column.description" />,
      dataIndex: "description",
      sorter: (a, b) => a.todayExpensesSlug.length - b.todayExpensesSlug.length,
    },
    {
      title: <IntlMessages id="pos.todayexpenses.table.column.amount" />,
      dataIndex: "amount",
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: <IntlMessages id="pos.todayexpenses.table.column.date" />,
      dataIndex: "expenseDate",
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: <IntlMessages id="pos.todayexpenses.table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handleModal = (todayExpensesData, actionName) => {
    debugger;
    const todayExpensesModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? todayExpensesData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(todayExpensesModalObj);
    setTodayExpensesModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(todayExpensesAction.deleteTodayExpenses({ todayExpensesParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="pos.todayexpenses.table.heading.todayexpenses" /></h4>
                <h6><IntlMessages id="pos.todayexpenses.table.label.manage.todayexpenses" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="pos.todayexpenses.table.button.addnew.todayExpenses" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line
                          x1={21}
                          y1={21}
                          x2="16.65"
                          y2="16.65"
                        />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <Table isLoading={isLoading} columns={columns} dataSource={allTodayExpensesData} />
            </div>
          </div>
          {isTodayExpensesModalOpen ? (
            <AddTodayExpensesModal
              currentModalData={currentModalData}
              isModalOpen={isTodayExpensesModalOpen}
              isUpdate={currentModalData.formData.id ? true : false}
              toggleModal={toggleTodayExpensesModal}
            />
          ) : null}
        </div>
        {/* /product list */}
      </div>
    </>
  );
};

export default TodayExpenses;