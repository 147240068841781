export const ActionTypes = {
    ADD_UPDATE_DESIGNATION: 'services/hrm/designation/add_update_designation',
    FETCH_DESIGNATION: 'services/hrm/designation/fetch_designations',
    SET_DESIGNATIONS: 'services/hrm/designation/set_designations',
    DELETE_DESIGNATIONS: 'services/hrm/designation/delete_designations',
    SET_LOADER: 'services/hrm/designation/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllDesignations = ({ designationParams }) => ({
    designationParams,
    type: ActionTypes.FETCH_DESIGNATION,
})

const setDesignations = ({ allDesignationData }) => ({
    allDesignationData,
    type: ActionTypes.SET_DESIGNATIONS,
})

const deleteDesignations = ({ designationParam }) => ({
    designationParam,
    type: ActionTypes.DELETE_DESIGNATIONS,
})

const addUpdateDesignations = ({ designationData, actionName, designationParams }) => ({
    designationData,
    actionName,
    designationParams,
    type: ActionTypes.ADD_UPDATE_DESIGNATION,
})

export default {
    addUpdateDesignations,
    fetchAllDesignations,
    setDesignations,
    deleteDesignations,
    setLoader
};
