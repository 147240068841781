import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Input, Table, Spin } from "antd";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import StockActions from '../../../core/Services/stock/stockadjustment/actions';
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import { debounce } from 'lodash';

const StockadjustmentModal = (props) => {
  const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const allWarehouses = warehouseData ? warehouseData.toJS() : [];
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const allProducts = productData ? productData.toJS() : [];
  const [productTable, setProductTable] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
    dispatch(commonAction.getAllWarehouses());
    dispatch(commonAction.getAllEmployee());
  }, [])

  useEffect(() => {
    debugger;
    if (currentModalData) {
      setSelectedQuantity(currentModalData.quantity);
      setProductTable([{ product: currentModalData.productName, quantity: currentModalData.quantity }]);
    }
  }, [currentModalData])

  useEffect(() => {
    setLoading(false);
  }, [productData])

  const searchProducts = (text) => {
    setLoading(true);
    dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
  }
  const debouncedSearchProducts = debounce(searchProducts, 1000);

  const handleQuantityChange = (value) => {
    setSelectedQuantity(value);
  }

  const handleProductChange = (data) => {
    let product;
    if (allProducts.length > 0) {
      product = allProducts.filter(p => p.id === data);
    } else {
      product = [{ productName: currentModalData.productName }]
    }
    setProductTable([{ product: product[0].productName, quantity: 0 }]);
  }

  const handleRecord = (actionName, stockadjustmentData) => {
    debugger;
    stockadjustmentData.quantity = selectedQuantity;
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      stockadjustmentData.id = currentModalData.id;
      stockadjustmentData.companyId = currentModalData.companyId;
      if (typeof stockadjustmentData.productId === 'string') {
        if (allProducts.length > 0) {
          const productId = allProducts.filter(p => p.productName === stockadjustmentData.productId);
          if (productId.length === 0) {
            stockadjustmentData.productId = currentModalData.productId;
          } else {
            stockadjustmentData.productId = productId[0].id;
          }
        } else {
          stockadjustmentData.productId = currentModalData.productId;
        }
      }
    } else {
      stockadjustmentData.id = 0;
      stockadjustmentData.companyId = 1;
    }
    stockadjustmentData.notes="";
    dispatch(StockActions.addUpdateStockAdjustments({ stockadjustmentData, actionName, UserParams: {} }));

    toggleModal();
  }

  const handleDelete = () => {
    setProductTable([]);
  };

  const column = [
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.Warehouse.length - b.Warehouse.length,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      sorter: (a, b) => a.Shop.length - b.Shop.length,
      render: (text, row) => (
        <Input
          type="number"
          min={1}
          value={selectedQuantity}
          onChange={(e) => handleQuantityChange(Number(e.target.value))}
        />
      )
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={handleDelete}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ]

  return (
    <Modal
      open={isModalOpen}
      width={800}
      zIndex={99999}
      onClose={() => toggleModal()}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal()}
      title={
        isUpdate ? (
          <IntlMessages id="stock.modal.editstock" />
        ) : (
          <IntlMessages id="stock.modal.addstock" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          warehouseId: currentModalData ? currentModalData.warehouseId : null,
          departmentId: currentModalData ? currentModalData.departmentId : null,
          employeeId: currentModalData ? currentModalData.employeeId : null,
          referenceNo: currentModalData ? currentModalData.referenceNo : null,
          productId: currentModalData ? currentModalData.productName : null,
        }}
      >
        <div className="modal-body custom-modal-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks search-form">
                <Form.Item
                  name="productId"
                  className="actions_addModalFormItem"
                  label={
                    <IntlMessages id="stock.table.column.product" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Product"
                    onSearch={debouncedSearchProducts}
                    onChange={handleProductChange}
                    filterOption={false}
                    options={allProducts.map((f) => ({ label: f.productName, value: f.id }))}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="departmentId"
                  label={
                    <IntlMessages id="stockadjustment.table.column.shop" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="warehouseId"
                  label={
                    <IntlMessages id="stock.table.column.warehouse" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allWarehouses.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="referenceNo"
                  label={
                    <IntlMessages id="stockadjustment.table.column.reference" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Reference Number"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="modal-body-table">
                <div className="table-responsive">
                  <Table
                    className="table datanew"
                    columns={column}
                    dataSource={productTable}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="employeeId"
                  label={
                    <IntlMessages id="stockadjustment.table.column.employee" />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default StockadjustmentModal;
