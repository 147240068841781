export const ActionTypes = {
    ADD_UPDATE_CUSTOMFIELD: 'services/Settings/customfield/add_update_customfield',
    FETCH_CUSTOMFIELD: 'services/Settings/customfield/fetch_customfield',
    SET_CUSTOMFIELD: 'services/Settings/customfield/set_customfield',
    DELETE_CUSTOMFIELD: 'services/Settings/customfield/delete_customfield',
    SET_LOADER: 'services/Settings/customfield/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllCustomField = ({ customfieldParams }) => ({
    customfieldParams,
    type: ActionTypes.FETCH_CUSTOMFIELD,
})

const setCustomField = ({ allCustomFieldData }) => ({
    allCustomFieldData,
    type: ActionTypes.SET_CUSTOMFIELD,
})

const deleteCustomFieldById = ({ customfieldParam }) => ({
    customfieldParam,
    type: ActionTypes.DELETE_CUSTOMFIELD,
})

const addUpdateCustomField = ({ customfieldData, actionName, customfieldParams }) => ({
    customfieldData,
    actionName,
    customfieldParams,
    type: ActionTypes.ADD_UPDATE_CUSTOMFIELD,
})

export default {
    addUpdateCustomField,
    fetchAllCustomField,
    setCustomField,
    deleteCustomFieldById,
    setLoader
};
