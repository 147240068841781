export const ActionTypes = {
    ADD_UPDATE_ADVANCEBOOKINGS: 'services/pos/cashclosing/advanceBookings/add_update_advanceBookings',
    ADD_UPDATE_ADVANCEBILL: 'services/pos/cashclosing/advanceBookings/add_update_advanceBill',
    FETCH_ADVANCEBOOKINGS: 'services/pos/cashclosing/advanceBookings/fetch_advanceBookings',
    SET_ADVANCEBOOKINGS: 'services/pos/advanceBookings/set_advanceBookings',
    DELETE_ADVANCEBOOKINGS: 'services/pos/cashclosing/advanceBookings/delete_advanceBookings',
    GET_ADVANCEBOOKINGS_BY_ID: 'services/pos/cashclosing/advanceBookings/get_advanceBookings_by_id',
    SET_ADVANCEBOOKINGS_BY_ID: 'services/pos/cashclosing/advanceBookings/set_advanceBookings_by_id',
    SET_LOADER: 'services/pos/advanceBookings/set_loader',
    DELETE_ADVANCEBILLS: 'services/pos/cashclosing/advanceBills/delete_advanceBills',
  }
  
  const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
  });
  
  const fetchAllAdvanceBookings = ({ advanceBookingsParams }) => ({
    advanceBookingsParams,
    type: ActionTypes.FETCH_ADVANCEBOOKINGS,
  })
  
  const setAdvanceBookings = ({ allAdvanceBookingsData }) => ({
    allAdvanceBookingsData,
    type: ActionTypes.SET_ADVANCEBOOKINGS,
  })
  
  const deleteAdvanceBookings = ({ advanceBookingsParam }) => ({
    advanceBookingsParam,
    type: ActionTypes.DELETE_ADVANCEBOOKINGS,
  })
  
  const getAdvanceBookingsById = ({ advanceBookingsParam }) => ({
    advanceBookingsParam,
    type: ActionTypes.GET_ADVANCEBOOKINGS_BY_ID,
  })
  
  const setAdvanceBookingsById = ({ advanceBookingsParam }) => ({
    advanceBookingsParam,
    type: ActionTypes.SET_ADVANCEBOOKINGS_BY_ID,
  })
  
  const addUpdateAdvanceBookings = ({ advanceBookingsData, actionName, advanceBookingsParams }) => ({
    advanceBookingsData,
    actionName,
    advanceBookingsParams,
    type: ActionTypes.ADD_UPDATE_ADVANCEBOOKINGS,
  })
  
  const addUpdateAdvanceBills = ({ advanceBillsData, actionName, advanceBookingsParams }) => ({
    advanceBillsData,
    actionName,
    advanceBookingsParams,
    type: ActionTypes.ADD_UPDATE_ADVANCEBILL,
  })
  
  const deleteAdvanceBills = ({ advanceBillsParam }) => ({
    advanceBillsParam,
    type: ActionTypes.DELETE_ADVANCEBILLS,
  })
  
  export default {
    addUpdateAdvanceBookings,
    fetchAllAdvanceBookings,
    setAdvanceBookings,
    setAdvanceBookingsById,
    deleteAdvanceBookings,
    getAdvanceBookingsById,
    setAdvanceBookingsById,
    setLoader,
    addUpdateAdvanceBills,
    deleteAdvanceBills
  }