import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../customfield/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateCustomField({ customfieldData, actionName, customfieldParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-customfield`,
        data: customfieldData,
      });
    } else {
      yield call(api.post, {
        route: '/Settings/create-customfield',
        data: customfieldData,
      });
    }
    
    yield put(Actions.fetchAllCustomField({ customfieldParams }));
    notification('success', actionName === 'update' ? "Custom Field Updated Successfully" : "Custom Field Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllCustomField({ customFieldParams }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));

    const { data } = yield call(api.get, {
      route: '/Settings/all-customfields',
      params: customFieldParams,
    });

    const allCustomFieldData = data;
    yield put(Actions.setCustomField({ allCustomFieldData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    console.log(error);
  } finally {
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteCustomFieldById({ customfieldParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Settings/delete-customfield/` + customfieldParam.id,
    });
    yield put(Actions.fetchAllCustomField({ customfieldParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Custom Field Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_CUSTOMFIELD, fetchAllCustomField),
    takeLatest(ActionTypes.ADD_UPDATE_CUSTOMFIELD, addUpdateCustomField),
    takeLatest(ActionTypes.DELETE_CUSTOMFIELD, deleteCustomFieldById)
  ]);
}
