export const ActionTypes = {
    ADD_REPORTSETTING: 'services/Settings/reportsetting/add_update_reportsetting',
    FETCH_REPORTSETTING: 'services/Settings/reportsetting/fetch_reportsetting',
    SET_REPORTSETTING: 'services/Settings/reportsetting/set_reportsetting',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllReportSettings = ({ reportsettingParams }) => ({
    reportsettingParams,
    type: ActionTypes.FETCH_REPORTSETTING,
})

const setReportSettings = ({ allReportSettingsData }) => ({
    allReportSettingsData,
    type: ActionTypes.SET_REPORTSETTING,
})

const addReportSetting = ({ reportSettingData, actionName, reportSettingParams }) => ({
    reportSettingData,
    actionName,
    reportSettingParams,
    type: ActionTypes.ADD_REPORTSETTING,
})


export default {
    fetchAllReportSettings,
    addReportSetting,
    setReportSettings,
    setLoader,
};
