import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../Common/Constant_Variables/constants";
import apiActions from '../apiResponse/actions';


function* addUpdateCoupons({ CouponsData, actionName, CouponsParams }) {
    try {
      if (actionName === 'update') {
        yield call(api.put, {
          route: '/Setup/edit-coupon',
          data: CouponsData,
        });
      } else {
        yield call(api.post, {
          route: '/Setup/create-coupon',
          data: CouponsData,
        });
      }
      
      yield put(Actions.fetchAllCoupons({ CouponsParams }));
      notification('success', actionName === 'update' ? CONSTANT_VARIABLES.COUPONS_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.COUPONS_CREATED_SUCCESSFULLY);
    } catch (error) {
      const apiStatus = error.details.response.status;
      yield put(apiActions.setApiResponse({ apiStatus }));
      handleNotification(error);
      console.log(error);
    }
  }
  function* fetchAllCoupons({ CouponsParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch coupons
      const { data } = yield call(api.get, {
        route: '/Setup/all-coupons',
        params: CouponsParams,
      });
  
      // Store the fetched data in Redux store
      const allCouponsData = data;
      yield put(Actions.setCoupons({ allCouponsData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }

  function* deleteCouponsById({ CouponsParams }) {
    try {
      yield put(Actions.setLoader({ showLoader: true }));
      yield call(api.delete, {
        route:'/Setup/delete-coupon/' + CouponsParams.id,
      });
      yield put(Actions.fetchAllCoupons({CouponsParams}));
      yield put(Actions.setLoader({ showLoader: false }));
      notification('success', CONSTANT_VARIABLES.COUPONS_DELETED_SUCCESSFULLY);
    } catch (error) {
      yield put(Actions.setLoader({ showLoader: false }));
      handleNotification(error);
      console.log(error);
    }
  }


  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0) {
      message.details.response.data.errors.map((value) => (
        notification('error', value)
      ));
    } else {
      notification('error', 'SERVE-POS API Issue.');
    }
  }

  export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_COUPONS, fetchAllCoupons),
      takeLatest(ActionTypes.ADD_UPDATE_COUPONS, addUpdateCoupons),
      takeLatest(ActionTypes.DELETE_COUPONS, deleteCouponsById)
    ]);
  }